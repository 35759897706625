const {
  PC_SUBCLASS_CLERIC_KNOWLEDGE_DOMAIN,
  PC_SUBCLASS_CLERIC_LIFE_DOMAIN,
  PC_SUBCLASS_CLERIC_LIGHT_DOMAIN,
  PC_SUBCLASS_CLERIC_ORDER_DOMAIN,
  PC_SUBCLASS_CLERIC_PEACE_DOMAIN,
  PC_SUBCLASS_CLERIC_TRICKERY_DOMAIN,
  PC_SUBCLASS_CLERIC_TWILIGHT_DOMAIN,
} = require('./../../pcSubClassIdList')

const {
  Dragonborn_Order_domain_TCoE_data,
  Dwarf_Female_Cleric_Knowledge_PHB_data,
  Human_Peace_domain_TCoE_data,
  Human_Twilight_domain_TCoE_data,
  Shadowheart_MTG_CLB_data,
  Dawnbringer_cleric_MTG_CLB_data,
} = require('./../commonImageCollection')

module.exports = {
  [PC_SUBCLASS_CLERIC_ORDER_DOMAIN]: [
    // GGTR Страницы 27
    Dragonborn_Order_domain_TCoE_data,
  ],
  [PC_SUBCLASS_CLERIC_PEACE_DOMAIN]: [
    Human_Peace_domain_TCoE_data,
  ],
  [PC_SUBCLASS_CLERIC_TWILIGHT_DOMAIN]: [
    Human_Twilight_domain_TCoE_data,
  ],
  [PC_SUBCLASS_CLERIC_KNOWLEDGE_DOMAIN]: [
    Dwarf_Female_Cleric_Knowledge_PHB_data,
  ],
  [PC_SUBCLASS_CLERIC_TRICKERY_DOMAIN]: [
    Shadowheart_MTG_CLB_data,
  ],
  [PC_SUBCLASS_CLERIC_LIGHT_DOMAIN]: [
    Dawnbringer_cleric_MTG_CLB_data,
  ],
  [PC_SUBCLASS_CLERIC_LIFE_DOMAIN]: [
    Dawnbringer_cleric_MTG_CLB_data,
  ],
}
