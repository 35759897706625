import arrify from 'arrify'
import get from 'lodash/get'
import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'

import PublicationInfo from '@/components/PublicationInfo'
import SourceInfo from '@/components/SourceInfo'
import Span from '@/components/Span'

import './ReleasedBeforeAsStyles.less'

const ReleasedBeforeAsComponent = (
  {
    className = '',
    checkIsReady = true,
    collection,
    preText,
    releasedAs,
    urlGenerator,
  }
) => {
  if (releasedAs) {
    const idList = arrify(releasedAs)

    return (
      <PublicationInfo className={classNames('ReleasedBeforeAs', className)}>
        {preText} как {
          idList.map(
            (id) => {
              const item = collection[id]
              const {nameScheme = 'name', isReady, source} = item
              const Renderer = checkIsReady
                ? isReady
                  ? Link
                  : Span
                : Link

              return (
                <span
                  key={id}
                  className='ReleasedBeforeAs_item'
                >
                  <Renderer
                    to={urlGenerator(id)}
                    className={classNames(
                      'ReleasedBeforeAs_itemName',
                      {
                        'ReleasedBeforeAs_itemName-link': isReady,
                      },
                      className
                    )}
                  >
                    {get(item, nameScheme)}
                  </Renderer>
                  в&nbsp;
                  <SourceInfo
                    className='ReleasedBeforeAs_source'
                    source={source}
                    showPage={false}
                  />
                </span>
              )
            }
          )
        }
      </PublicationInfo>
    )
  }
  return null
}

export default ReleasedBeforeAsComponent
