const generateListReleasedBeforeAs = require('./../../utils/generateListReleasedBeforeAs')
const prepareForSearch = require('./../../utils/prepareForSearch')
const listToCollectionById = require('./../../utils/listToCollectionById')
const sortByName = require('./../../utils/sortByName')
const checkIsFromUA = require('./../../utils/checkIsFromUA')

const SEARCH_PROP_NAME = require('./../SEARCH_PROP_NAME')

const invocationRawList = require('./invocationRawList')

const invocationList = invocationRawList
  .map(
    (item, i , list) => ({
      ...item,
      isAbandoned: Boolean(item.abandonedAt),
      isReReleased: Boolean(item.releasedAs),
      releasedAs: item.releasedAs || null,
      releasedBeforeAsList: generateListReleasedBeforeAs(list, item.id),
      requirementLvl: item.requirement
        ? item.requirement.lvl
        : 0,
      requirementFeatureId: item.requirement
        ? item.requirement.featureId
        : null,
      requirementPcSubLassId: item.requirement
        ? item.requirement.pcSubLassId
        : null,
      isUA: checkIsFromUA(item.source),
      isReady: 'isReady' in item ? item.isReady : !item.isNotReady,
      [SEARCH_PROP_NAME]: prepareForSearch(
        [
          item.name,
          item.nameAlt || '',
          item.nameEn,
          item.requirement
            ? item.requirement.skill || ''
            : '',
          item.description,
        ]
          .filter(e => e)
          .map(
            e => String(e).toLowerCase(),
          )
          .join('\n'),
      ),
    }),
  )
  .sort(sortByName)

module.exports = invocationList

module.exports.invocationCollection = listToCollectionById(invocationList)

module.exports.invocationRequiredFeatureList = invocationList.reduce(
  (list, {requirementFeatureId}) => requirementFeatureId && !list.includes(requirementFeatureId)
    ? [
      ...list,
      requirementFeatureId,
    ]
    : list,
  []
)

module.exports.invocationRequiredPcSubLassIdList = invocationList.reduce(
  (list, {requirementPcSubLassId}) => requirementPcSubLassId && !list.includes(requirementPcSubLassId)
    ? [
      ...list,
      requirementPcSubLassId,
    ]
    : list,
  []
)
