const {SOURCE_MGZN_DRGN_378} = require('./../../sourceList')

const Dwarf_Fighter_MGZN_DRGN_378_img = require('./../../../images/races/dwarf-fighter-MGZN_DRGN_378.webp')

module.exports = {
  Dwarf_Fighter_MGZN_DRGN_378_data: {
    src: Dwarf_Fighter_MGZN_DRGN_378_img,
    text: 'Щитовой дварф, воин',
    source: {
      id: SOURCE_MGZN_DRGN_378,
    },
  },
}
