const {SOURCE_ADND2_MM} = require('./../../sourceList')

const {
  CREATURE_DUERGAR,
} = require('./../../creatureIdList')

const Duergar_img_ADND2_MM = require('./../../../images/creatures/adnd2_mm/duergar.jpg')

module.exports = {
  Duergar_ADND2_data: {
    src: Duergar_img_ADND2_MM,
    creatureId: CREATURE_DUERGAR,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
}
