import React from 'react'
import { faLink } from '@fortawesome/free-solid-svg-icons'

import Icon from '@/components/Icon'

import './AnchorStyles.less'

const AnchorComponent = ({name, id}) => (
  <a
    className='Anchor'
    href={`#${id}`}
    title={`Постоянная ссылка на «${name}»`}
  >
      <Icon icon={faLink}/>
  </a>
)

export default AnchorComponent
