const {
    SOURCE_PHB,
    SOURCE_TCoE,
    SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
    SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
    SOURCE_UA_2019_11_04_CLASS_FEATURE_VARIANTS,
    SOURCE_XGTE,
  } = require('./../sourceList'),
  {
    FEATURE_PACT_OF_THE_BLADE,
    FEATURE_PACT_OF_THE_CHAIN,
    FEATURE_PACT_OF_THE_TALISMAN,
    FEATURE_PACT_OF_THE_TOME,
  } = require('./../featureIdList'),
  {
    PC_SUBCLASS_WARLOCK_THE_ARCHFEY,
    PC_SUBCLASS_WARLOCK_THE_FIEND,
    PC_SUBCLASS_WARLOCK_THE_GREAT_OLD_ONE,
    PC_SUBCLASS_WARLOCK_THE_HEXBLADE,
    PC_SUBCLASS_WARLOCK_THE_RAVEN_QUEEN_2017_02_13,
    PC_SUBCLASS_WARLOCK_THE_SEEKER_2016_08_01,
  } = require('./../pcSubClassIdList'),
  {
    GEAR_CROSSBOW_HEAVY,
    GEAR_CROSSBOW_LIGHT,
    GEAR_FLAIL,
    GEAR_GREATSWORD,
    GEAR_LONGBOW,
    GEAR_MACE,
    GEAR_SHORTBOW,
  } = require('./../gearIdList'),
  {
    SPELL_ALTER_SELF,
    SPELL_ANIMATE_DEAD,
    SPELL_ARCANE_EYE,
    SPELL_BANE,
    SPELL_BESTOW_CURSE,
    SPELL_COMPULSION,
    SPELL_CONJURE_ELEMENTAL,
    SPELL_DETECT_MAGIC,
    SPELL_CONFUSION,
    SPELL_DISGUISE_SELF,
    SPELL_ELDRITCH_BLAST,
    SPELL_FALSE_LIFE,
    SPELL_FIND_FAMILIAR,
    SPELL_FIREBALL,
    SPELL_FREEDOM_OF_MOVEMENT,
    SPELL_HEX,
    SPELL_HOLD_MONSTER,
    SPELL_INVISIBILITY,
    SPELL_JUMP,
    SPELL_LEVITATE,
    SPELL_MAGE_ARMOR,
    SPELL_POLYMORPH,
    SPELL_SENDING,
    SPELL_SILENT_IMAGE,
    SPELL_SLOW,
    SPELL_SPEAK_WITH_ANIMALS,
    SPELL_SPEAK_WITH_DEAD,
    SPELL_WATER_BREATHING,
  } = require('./../spellIdList'),
  {
    INVOCATION_AGONIZING_BLAST,
    INVOCATION_ARMOR_OF_SHADOWS,
    INVOCATION_ASCENDANT_STEP,
    INVOCATION_ASPECT_OF_THE_MOON,
    INVOCATION_BEAST_SPEECH,
    INVOCATION_BEGUILING_INFLUENCE,
    INVOCATION_BEWITCHING_WHISPERS,
    INVOCATION_BOND_OF_THE_TALISMAN,
    INVOCATION_BOOK_OF_ANCIENT_SECRETS,
    INVOCATION_BURNING_HEX_2017_02_13,
    INVOCATION_CAIPHON_S_BEACON_2017_02_13,
    INVOCATION_CHAIN_MASTERS_FURY_2019_04_11,
    INVOCATION_CHAINS_OF_CARCERI,
    INVOCATION_CHILLING_HEX_2017_02_13,
    INVOCATION_CHRONICLE_OF_THE_RAVEN_QUEEN_2017_02_13,
    INVOCATION_CLAW_OF_ACAMAR_2017_02_13,
    INVOCATION_CLOAK_OF_BAALZEBUL_2017_02_13,
    INVOCATION_CLOAK_OF_FLIES,
    INVOCATION_CURSE_BRINGER_2017_02_13,
    INVOCATION_DEVILS_SIGHT,
    INVOCATION_DREADFUL_WORD,
    INVOCATION_ELDRITCH_ARMOR_2019_04_11,
    INVOCATION_ELDRITCH_MIND,
    INVOCATION_ELDRITCH_SIGHT,
    INVOCATION_ELDRITCH_SMITE,
    INVOCATION_ELDRITCH_SMITE_2017_06_05,
    INVOCATION_ELDRITCH_SPEAR,
    INVOCATION_EYES_OF_THE_RUNE_KEEPER,
    INVOCATION_FAR_SCRIBE,
    INVOCATION_FIENDISH_VIGOR,
    INVOCATION_FROST_LANCE_2017_02_13,
    INVOCATION_FROST_LANCE_2017_06_05,
    INVOCATION_GAZE_OF_KHIRAD_2017_02_13,
    INVOCATION_GAZE_OF_TWO_MINDS,
    INVOCATION_GHOSTLY_GAZE,
    INVOCATION_GHOSTLY_GAZE_2017_06_05,
    INVOCATION_GIFT_OF_THE_DEPTHS,
    INVOCATION_GIFT_OF_THE_DEPTHS_2017_06_05,
    INVOCATION_GIFT_OF_THE_EVER_LIVING_ONES,
    INVOCATION_GIFT_OF_THE_EVER_LIVING_ONES_2017_06_05,
    INVOCATION_GIFT_OF_THE_PROTECTORS,
    INVOCATION_GRASP_OF_HADAR,
    INVOCATION_GREEN_LORD_S_GIFT_2017_02_13,
    INVOCATION_IMPROVED_PACT_WEAPON,
    INVOCATION_IMPROVED_PACT_WEAPON_2017_02_13,
    INVOCATION_IMPROVED_PACT_WEAPON_2017_06_05,
    INVOCATION_INVESTMENT_OF_THE_CHAIN_MASTER,
    INVOCATION_KISS_OF_MEPHISTOPHELES_2017_02_13,
    INVOCATION_KISS_OF_MEPHISTOPHELES_2017_06_05,
    INVOCATION_LANCE_OF_LETHARGY,
    INVOCATION_LIFEDRINKER,
    INVOCATION_MACE_OF_DISPATER_2017_02_13,
    INVOCATION_MADDENING_HEX,
    INVOCATION_MADDENING_HEX_2017_06_05,
    INVOCATION_MASK_OF_MANY_FACES,
    INVOCATION_MASTER_OF_MYRIAD_FORMS,
    INVOCATION_MINIONS_OF_CHAOS,
    INVOCATION_MIRE_THE_MIND,
    INVOCATION_MISTY_VISIONS,
    INVOCATION_MOON_BOW_2017_02_13,
    INVOCATION_ONE_WITH_SHADOWS,
    INVOCATION_OTHERWORLDLY_LEAP,
    INVOCATION_PATH_OF_THE_SEEKER_2017_02_13,
    INVOCATION_PROTECTION_OF_THE_TALISMAN,
    INVOCATION_RAVEN_QUEEN_S_BLESSING_2017_02_13,
    INVOCATION_REBUKE_OF_THE_TALISMAN_2019_04_11,
    INVOCATION_RELENTLESS_HEX,
    INVOCATION_RELENTLESS_HEX_2017_02_13,
    INVOCATION_RELENTLESS_HEX_2017_06_05,
    INVOCATION_REPELLING_BLAST,
    INVOCATION_SCULPTOR_OF_FLESH,
    INVOCATION_SEA_TWINS_GIFT_2017_02_13,
    INVOCATION_SEEKER_S_SPEECH_2017_02_13,
    INVOCATION_SHROUD_OF_SHADOW,
    INVOCATION_SHROUD_OF_ULBAN_2017_02_13,
    INVOCATION_SIGN_OF_ILL_OMEN,
    INVOCATION_SUPERIOR_PACT_WEAPON_2017_02_13,
    INVOCATION_THIEF_OF_FIVE_FATES,
    INVOCATION_THIRSTING_BLADE,
    INVOCATION_TOMB_OF_LEVISTUS,
    INVOCATION_TOMB_OF_LEVISTUS_2017_02_13,
    INVOCATION_TOMB_OF_LEVISTUS_2017_06_05,
    INVOCATION_TRICKSTERS_ESCAPE,
    INVOCATION_ULTIMATE_PACT_WEAPON_2017_02_13,
    INVOCATION_UNDYING_SERVITUDE,
    INVOCATION_VISIONS_OF_DISTANT_REALMS,
    INVOCATION_VOICE_OF_THE_CHAIN_MASTER,
    INVOCATION_WHISPERS_OF_THE_GRAVE,
    INVOCATION_WITCH_SIGHT,
  } = require('./../invocationIdList')

module.exports = [
  {
    id: INVOCATION_AGONIZING_BLAST,
    name: `Мучительный взрыв`,
    nameEn: `Agonizing Blast`,
    description: `Когда Вы накладываете [Мистический заряд](SPELL:${SPELL_ELDRITCH_BLAST}), добавьте модификатор Харизмы к урону, причиняемому при попадании.`,
    requirement: {
      spell: SPELL_ELDRITCH_BLAST,
    },
    source: {
      id: SOURCE_PHB,
      page: 112,
    },
  },
  {
    id: INVOCATION_ARMOR_OF_SHADOWS,
    name: `Доспех теней`,
    nameEn: `Armor of Shadows`,
    description: `Вы можете неограниченно сотворять на себя заклинание [Доспехи мага](SPELL:${SPELL_MAGE_ARMOR}), не тратя ячейки заклинаний и материальные компоненты. `,
    note: {
      text: 'Что мягче шелка, прочнее стали и тише мысли?',
      author: 'загадка колдуна',
    },
    source: {
      id: SOURCE_PHB,
      page: 111,
    },
  },
  {
    id: INVOCATION_ASCENDANT_STEP,
    name: `Восходящий шаг`,
    nameEn: `Ascendant Step`,
    description: `Вы можете неограниченно сотворять на себя заклинание [Левитация](SPELL:${SPELL_LEVITATE}), не тратя ячейки заклинаний и материальные компоненты.`,
    requirement: {
      lvl: 9,
    },
    source: {
      id: SOURCE_PHB,
      page: 111,
    },
  },
  {
    id: INVOCATION_ASPECT_OF_THE_MOON,
    name: `Аспект луны`,
    nameEn: `Aspect of the Moon`,
    description: `Вам больше не нужно спать и Вас невозможно усыпить любым способом. Чтобы получить преимущества длинного отдыха, Вы можете потратить все 8 часов на лёгкую деятельность, например, почитать свою Книгу Теней и постоять на страже.`,
    requirement: {
      featureId: FEATURE_PACT_OF_THE_TOME,
    },
    source: [
      {
        id: SOURCE_XGTE,
        page: 40,
      },
      {
        id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
        page: 3,
      },
      {
        id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
        page: 5,
      },
    ],
  },
  {
    id: INVOCATION_BEAST_SPEECH,
    name: `Животная речь`,
    nameEn: `Beast Speech`,
    description: `Вы можете неограниченно сотворять заклинание [Разговор с животными](SPELL:${SPELL_SPEAK_WITH_ANIMALS}), не тратя ячейки заклинаний.`,
    source: {
      id: SOURCE_PHB,
      page: 111,
    },
  },
  {
    id: INVOCATION_BEGUILING_INFLUENCE,
    name: `Обманчивое влияние`,
    nameEn: `Beguiling Influence`,
    description: `Вы получаете владение навыками _Обман_ и _Убеждение_.`,
    source: {
      id: SOURCE_PHB,
      page: 112,
    },
  },
  {
    id: INVOCATION_BEWITCHING_WHISPERS,
    name: `Завораживающий шёпот`,
    nameEn: `Bewitching Whispers`,
    description: `Вы можете один раз сотворить заклинание [Принуждение](SPELL:${SPELL_COMPULSION}), используя ячейку заклинаний колдуна. Вы не можете сделать это повторно, пока не окончите длинный отдых.`,
    requirement: {
      lvl: 7,
    },
    source: {
      id: SOURCE_PHB,
      page: 111,
    },
  },
  {
    id: INVOCATION_BOND_OF_THE_TALISMAN,
    name: `Связь талисмана`,
    nameEn: `Bond of the Talisman`,
    description: `Пока кто-то другой носит Ваш талисман, Вы можете действием телепортироваться в свободное пространство рядом с ним, если Вы оба находитесь на одном плане существования.

Носитель талисмана может сделать то же самое, действием телепортируясь к Вам.

Телепортацией можно воспользоваться количество раз, равное Вашему бонусу мастерства, и все использования восстанавливаются, когда Вы заканчиваете длинный отдых.`,
    requirement: {
      lvl: 12,
      featureId: FEATURE_PACT_OF_THE_TALISMAN,
    },
    source: {
      id: SOURCE_TCoE,
      page: 106,
    },
  },
  {
    id: INVOCATION_BOOK_OF_ANCIENT_SECRETS,
    name: `Книга древних секретов`,
    nameEn: `Book of Ancient Secrets`,
    description: `Теперь Вы можете вписывать магические ритуалы в свою [Книгу Теней](FEATURE:${FEATURE_PACT_OF_THE_TOME}). Выберите два заклинания 1 уровня с ключевым словом _«ритуал»_ из списков заклинаний любых классов. Эти заклинания появляются в [книге](FEATURE:${FEATURE_PACT_OF_THE_TOME}) и не учитываются в общем количестве заклинаний, которые Вы можете знать. Держа [Книгу Теней](FEATURE:${FEATURE_PACT_OF_THE_TOME}) в руке, Вы можете творить выбранные заклинания как ритуалы. Вы не можете творить заклинания иначе как ритуалом, если Вы не узнали их иным способом. Вы также можете творить известные заклинания колдуна как ритуалы, если у них есть ключевое слово _«ритуал»_.

Во время приключений Вы можете добавлять другие ритуальные заклинания в свою [Книгу Теней](FEATURE:${FEATURE_PACT_OF_THE_TOME}). Когда Вы найдёте такое заклинание, Вы можете добавить его в книгу, если круг заклинания не превышает половину Вашего уровня колдуна (округляя в большую сторону) и если Вы можете потратить время на переписывание заклинания. За каждый круг заклинания время переписывания занимает 2 часа и стоит 50 золотых монет, которые должны быть потрачены на редкие чернила, необходимые для записи.`,
    requirement: {
      featureId: FEATURE_PACT_OF_THE_TOME,
    },
    source: {
      id: SOURCE_PHB,
      page: 112,
    },
  },
  {
    id: INVOCATION_BURNING_HEX_2017_02_13,
    name: `Пылающее проклятье`,
    nameEn: `Burning Hex`,
    description: `Вы можете бонусным действием заставить цель, проклятую Вашим Проклятием Ведьмовского клинка, получить урон огнём, равный Вашему модификатору Харизмы (минимум 1).`,
    requirement: {
      pcSubLassId: PC_SUBCLASS_WARLOCK_THE_HEXBLADE,
    },
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 3,
    },
  },
  {
    id: INVOCATION_CAIPHON_S_BEACON_2017_02_13,
    name: `Кайфонов маяк`,
    nameEn: `Caiphon’s Beacon`,
    description: `Пурпурная звезда Кайфон — гибель неопытных моряков. Те, кто направляет свои суда, пользуясь его обманчивым светом, неизменно терпят крах.

Вы получаете владение навыками Обмана и Скрытности, и получаете преимущество к атакам по очарованным существам.`,
    requirement: {
      pcSubLassId: PC_SUBCLASS_WARLOCK_THE_GREAT_OLD_ONE,
    },
    abandonedAt: {
      id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
    },
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 3,
    },
  },
  {
    id: INVOCATION_CHAIN_MASTERS_FURY_2019_04_11,
    releasedAs: INVOCATION_INVESTMENT_OF_THE_CHAIN_MASTER,
    name: `Ярость цепного мастера`,
    nameEn: `Chain Master’s Fury`,
    description: `Бонусным действием Вы можете приказать фамильяру совершить одну атаку.`,
    requirement: {
      lvl: 9,
      featureId: FEATURE_PACT_OF_THE_CHAIN,
    },
    source: {
      id: SOURCE_UA_2019_11_04_CLASS_FEATURE_VARIANTS,
      page: 11,
    },
  },
  {
    id: INVOCATION_CHAINS_OF_CARCERI,
    name: `Цепи Карцери`,
    nameEn: `Chains of Carceri`,
    description: `Вы можете неограниченно сотворять заклинание [Удержание чудовища](SPELL:${SPELL_HOLD_MONSTER}), если цель — исчадие, небожитель или элементаль. При этом Вы не тратите ячейки заклинаний и материальные компоненты. Вы должны закончить длинный отдых, прежде чем снова сможете использовать это заклинание против этого же существа. `,
    requirement: {
      lvl: 15,
      featureId: FEATURE_PACT_OF_THE_CHAIN,
    },
    source: {
      id: SOURCE_PHB,
      page: 112,
    },
  },
  {
    id: INVOCATION_CHILLING_HEX_2017_02_13,
    releasedAs: INVOCATION_MADDENING_HEX,
    name: `Леденящее проклятье`,
    nameEn: `Chilling Hex`,
    description: `Вы можете бонусным действием заставить стужу кружиться вокруг цели, проклятой Вашим _Проклятием Ведьмовского клинка_, нанося урон холодом всем врагам в пределах 5 футов от неё. Урон равен Вашему модификатору Харизмы (минимум 1).`,
    requirement: {
      pcSubLassId: PC_SUBCLASS_WARLOCK_THE_HEXBLADE,
    },
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 3,
    },
  },
  {
    id: INVOCATION_CHRONICLE_OF_THE_RAVEN_QUEEN_2017_02_13,
    name: `Хроника Королевы Воронов`,
    nameEn: `Chronicle of the Raven Queen`,
    description: `Вы можете положите руку или другую часть трупа на свою Книгу Теней и вслух задать один вопрос. Через 1 минуту ответ появится в Книге, записанный кровью.

Ответ даётся духом мёртвого существа, используя все доступные ему знания и переводится на язык по Вашему выбору.

Вы должны использовать эту способность в течение 1 минуты после смерти существ и ему нельзя задать больше одного вопроса.`,
    requirement: {
      featureId: FEATURE_PACT_OF_THE_TOME,
      pcSubLassId: PC_SUBCLASS_WARLOCK_THE_RAVEN_QUEEN_2017_02_13,
    },
    abandonedAt: {
      id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
    },
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 3,
    },
  },
  {
    id: INVOCATION_CLAW_OF_ACAMAR_2017_02_13,
    name: `Коготь Акамара`,
    nameEn: `Claw of Acamar`,
    description: `Вы создаёте способностью _Договор клинка_ чёрный свинцовый [цеп](GEAR:${GEAR_FLAIL}).

Било цепа выглядит как два сплетённых щупальца.

У цепа есть свойства «досягаемость».

Когда Вы попадаете им по существу, Вы можете потратить одну ячейку заклинаний чтобы нанести цели дополнительно 2к8 некротического урона за круг ячейки и уменьшить скорость цели до 0 до конца Вашего следующего хода.`,
    requirement: {
      featureId: FEATURE_PACT_OF_THE_BLADE,
      pcSubLassId: PC_SUBCLASS_WARLOCK_THE_GREAT_OLD_ONE,
    },
    abandonedAt: {
      id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
    },
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 3,
    },
  },
  {
    id: INVOCATION_CLOAK_OF_BAALZEBUL_2017_02_13,
    releasedAs: INVOCATION_CLOAK_OF_FLIES,
    name: `Плащ Вельзевула`,
    nameEn: `Cloak of Baalzebul`,
    description: `Вы можете бонусным действием окружить себя жужжащим роем мух.

Рой даёт Вам преимущество на проверки Харизмы (Запугивание), но даёт помеху на все другие проверки Харизмы. Любое другое существо, начинающее свой ход в ауре, получает урон ядом, равный вашему модификатору Харизмы.

Вы можете распустить рой другим бонусным действием.`,
    requirement: {
      pcSubLassId: PC_SUBCLASS_WARLOCK_THE_FIEND,
    },
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 3,
    },
  },
  {
    id: INVOCATION_CLOAK_OF_FLIES,
    name: `Плащ мух`,
    nameEn: `Cloak of Flies`,
    description: `Вы можете бонусным действием окружить себя магической аурой, которая выглядит как множество мух. Аура распространяется на 5 футов от Вас во всех направлениях, но не через полное укрытие. Этот эффект длится пока Вы дееспособны или пока Вы не отмените его бонусным действием.

Аура даёт Вам преимущество на проверки Харизмы (Запугивание), но даёт помеху на все другие проверки Харизмы. Любое другое существо, начинающее свой ход в ауре, получает урон ядом, равный вашему модификатору Харизмы (минимум 0 урона).

Использовав это воззвание, Вы не сможете использовать его снова, пока не закончите короткий или длинный отдых.`,
    requirement: {
      lvl: 5,
    },
    source: [
      {
        id: SOURCE_XGTE,
        page: 41,
      },
      {
        id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
        page: 5,
      },
    ],
  },
  {
    id: INVOCATION_CURSE_BRINGER_2017_02_13,
    name: `Проклинатель`,
    nameEn: `Curse Bringer`,
    description: `Вы можете создать способностью _Договор клинка_ серебряный [двуручный меч](GEAR:${GEAR_GREATSWORD}), на клинке которого вырезаны чёрные руны.

Если Вы уменьшаете до 0 хиты цели, проклятой Вашим _Проклятием Ведьмовского клинка_, то Вы можете немедленно поменять цель Проклятья на другое существо. Это не продлевает время действия Проклятья.

Когда Вы попадаете по существу этим оружием, Вы можете потратить ячейку заклинания, чтобы нанести цели 2к8 рубящего урона за круг заклинания и при этом можете уменьшить скорость существа до 0 до конца Вашего следующего хода.`,
    requirement: {
      featureId: FEATURE_PACT_OF_THE_BLADE,
      pcSubLassId: PC_SUBCLASS_WARLOCK_THE_HEXBLADE,
    },
    abandonedAt: {
      id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
    },
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 3,
    },
  },
  {
    id: INVOCATION_DEVILS_SIGHT,
    name: `Дьявольский взгляд`,
    nameEn: `Devil’s Sight`,
    description: `Вы можете нормально видеть в темноте, как магической, так и немагической, на расстоянии 120 футов.`,
    source: {
      id: SOURCE_PHB,
      page: 111,
    },
  },
  {
    id: INVOCATION_DREADFUL_WORD,
    name: `Ужасающее слово`,
    nameEn: `Dreadful Word`,
    description: `Вы можете один раз сотворить заклинание [Смятение](SPELL:${SPELL_CONFUSION}), используя ячейку заклинаний колдуна. Вы не можете сделать это повторно, пока не окончите длинный отдых.`,
    requirement: {
      lvl: 7,
    },
    source: {
      id: SOURCE_PHB,
      page: 112,
    },
  },
  {
    id: INVOCATION_ELDRITCH_ARMOR_2019_04_11,
    name: `Мистический доспех`,
    nameEn: `Eldritch Armor`,
    description: `Действием Вы можете коснуться доспеха, который никто не несёт и не носит и мгновенно надеть его, если на Вас ещё не надет доспех.

Вы считаетесь владеющим этим доспехом, пока он надет на Вас.`,
    requirement: {
      featureId: FEATURE_PACT_OF_THE_BLADE,
    },
    source: {
      id: SOURCE_UA_2019_11_04_CLASS_FEATURE_VARIANTS,
      page: 11,
    },
  },
  {
    id: INVOCATION_ELDRITCH_MIND,
    name: `Мистический разум`,
    nameEn: `Eldritch Mind`,
    description: `Вы получаете преимущество к испытаниям Телосложения для поддержания концентрации на заклинании.`,
    source: {
      id: SOURCE_TCoE,
      page: 106,
    },
  },
  {
    id: INVOCATION_ELDRITCH_SIGHT,
    name: `Таинственный взгляд`,
    nameEn: `Eldritch Sight`,
    description: `Вы можете неограниченно сотворять заклинание [Обнаружение магии](SPELL:${SPELL_DETECT_MAGIC}), не тратя ячейки заклинаний.`,
    source: {
      id: SOURCE_PHB,
      page: 112,
    },
  },
  {
    id: INVOCATION_ELDRITCH_SMITE,
    name: `Мистическая кара`,
    nameEn: `Eldritch Smite`,
    description: `Один раз в ход, когда Вы попадаете по существу своим оружием договора, Вы можете потратить ячейку заклинаний колдуна, чтобы нанести цели дополнительные 1к8 урона силовым полем, плюс ещё 1к8 за каждый круг ячейки заклинания.

Если цель Огромного или меньшего размера, Вы также можете сбить её с ног.`,
    requirement: {
      lvl: 5,
      featureId: FEATURE_PACT_OF_THE_BLADE,
    },
    source: {
      id: SOURCE_XGTE,
      page: 41,
    },
  },
  {
    id: INVOCATION_ELDRITCH_SMITE_2017_06_05,
    releasedAs: INVOCATION_ELDRITCH_SMITE,
    name: `Мистическая кара`,
    nameEn: `Eldritch Smite`,
    isReady: false,
    requirement: {
      lvl: 5,
      featureId: FEATURE_PACT_OF_THE_BLADE,
    },
    source: {
      id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
      page: 6,
    },
  },
  {
    id: INVOCATION_ELDRITCH_SPEAR,
    name: `Мистическое копьё`,
    nameEn: `Eldritch Spear`,
    description: `Когда Вы накладываете [Мистический заряд](SPELL:${SPELL_ELDRITCH_BLAST}), его дистанция равна 300 футам.`,
    requirement: {
      spell: SPELL_ELDRITCH_BLAST,
    },
    source: {
      id: SOURCE_PHB,
      page: 112,
    },
  },
  {
    id: INVOCATION_EYES_OF_THE_RUNE_KEEPER,
    name: `Глаза хранителя рун`,
    nameEn: `Eyes of the Rune Keeper`,
    description: `Вы можете читать любые письмена.`,
    source: {
      id: SOURCE_PHB,
      page: 111,
    },
  },
  {
    id: INVOCATION_FAR_SCRIBE,
    name: `Далёкие письмена`,
    nameEn: `Far Scribe`,
    description: `В Вашей Книге Теней появляется новая страница.

С Вашего разрешения, существо может действием вписать на эту странице своё имя. На страницу может быть вписано количество имён, равное Вашему бонусу мастерства.

Вы можете сотворять заклинание [Послание](SPELL:${SPELL_SENDING}), выбирая существ, чьи имена написаны на этой странице, не тратя ячейки заклинаний и материальные компоненты. Для этого Вы должны написать своё послание на этой странице. Цель слышит в голове Ваше сообщение и, если она отвечает, то её ответ появляется на этой же странице, а не в Вашей голове. Письмена исчезают спустя 1 минуту.

Вы можете действием коснуться имени на странице и магически стереть его.`,
    requirement: {
      lvl: 5,
      featureId: FEATURE_PACT_OF_THE_TOME,
    },
    source: {
      id: SOURCE_TCoE,
      page: 106,
    },
  },
  {
    id: INVOCATION_FIENDISH_VIGOR,
    name: `Мощь исчадия`,
    nameEn: `Fiendish Vigor`,
    description: `Вы можете неограниченно сотворять на себя заклинание [Псевдожизнь](SPELL:${SPELL_FALSE_LIFE}) как заклинание 1 уровня, не тратя ячейки заклинаний и материальные компоненты.`,
    source: {
      id: SOURCE_PHB,
      page: 112,
    },
  },
  {
    id: INVOCATION_FROST_LANCE_2017_02_13,
    releasedAs: INVOCATION_LANCE_OF_LETHARGY,
    name: `Ледяное копьё`,
    nameEn: `Frost Lance`,
    description: `Вы запираете своих врагов во льду Принца Мороза.

Когда Вы попадаете по существу [Мистическим зарядом](SPELL:${SPELL_ELDRITCH_BLAST}) один или более раз на своём ходу, Вы можете понизить его скорость на 10 футов до конца Вашего следующего хода.`,
    requirement: {
      pcSubLassId: PC_SUBCLASS_WARLOCK_THE_ARCHFEY,
      spell: SPELL_ELDRITCH_BLAST,
    },
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 3,
    },
  },
  {
    id: INVOCATION_FROST_LANCE_2017_06_05,
    releasedAs: INVOCATION_LANCE_OF_LETHARGY,
    isReady: false,
    name: `Ледяное копьё`,
    nameEn: `Frost Lance`,
    requirement: {
      spell: SPELL_ELDRITCH_BLAST,
    },
    source: {
      id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
      page: 6,
    },
  },
  {
    id: INVOCATION_GAZE_OF_KHIRAD_2017_02_13,
    releasedAs: INVOCATION_GHOSTLY_GAZE,
    name: `Взгляд Хирада`,
    nameEn: `Gaze of Khirad`,
    description: `Вы получаете пронзающий взгляд синей звезды Хирад.

Действием Вы можете смотреть сквозь твёрдые предметы на расстояние до 30 футов до конца текущего хода. В это время Вы видите предметы как прозрачные призрачные образы.`,
    requirement: {
      lvl: 7,
      pcSubLassId: PC_SUBCLASS_WARLOCK_THE_GREAT_OLD_ONE,
    },
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 3,
    },
  },
  {
    id: INVOCATION_GAZE_OF_TWO_MINDS ,
    name: `Взор двух умов`,
    nameEn: `Gaze of Two Minds`,
    description: `Вы можете действием коснуться согласного гуманоида и до конца своего следующего хода воспринимать всё его чувствами. Пока существо находится на том же плане существования, что и Вы, Вы можете в последующие ходы действием продлевать эту связь до конца своего следующего хода. При восприятии чувствами другого существа, Вы получаете все преимущества от особых чувств, которыми обладает это существо, а сами при этом слепы и глухи.`,
    source: {
      id: SOURCE_PHB,
      page: 111,
    },
  },
  {
    id: INVOCATION_GHOSTLY_GAZE,
    name: `Призрачный взор`,
    nameEn: `Ghostly Gaze`,
    description: `Вы получаете возможность действием смотреть сквозь твёрдые объекты в радиусе 30 футов. В этом диапазоне у Вас есть тёмное зрение, если у Вас его ещё нет.

Это особое зрение длится 1 минуту или до тех пор, пока Ваша концентрация не закончится (как при концентрации на заклинании). В это время Вы воспринимаете объекты как призрачные, прозрачные образы.

Использовав это воззвание, Вы не сможете использовать его снова, пока не закончите короткий или длинный отдых.`,
    requirement: {
      lvl: 7,
    },
    source: {
      id: SOURCE_XGTE,
      page: 41,
    },
  },
  {
    id: INVOCATION_GHOSTLY_GAZE_2017_06_05,
    releasedAs: INVOCATION_GHOSTLY_GAZE,
    name: `Призрачный взор`,
    nameEn: `Ghostly Gaze`,
    isReady: false,
    requirement: {
      lvl: 7,
    },
    source: {
      id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
      page: 6,
    },
  },
  {
    id: INVOCATION_GIFT_OF_THE_DEPTHS,
    name: `Дар глубин`,
    nameEn: `Gift of the Depths`,
    description: `Вы можете дышать под водой, и получаете скорость плавания, равную Вашей скорости ходьбы.

Вы также можете один раз сотворить [Подводное дыхание](SPELL:${SPELL_WATER_BREATHING}), не тратя ячейки заклинаний. Вы восстанавливаете это умение, когда заканчиваете длинный отдых.`,
    requirement: {
      lvl: 5,
    },
    source: {
      id: SOURCE_XGTE,
      page: 41,
    },
  },
  {
    id: INVOCATION_GIFT_OF_THE_DEPTHS_2017_06_05,
    releasedAs: INVOCATION_GIFT_OF_THE_DEPTHS,
    name: `Дар глубин`,
    nameEn: `Gift of the Depths`,
    isReady: false,
    requirement: {
      lvl: 5,
    },
    source: {
      id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
      page: 6,
    },
  },
  {
    id: INVOCATION_GIFT_OF_THE_EVER_LIVING_ONES,
    name: `Дар вечноживых`,
    nameEn: `Gift of the Ever-Living Ones`,
    description: `Всякий раз, когда Вы восстанавливаете хиты и Ваш фамильяр находится на расстоянии 100 футов от Вас, все кости, брошенные для восстановления хитов, для Вас имеют максимальное значение.`,
    requirement: {
      featureId: FEATURE_PACT_OF_THE_CHAIN,
    },
    source: {
      id: SOURCE_XGTE,
      page: 41,
    },
  },
  {
    id: INVOCATION_GIFT_OF_THE_EVER_LIVING_ONES_2017_06_05,
    releasedAs: INVOCATION_GIFT_OF_THE_EVER_LIVING_ONES,
    name: `Дар вечноживых`,
    nameEn: `Gift of the Ever-Living Ones`,
    isReady: false,
    requirement: {
      featureId: FEATURE_PACT_OF_THE_CHAIN,
    },
    source: {
      id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
      page: 6,
    },
  },
  {
    id: INVOCATION_GIFT_OF_THE_PROTECTORS,
    name: `Дар защитников`,
    nameEn: `Gift of the Protectors`,
    description: `В Вашей Книге Теней появляется новая страница.

С Вашего разрешения, существо может действием вписать на эту странице своё имя. На страницу может быть вписано количество имён, равное Вашему бонусу мастерства.

Когда существо, чьё имя вписано на эту страницу, достигает 0 хитов, но не погибает мгновенно, вместо этого оно магически достигает 1 хита. После того, как эта магия сработала, ни одно существо не может больше получить это преимущество, пока Вы не закончите длинный отдых.

Вы можете действием коснуться имени на странице и магически стереть его.`,
    requirement: {
      lvl: 9,
      featureId: FEATURE_PACT_OF_THE_TOME,
    },
    source: {
      id: SOURCE_TCoE,
      page: 106,
    },
  },
  {
    id: INVOCATION_GRASP_OF_HADAR,
    name: `Хватка Хадара`,
    nameEn: `Grasp of Hadar`,
    description: `Один раз в каждом из Ваших ходов, когда Вы попадаете по существу [Мистическим зарядом](SPELL:${SPELL_ELDRITCH_BLAST}), Вы можете переместить это существо по прямой линии на 10 футов ближе к Вам.`,
    requirement: {
      spell: SPELL_ELDRITCH_BLAST,
    },
    source: [
      {
        id: SOURCE_XGTE,
        page: 41,
      },
      {
        id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
        page: 6,
      },
      {
        id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
        page: 3,
      },
    ],
  },
  {
    id: INVOCATION_GREEN_LORD_S_GIFT_2017_02_13,
    releasedAs: INVOCATION_GIFT_OF_THE_EVER_LIVING_ONES,
    name: `Дар зелёного владыки`,
    nameEn: `Green Lord’s Gift`,
    description: `Зелёный владыка надзирает за цветущим царством вечного лета. А Ваша душа привязывается к его силе.

Когда Вы восстанавливаете хиты, то считаете что на каждой брошенной кости выпадает максимальное значение.`,
    requirement: {
      pcSubLassId: PC_SUBCLASS_WARLOCK_THE_ARCHFEY,
    },
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 3,
    },
  },
  {
    id: INVOCATION_IMPROVED_PACT_WEAPON,
    name: `Улучшенное оружие договора`,
    nameEn: `Improved Pact Weapon`,
    description: `Вы можете использовать любое оружие, призванное с помощью умения Договор клинка, в качестве своей заклинательной фокусировки колдуна.

Кроме того, это оружие получает +1 бонус к атакам и броскам урона, если это не волшебное оружие, которое уже имеет бонус к этим броскам.

Наконец, призванное оружие может быть [коротким луком](GEAR:${GEAR_SHORTBOW}), [длинным луком](GEAR:${GEAR_LONGBOW}), [лёгким арбалетом](GEAR:${GEAR_CROSSBOW_LIGHT}) или [тяжёлым арбалетом](GEAR:${GEAR_CROSSBOW_HEAVY}).`,
    requirement: {
      featureId: FEATURE_PACT_OF_THE_BLADE,
    },
    source: {
      id: SOURCE_XGTE,
      page: 41,
    },
  },
  {
    id: INVOCATION_IMPROVED_PACT_WEAPON_2017_02_13,
    releasedAs: INVOCATION_IMPROVED_PACT_WEAPON,
    name: `Улучшенное оружие договора`,
    nameEn: `Improved Pact Weapon`,
    isReady: false,
    requirement: {
      lvl: 5,
      featureId: FEATURE_PACT_OF_THE_BLADE,
    },
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 4,
    },
  },
  {
    id: INVOCATION_IMPROVED_PACT_WEAPON_2017_06_05,
    releasedAs: INVOCATION_IMPROVED_PACT_WEAPON,
    name: `Улучшенное оружие договора`,
    nameEn: `Improved Pact Weapon`,
    isReady: false,
    requirement: {
      featureId: FEATURE_PACT_OF_THE_BLADE,
    },
    source: {
      id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
      page: 6,
    },
  },
  {
    id: INVOCATION_INVESTMENT_OF_THE_CHAIN_MASTER,
    name: `Вложение мастера цепей`,
    nameEn: `Investment of the Chain Master`,
    description: `Когда Вы сотворяете заклинание [Поиск фамильяра](SPELL:${SPELL_FIND_FAMILIAR}), Вы наполняете вызванного фамильяра частью Ваших мистических сил, наделяя это существо следующими преимуществами:

* Фамильяр получает скорость полёта или скорость плавания (по Вашему выбору) 40 футов.
* Вы можете бонусным действием приказать фамильяру совершить действие Атака.
* Атаки оружием фамильяра теперь считаются магическими с целью преодоления иммунитета и сопротивления к немагическим атакам.
* Если фамильяр заставляет существо пройти испытание, он использует для этого Вашу сложность заклинаний.
* Когда фамильяр получает урон, Вы можете реакцией дать емк сопротивление к этому виду урона.`,
    requirement: {
      featureId: FEATURE_PACT_OF_THE_CHAIN,
    },
    source: {
      id: SOURCE_TCoE,
      page: 106,
    },
  },
  {
    id: INVOCATION_KISS_OF_MEPHISTOPHELES_2017_02_13,
    releasedAs: INVOCATION_KISS_OF_MEPHISTOPHELES_2017_06_05,
    name: `Поцелуй Мефистофеля`,
    nameEn: `Kiss of Mephistopheles`,
    description: `Вы можете проводить пламя Мефистофеля через свой [Мистический заряд](SPELL:${SPELL_ELDRITCH_BLAST}).

Когда Вы попадаете по существу этим заговором, Вы можете бонусным действием сотворить [Огненный шар](SPELL:${SPELL_FIREBALL}) используя ячейку заклинаний колдуна. Центром [Огненного шара](SPELL:${SPELL_FIREBALL}) считается существо, в которое Вы попали [Мистическим зарядом](SPELL:${SPELL_ELDRITCH_BLAST}).`,
    requirement: {
      lvl: 5,
      pcSubLassId: PC_SUBCLASS_WARLOCK_THE_FIEND,
      spell: SPELL_ELDRITCH_BLAST,
    },
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 3,
    },
  },
  {
    id: INVOCATION_KISS_OF_MEPHISTOPHELES_2017_06_05,
    name: `Поцелуй Мефистофеля`,
    nameEn: `Kiss of Mephistopheles`,
    description: `Вы можете проводить пламя Мефистофеля через свой [Мистический заряд](SPELL:${SPELL_ELDRITCH_BLAST}).

Когда Вы попадаете по существу этим заговором, Вы можете бонусным действием сотворить [Огненный шар](SPELL:${SPELL_FIREBALL}) используя ячейку заклинаний колдуна. Центром [Огненного шара](SPELL:${SPELL_FIREBALL}) считается существо, в которое Вы попали [Мистическим зарядом](SPELL:${SPELL_ELDRITCH_BLAST}).`,
    requirement: {
      lvl: 5,
      spell: SPELL_ELDRITCH_BLAST,
    },
    source: {
      id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
      page: 3,
    },
  },
  {
    id: INVOCATION_LIFEDRINKER,
    name: `Пьющий жизнь`,
    nameEn: `Lifedrinker`,
    description: `Когда Вы попадаете по существу оружием договора, это существо получает дополнительный урон некротической энергией, равный Вашему модификатору Харизмы (минимум 1).`,
    requirement: {
      lvl: 12,
      featureId: FEATURE_PACT_OF_THE_BLADE,
    },
    source: {
      id: SOURCE_PHB,
      page: 112,
    },
  },
  {
    id: INVOCATION_LANCE_OF_LETHARGY,
    name: `Копье летаргии`,
    nameEn: `Lance of Lethargy`,
    description: `Один раз в каждом из Ваших ходов, когда Вы попадаете по существу [Мистическим зарядом](SPELL:${SPELL_ELDRITCH_BLAST}), Вы можете до конца Вашего следующего хода уменьшить его скорость на 10 футов.`,
    requirement: {
      spell: SPELL_ELDRITCH_BLAST,
    },
    source: {
      id: SOURCE_XGTE,
      page: 41,
    },
  },
  {
    id: INVOCATION_MACE_OF_DISPATER_2017_02_13,
    name: `Булава Диспатера`,
    nameEn: `Mace of Dispater`,
    description: `Когда Вы создаёте булаву способностью _Договор клинка_, она проявляется в виде железной [булавы](GEAR:${GEAR_MACE}), выкованной в Дисе, втором слоё Девяти Преисподней.

Когда Вы попадаете этой [булавой](GEAR:${GEAR_MACE}) по существу, Вы можете потратить ячейку заклинания, чтобы нанести цели 2к8 урона силовым полем за круг заклинания и при этом можете сбить его с ног, если оно Огромного размера или меньше.`,
    requirement: {
      featureId: FEATURE_PACT_OF_THE_BLADE,
      pcSubLassId: PC_SUBCLASS_WARLOCK_THE_FIEND,
    },
    abandonedAt: {
      id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
    },
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 3,
    },
  },
  {
    id: INVOCATION_MADDENING_HEX,
    name: `Безумное проклятие`,
    nameEn: `Maddening Hex`,
    description: `Вы можете бонусным действием вызвать психическое возмущение вокруг цели, проклятой Вашим заклинанием [Сглаз](SPELL:${SPELL_HEX}) или Вашим умением колдуна, таким как _Проклятие Ведьмовского клинка_ или [Знак дурного предзнаменования](INVOCATION:${INVOCATION_SIGN_OF_ILL_OMEN}).

Когда Вы это делаете, Вы наносите психический урон проклятой цели и каждому существу по Вашему выбору, видимому Вами в пределах 5 футов от цели. Психический урон равен Вашему модификатору Харизмы (минимум 1 урона).

Чтобы использовать этот воззвание, Вы должны иметь возможность видеть проклятую цель, и она должна находиться в пределах 30 футов от Вас.`,
    requirement: {
      lvl: 5,
      spell: {
        spellId: SPELL_HEX,
        comment: `или способность колдуна к проклятию`,
      },
    },
    source: {
      id: SOURCE_XGTE,
      page: 41,
    },
  },
  {
    id: INVOCATION_MADDENING_HEX_2017_06_05,
    releasedAs: INVOCATION_MADDENING_HEX,
    name: `Безумное проклятие`,
    nameEn: `Maddening Hex`,
    idReady: false,
    requirement: {
      lvl: 5,
    },
    source: {
      id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
      page: 6,
    },
  },
  {
    id: INVOCATION_MASK_OF_MANY_FACES,
    name: `Маска многих лиц`,
    nameEn: `Mask of Many Faces`,
    description: `Вы можете неограниченно сотворять заклинание [Маскировка](SPELL:${SPELL_DISGUISE_SELF}), не тратя ячейки заклинаний.`,
    source: {
      id: SOURCE_PHB,
      page: 112,
    },
  },
  {
    id: INVOCATION_MASTER_OF_MYRIAD_FORMS,
    name: `Мастер бесчисленных обликов`,
    nameEn: `Master of Myriad Forms`,
    description: `Вы можете неограниченно сотворять заклинание [Смена обличья](SPELL:${SPELL_ALTER_SELF}), не тратя ячейки заклинаний.`,
    requirement: {
      lvl: 15,
    },
    source: {
      id: SOURCE_PHB,
      page: 112,
    },
  },
  {
    id: INVOCATION_MINIONS_OF_CHAOS,
    name: `Слуги хаоса`,
    nameEn: `Minions of Chaos`,
    description: `Вы можете один раз сотворить заклинание [Призыв элементаля](SPELL:${SPELL_CONJURE_ELEMENTAL}), используя ячейку заклинаний колдуна. Вы не можете сделать это повторно, пока не окончите длинный отдых. `,
    requirement: {
      lvl: 9,
    },
    source: {
      id: SOURCE_PHB,
      page: 112,
    },
  },
  {
    id: INVOCATION_MIRE_THE_MIND,
    name: `Трясина разума`,
    nameEn: `Mire the Mind`,
    description: `Вы можете один раз сотворить заклинание [Замедление](SPELL:${SPELL_SLOW}), используя ячейку заклинаний колдуна. Вы не можете сделать это повторно, пока не окончите длинный отдых.`,
    requirement: {
      lvl: 5,
    },
    source: {
      id: SOURCE_PHB,
      page: 112,
    },
  },
  {
    id: INVOCATION_MISTY_VISIONS,
    name: `Туманные видения`,
    nameEn: `Misty Visions`,
    description: `Вы можете неограниченно сотворять заклинание [Безмолвный образ](SPELL:${SPELL_SILENT_IMAGE}), не тратя ячейки заклинаний и материальные компоненты.`,
    source: {
      id: SOURCE_PHB,
      page: 112,
    },
  },
  {
    id: INVOCATION_MOON_BOW_2017_02_13,
    name: `Лунный лук`,
    nameEn: `Moon Bow`,
    description: `Вы можете создавать длинный лук способностью _Договор клинка_.

Когда Вы натягиваете его тетиву и стреляете, он создаёт стрелу белого дерева, исчезающую через минуту.

Вы получаете преимущество к атакам этим луком по ликантропам.

Когда Вы попадаете им по существу, Вы можете потратить ячейку заклинания, чтобы нанести цели 2к8 урона излучением за круг заклинания.`,
    requirement: {
      featureId: FEATURE_PACT_OF_THE_BLADE,
      pcSubLassId: PC_SUBCLASS_WARLOCK_THE_ARCHFEY,
    },
    abandonedAt: {
      id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
    },
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 3,
    },
  },
  {
    id: INVOCATION_ONE_WITH_SHADOWS,
    name: `Один среди теней`,
    nameEn: `One with Shadows`,
    description: `Когда Вы находитесь в области тусклого света или в темноте, Вы можете действием стать невидимым, пока Вы не переместитесь или не используете действие или реакцию.`,
    requirement: {
      lvl: 5,
    },
    source: {
      id: SOURCE_PHB,
      page: 112,
    },
  },
  {
    id: INVOCATION_OTHERWORLDLY_LEAP,
    name: `Потусторонний прыжок`,
    nameEn: `Otherworldly Leap`,
    description: `Вы можете неограниченно сотворять на себя заклинание [Прыжок](SPELL:${SPELL_JUMP}), не тратя ячейки заклинаний и материальные компоненты. `,
    requirement: {
      lvl: 9,
    },
    source: {
      id: SOURCE_PHB,
      page: 112,
    },
  },
  {
    id: INVOCATION_PATH_OF_THE_SEEKER_2017_02_13,
    name: `Путь Искателя`,
    nameEn: `Path of the Seeker`,
    description: `Искатель побуждает Вас отправиться в путешествие в поисках знаний, и мало что может помешать Вам встать на этот путь.

Вы игнорируете труднопроходимую местность и получаете преимущество ко всем проверкам для выбирания из захвата, оков, верёвок и преимущество к испытаниям от паралича.`,
    requirement: {
      pcSubLassId: PC_SUBCLASS_WARLOCK_THE_SEEKER_2016_08_01,
    },
    abandonedAt: {
      id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
    },
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 3,
    },
  },
  {
    id: INVOCATION_PROTECTION_OF_THE_TALISMAN,
    name: `Защита талисмана`,
    nameEn: `Protection of the Talisman`,
    description: `Когда носитель Вашего талисмана проваливает испытание, он может добавить 1к4 к результату, потенциально превращая провал в успех.

Эту способность можно использовать количество раз, равное Вашему бонусу мастерства, и все использования восстанавливаются, когда Вы заканчиваете длинный отдых.`,
    requirement: {
      lvl: 7,
      featureId: FEATURE_PACT_OF_THE_TALISMAN,
    },
    source: {
      id: SOURCE_TCoE,
      page: 106,
    },
  },
  {
    id: INVOCATION_RAVEN_QUEEN_S_BLESSING_2017_02_13,
    name: `Благословение Королевы Воронов`,
    nameEn: `Raven Queen’s Blessing`,
    description: `Когда Вы совершаете критическое попадание [Мистическим зарядом](SPELL:${SPELL_ELDRITCH_BLAST}), выберите себя или видимого Вами в пределах 30 футов союзника. Выбранное существо может немедленно потратить Кость Хитов, чтобы восстановить хиты, добавляя к результату свой модификатор Телосложения (минимум 1 хит).`,
    requirement: {
      pcSubLassId: PC_SUBCLASS_WARLOCK_THE_RAVEN_QUEEN_2017_02_13,
      spell: SPELL_ELDRITCH_BLAST,
    },
    abandonedAt: {
      id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
    },
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 3,
    },
  },
  {
    id: INVOCATION_REBUKE_OF_THE_TALISMAN_2019_04_11,
    name: `Возмездие талисмана`,
    nameEn: `Rebuke of the Talisman`,
    description: `Когда нападающий, видимый Вами в пределах 30 футов, попадает атакой по носителю Вашего талисмана, Вы можете реакцией нанести этому нападающему психический урон, равный Вашему модификатору Харизмы (минимум 1 урона) и оттолкнуть его на расстояние до 10 футов от носителя талисмана.`,
    requirement: {
      featureId: FEATURE_PACT_OF_THE_TALISMAN,
    },
    source: {
      id: SOURCE_UA_2019_11_04_CLASS_FEATURE_VARIANTS,
      page: 12,
    },
  },
  {
    id: INVOCATION_RELENTLESS_HEX,
    name: `Безжалостное проклятие`,
    nameEn: `Relentless Hex`,
    description: `Ваше проклятие создает временную связь между Вами и Вашей целью.

Вы можете бонусным действием магически телепортироваться на расстояние до 30 футов, в незанятое пространство, видимое Вами в пределах 5 футов от цели, проклятой Вашим заклинанием [Сглаз](SPELL:${SPELL_HEX}) или Вашим умением колдуна, таким как _Проклятие Ведьмовского клинка_ или [Знак дурного предзнаменования](INVOCATION:${INVOCATION_SIGN_OF_ILL_OMEN}).

Чтобы телепортироваться таким образом, Вы должны иметь возможность видеть проклятую цель.`,
    requirement: {
      lvl: 7,
      spell: {
        spellId: SPELL_HEX,
        comment: `или способность колдуна к проклятию`,
      },
    },
    source: {
      id: SOURCE_XGTE,
      page: 40,
    },
  },
  {
    id: INVOCATION_RELENTLESS_HEX_2017_02_13,
    releasedAs: INVOCATION_RELENTLESS_HEX,
    name: `Безжалостное проклятие`,
    nameEn: `Relentless Hex`,
    idReady: false,
    requirement: {
      lvl: 5,
      pcSubLassId: PC_SUBCLASS_WARLOCK_THE_HEXBLADE,
    },
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 3,
    },
  },
  {
    id: INVOCATION_RELENTLESS_HEX_2017_06_05,
    releasedAs: INVOCATION_RELENTLESS_HEX,
    name: `Безжалостное проклятие`,
    nameEn: `Relentless Hex`,
    idReady: false,
    requirement: {
      lvl: 7,
    },
    source: {
      id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
      page: 6,
    },
  },
  {
    id: INVOCATION_REPELLING_BLAST,
    name: `Отталкивающий заряд`,
    nameEn: `Repelling Blast`,
    description: `Если Вы попадаете по существу [Мистическим зарядом](SPELL:${SPELL_ELDRITCH_BLAST}), Вы можете толкнуть его на 10 футов прочь от себя по прямой линии.`,
    requirement: {
      spell: SPELL_ELDRITCH_BLAST,
    },
    source: {
      id: SOURCE_PHB,
      page: 112,
    },
  },
  {
    id: INVOCATION_SCULPTOR_OF_FLESH,
    name: `Скульптор плоти`,
    nameEn: `Sculptor of Flesh`,
    description: `Вы можете один раз сотворить заклинание [Превращение](SPELL:${SPELL_POLYMORPH}), используя ячейку заклинаний колдуна. Вы не можете сделать это повторно, пока не окончите длинный отдых.`,
    requirement: {
      lvl: 7,
    },
    source: {
      id: SOURCE_PHB,
      page: 112,
    },
  },
  {
    id: INVOCATION_SEA_TWINS_GIFT_2017_02_13,
    releasedAs: INVOCATION_GIFT_OF_THE_DEPTHS,
    name: `Дар Морских близнецов`,
    nameEn: `Sea Twins’ Gift`,
    description: `Морские близнецы правят морями Страны Фей. Их дар позволяет Вам легко путешествовать по воде.

Вы можете дышать под водой и получаете скорость плавания, равную Вашей скорости ходьбы.

Вы можете сотворять [Подводное дыхание](SPELL:${SPELL_WATER_BREATHING}), используя ячейку заклинаний колдуна. Вы не можете сделать это повторно, пока не закончите длинный отдых.`,
    requirement: {
      pcSubLassId: PC_SUBCLASS_WARLOCK_THE_ARCHFEY,
    },
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 3,
    },
  },
  {
    id: INVOCATION_SEEKER_S_SPEECH_2017_02_13,
    name: `Речь Искателя`,
    nameEn: `Seeker’s Speech`,
    description: `Ваша жажда знаний позволяет Вам овладеть любым устным языком.

Завершая длинный отдых, Вы выбираете два языка. Вы получаете способность говорить, читать и писать на выбранных языках до окончания следующего длинного отдыха.`,
    requirement: {
      pcSubLassId: PC_SUBCLASS_WARLOCK_THE_SEEKER_2016_08_01,
    },
    abandonedAt: {
      id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
    },
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 3,
    },
  },
  {
    id: INVOCATION_SHROUD_OF_SHADOW,
    name: `Саван теней`,
    nameEn: `Shroud of Shadow`,
    description: `Вы можете неограниченно сотворять заклинание [Невидимость](SPELL:${SPELL_INVISIBILITY}), не тратя ячейки заклинаний.`,
    requirement: {
      lvl: 15,
    },
    source: [
      {
        id: SOURCE_XGTE,
        page: 41,
      },
      {
        id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
        page: 6,
      },
    ],
  },
  {
    id: INVOCATION_SHROUD_OF_ULBAN_2017_02_13,
    releasedAs: INVOCATION_SHROUD_OF_SHADOW,
    name: `Пелена Ульбана`,
    nameEn: `Shroud of Ulban`,
    description: `Сине-белая звезда Ульбан непостоянно присутствует среди звезд, появляясь на небе только для того, чтобы предвещать ужасное предзнаменование.

Вы можете действием стать невидимым на 1 минуту. Если Вы совершаете атаку, наносите урон или заставляете существо пройти испытание, то Вы становитесь видимым в конце текущего хода.`,
    requirement: {
      lvl: 18,
      pcSubLassId: PC_SUBCLASS_WARLOCK_THE_GREAT_OLD_ONE,
    },
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 3,
    },
  },
  {
    id: INVOCATION_SIGN_OF_ILL_OMEN,
    name: `Знак дурного предзнаменования`,
    nameEn: `Sign of Ill Omen`,
    description: `Вы можете один раз сотворить заклинание [Проклятие](SPELL:${SPELL_BESTOW_CURSE}), используя ячейку заклинаний колдуна. Вы не можете сделать это повторно, пока не окончите длинный отдых. `,
    requirement: {
      lvl: 5,
    },
    source: {
      id: SOURCE_PHB,
      page: 112,
    },
  },
  {
    id: INVOCATION_SUPERIOR_PACT_WEAPON_2017_02_13,
    name: `Превосходное оружие договора`,
    nameEn: `Superior Pact Weapon`,
    description: `Любое оружие, создаваемое Вами способностью _Договор клинка_, получает +2 к броскам атаки и урона.

Это воззвание не влияет на магическое оружие, трансформированное в оружие договора.`,
    requirement: {
      lvl: 9,
      featureId: FEATURE_PACT_OF_THE_BLADE,
    },
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 3,
    },
  },
  {
    id: INVOCATION_THIEF_OF_FIVE_FATES ,
    name: `Вор пяти судеб`,
    nameEn: `Thief of Five Fates`,
    description: `Вы можете один раз сотворить заклинание [Порча](SPELL:${SPELL_BANE}), используя ячейку заклинаний колдуна. Вы не можете сделать это повторно, пока не окончите длинный отдых. `,
    source: {
      id: SOURCE_PHB,
      page: 111,
    },
  },
  {
    id: INVOCATION_THIRSTING_BLADE,
    name: `Жаждущий клинок`,
    nameEn: `Thirsting Blade`,
    description: `Вы можете атаковать своим оружием договора два раза вместо одного, когда в свой ход используете действие Атака.`,
    requirement: {
      lvl: 5,
      featureId: FEATURE_PACT_OF_THE_BLADE,
    },
    source: {
      id: SOURCE_PHB,
      page: 111,
    },
  },
  {
    id: INVOCATION_TOMB_OF_LEVISTUS,
    name: `Гробница Левита`,
    nameAlt: `Гробница Левистуса`,
    nameEn: `Tomb of Levistus`,
    description: `Если Вы получаете урон, то Вы можете реакцией покрыть себя льдом, который тает в конце Вашего следующего хода. Вы получаете 10 временных хитов за уровень колдуна, которые принимают как можно больше урона вызвавшего реакцию.

Сразу же после получения урона Вы получаете уязвимость к урону огнём, Ваша скорость уменьшается до 0, а Вы недееспособны. Эти эффекты и любые оставшиеся временные хиты пропадают, когда тает лёд.

Использовав это воззвание, Вы не сможете использовать его снова, пока не закончите короткий или длинный отдых.`,
    requirement: {
      lvl: 5,
    },
    source: {
      id: SOURCE_XGTE,
      page: 41,
    },
  },
  {
    id: INVOCATION_TOMB_OF_LEVISTUS_2017_02_13,
    releasedAs: INVOCATION_TOMB_OF_LEVISTUS,
    name: `Гробница Левита`,
    nameAlt: `Гробница Левистуса`,
    nameEn: `Tomb of Levistus`,
    isReady: false,
    requirement: {
      pcSubLassId: PC_SUBCLASS_WARLOCK_THE_FIEND,
    },
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 3,
    },
  },
  {
    id: INVOCATION_TOMB_OF_LEVISTUS_2017_06_05,
    releasedAs: INVOCATION_TOMB_OF_LEVISTUS,
    name: `Гробница Левита`,
    nameAlt: `Гробница Левистуса`,
    nameEn: `Tomb of Levistus`,
    isReady: false,
    requirement: {
      lvl: 5,
    },
    source: {
      id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
      page: 6,
    },
  },
  {
    id: INVOCATION_TRICKSTERS_ESCAPE,
    name: `Побег обманщика`,
    nameEn: `Trickster’s Escape`,
    description: `Вы можете один раз сотворить на себя [Свободу перемещения](SPELL:${SPELL_FREEDOM_OF_MOVEMENT}), не тратя ячейки заклинаний. Вы восстанавливаете это умение, когда заканчиваете длинный отдых.`,
    requirement: {
      lvl: 7,
    },
    source: [
      {
        id: SOURCE_XGTE,
        page: 41,
      },
      {
        id: SOURCE_UA_2017_06_05_REVISED_CLASS_OPTIONS,
        page: 7,
      },
    ],
  },
  {
    id: INVOCATION_ULTIMATE_PACT_WEAPON_2017_02_13,
    name: `Абсолютное оружие договора`,
    nameEn: `Ultimate Pact Weapon`,
    description: `Любое оружие, создаваемое Вами способностью _Договор клинка_, получает +3 к броскам атаки и урона.

Это воззвание не влияет на магическое оружие, трансформированное в оружие договора.`,
    requirement: {
      lvl: 15,
      featureId: FEATURE_PACT_OF_THE_BLADE,
    },
    source: {
      id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
      page: 3,
    },
  },
  {
    id: INVOCATION_UNDYING_SERVITUDE,
    name: `Мёртвое служение`,
    nameEn: `Undying Servitude`,
    description: `Вы можете сотворять заклинание [Восставший труп](SPELL:${SPELL_ANIMATE_DEAD}) без траты ячеек заклинаний.

Сделав так, Вы не можете снова сотворять его таким образом, пока не закончите длинный отдых`,
    requirement: {
      lvl: 5,
    },
    source: {
      id: SOURCE_TCoE,
      page: 106,
    },
  },
  {
    id: INVOCATION_VISIONS_OF_DISTANT_REALMS,
    name: `Видения далёких королевств`,
    nameEn: `Visions of Distant Realms`,
    description: `Вы можете неограниченно сотворять заклинание [Магический глаз](SPELL:${SPELL_ARCANE_EYE}), не тратя ячейки заклинаний. `,
    requirement: {
      lvl: 15,
    },
    source: {
      id: SOURCE_PHB,
      page: 111,
    },
  },
  {
    id: INVOCATION_VOICE_OF_THE_CHAIN_MASTER,
    name: `Глас цепного мастера`,
    nameEn: `Voice of the Chain Master`,
    description: `Вы можете телепатически общаться со своим фамильяром и воспринимать всё чувствами фамильяра, пока Вы находитесь на одном и том же плане существования. Кроме того, когда Вы воспринимаете чувствами фамильяра, Вы можете говорить через него своим собственным голосом, даже если фамильяр не способен говорить.`,
    requirement: {
      featureId: FEATURE_PACT_OF_THE_CHAIN,
    },
    source: {
      id: SOURCE_PHB,
      page: 111,
    },
  },
  {
    id: INVOCATION_WHISPERS_OF_THE_GRAVE,
    name: `Могильный шёпот`,
    nameEn: `Whispers of the Grave`,
    description: `Вы можете неограниченно сотворять заклинание [Разговор с мёртвыми](SPELL:${SPELL_SPEAK_WITH_DEAD}), не тратя ячейки заклинаний.`,
    requirement: {
      lvl: 9,
    },
    source: {
      id: SOURCE_PHB,
      page: 112,
    },
  },
  {
    id: INVOCATION_WITCH_SIGHT,
    name: `Ведьмачий взор`,
    nameEn: `Witch Sight`,
    description: `Вы можете видеть истинный облик всех перевёртышей и существ, скрытых магией школы Иллюзии или Преобразования, и находящихся в Вашей линии обзора, и при этом в пределах 30 футов от Вас.`,
    requirement: {
      lvl: 15,
    },
    source: {
      id: SOURCE_PHB,
      page: 111,
    },
  },
  // {
  //   id: INVOCATION__2017_02_13,
  //   name: ``,
  //   nameEn: ``,
  //   description: ``,
  //   requirement: {
  //     lvl: ,
  //     featureId: ,
  //     pcSubLassId: ,
  //     spell: ,
  //   },
  //   source: {
  //     id: SOURCE_UA_2017_02_13_WARLOCK_WIZARD,
  //     page: 3,
  //   },
  // },
]
