import React, { createElement } from 'react'

import PcClassAbility from '@/components/PcClassAbility'
import { TYPE_ITEM } from '@/constants/itemTypes'

import './PcClassAbilityListStyles.less'

export default ({ list, ...rest }) => (
  <section className='PcClassAbilityList'>
    {
      list.map(
        ({ item, type, index }, i) => type === TYPE_ITEM
          ? (
            <PcClassAbility
              ability={item}
              key={i}
              {...rest}
            />
          )
          : createElement(item, {key: i})
      )
    }
  </section>
)
