const {SOURCE_WGtE} = require('./../../sourceList')

const Clawfoot_Raptor_WGtE_img = require('./../../../images/creatures/wgte/clawfoot_raptor.png')
const Double_Bladed_Scimitar_WGtE_img = require('./../../../images/gears/erftlw/double_bladed_scimitar.png')
const Warforged_Wizard_WGtE_img = require('./../../../images/races/warforged_wizard_WGtE.png')
const Pteranodon_Halfling_WGtE_img = require('./../../../images/creatures/mm/pteranodon_halfling.png')
const Armblade_Warforged_WGtE_img = require('./../../../images/magicItems/erftlw/armblade_warforged.png')

module.exports = {
  Clawfoot_Raptor_WGtE_data: {
    src: Clawfoot_Raptor_WGtE_img,
    text: 'Талентский полурослик верхом на когтеногом рапторе',
    source: {
      id: SOURCE_WGtE,
      page: 81,
    },
  },
  Warforged_Wizard_WGtE_data: {
    src: Warforged_Wizard_WGtE_img,
    text: 'Кованый-волшебник',
    source: {
      id: SOURCE_WGtE,
      page: 70,
    },
  },
  Double_Bladed_Scimitar_WGtE_data: {
    src: Double_Bladed_Scimitar_WGtE_img,
    text: 'Эльф с двуклинковым скимитаром',
    source: {
      id: SOURCE_WGtE,
      page: 74,
    },
  },
  Pteranodon_Halfling_WGtE_data: {
    src: Pteranodon_Halfling_WGtE_img,
    text: 'Талентский полурослик верхом на верном птеранодоне',
    source: {
      id: SOURCE_WGtE,
      page: 80,
    },
  },
  Armblade_Warforged_WGtE_data: {
    src: Armblade_Warforged_WGtE_img,
    text: 'Кованый с рукой-клинком',
    source: {
      id: SOURCE_WGtE,
      page: 120,
    },
  },
}
