const {SOURCE_ERFTLW} = require('./../../sourceList')

const Shifter_Artillerist_ERFTLW_img = require('./../../../images/races/shifter-artillerist.png')
const Dwarf_with_Tentacle_Whip_ERFTLW_img = require('./../../../images/magicItems/erftlw/dwarf_with_tentacle_whip.png')

module.exports = {
  Shifter_Artillerist_ERFTLW_data: {
    src: Shifter_Artillerist_ERFTLW_img,
    text: 'Оборотень, изобретатель-артиллерист',
    source: {
      id: SOURCE_ERFTLW,
    },
  },
  Dwarf_with_Tentacle_Whip_ERFTLW_data: {
    src: Dwarf_with_Tentacle_Whip_ERFTLW_img,
    text: 'Дварф со щупальцевым кнутом',
    source: {
      id: SOURCE_ERFTLW,
    },
  },
}
