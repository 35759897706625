import React from 'react'

import enrichWithAds from '@/utils/enrichWithAds'
import AdsAtAbilities from '@/ads/components/AdsAtAbilities'

import PcClassAbilityListComponent from './PcClassAbilityListComponent'

const PcClassAbilityListContainer = ({ abilityList, ...rest }) => {
  if (abilityList && abilityList.length) {
    const listWithAds = enrichWithAds(abilityList, AdsAtAbilities)

    return (
      <PcClassAbilityListComponent
        list={listWithAds}
        {...rest}
      />
    )
  }

  return null
}

export default PcClassAbilityListContainer
