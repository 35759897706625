const {SOURCE_VRGtR} = require('./../../sourceList')

const Dhampir_rogue_img = require('./../../../images/races/dhampir.png')

module.exports = {
  Dhampir_rogue_VRGtR_data: {
    src: Dhampir_rogue_img,
    text: 'Дампир-плут',
    source: {
      id: SOURCE_VRGtR,
    },
  },
}
