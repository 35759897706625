const {
  SOURCE_DND3_5_DTBoD,
} = require('./../../sourceList')

const Blue_Dragon_Lightning_DND3_5_DTBoD_img = require('./../../../images/creatures/dnd3_5_dtbod/blue-dragon-lightning.jpg')

module.exports = {
  Blue_Dragon_Lightning_DND3_5_DTBoD_data: {
    src: Blue_Dragon_Lightning_DND3_5_DTBoD_img,
    text: 'Синий дракон дыханием сбивает призраков на лету',
    source: {
      id: SOURCE_DND3_5_DTBoD,
    },
  },
}
