const {
  PC_SUBCLASS_MONK_WAY_OF_MERCY,
  PC_SUBCLASS_MONK_WAY_OF_THE_ASTRAL_SELF,
  PC_SUBCLASS_MONK_WAY_OF_THE_FOUR_ELEMENTS,
} = require('./../../pcSubClassIdList')

const {
  Dragonborn_Monk_astral_self_TCoE_data,
  Orc_Way_of_the_four_elements_TCoE_data,
  Wood_elf_Way_of_mercy_TCoE_data,
} = require('./../commonImageCollection')

module.exports = {
  [PC_SUBCLASS_MONK_WAY_OF_THE_ASTRAL_SELF]: [
    Dragonborn_Monk_astral_self_TCoE_data,
  ],
  [PC_SUBCLASS_MONK_WAY_OF_THE_FOUR_ELEMENTS]: [
    Orc_Way_of_the_four_elements_TCoE_data,
  ],
  [PC_SUBCLASS_MONK_WAY_OF_MERCY]: [
    Wood_elf_Way_of_mercy_TCoE_data,
  ],
}
