import React, { createElement } from 'react'
import PropTypes from 'prop-types'

import AdsAtNotes from '@/ads/components/AdsAtNotes'
import { TYPE_ADS } from '@/constants/itemTypes'
import enrichWithAds from '@/utils/enrichWithAds'

import NoteComponent from './NoteComponent'

const NoteContainer = ({ note }) => {
  if (note) {
    const noteListWithAds = enrichWithAds(note, AdsAtNotes)

    return (
      <>
        {
          noteListWithAds.map(
            ({ item, type }, i) => {
              if (type === TYPE_ADS) {
                return createElement(item, {key: i})
              }

              const {author, text } = typeof item === 'object'
                ? item
                : { author: null, text: item }

              return (
                <NoteComponent
                  author={author}
                  text={text}
                  key={i}
                />
              )
            },
          )
        }
      </>
    )
  }

  return null
}

NoteContainer.defaultProps = {
  note: null,
}

NoteContainer.propTypes = {
  note: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string,
      author: PropTypes.string,
    }),
  ]),
}

export default NoteContainer
