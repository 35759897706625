const listToCollectionById = require('./../utils/listToCollectionById')
const sortByName = require('./../utils/sortByName')
const {SOURCE_TCoE} = require('./sourceList')

const RUNE_CLOUD = 'cloud'
const RUNE_FIRE = 'fire'
const RUNE_FROST = 'frost'
const RUNE_HILL = 'hill'
const RUNE_STONE = 'stone'
const RUNE_STORM = 'storm'

const runeList = [
  {
    id: RUNE_CLOUD,
    name: `Облачная руна`,
    nameEn: `Cloud Rune`,
    description: `Эта руна имитирует магию обмана, используемую некоторыми облачными великанами. Неся или нося предмет с этой руной, Вы совершаете с преимуществом проверки Ловкости (Ловкость рук) и Харизмы (Обман).

Кроме того, когда по Вам или по видимому Вами в пределах 30 футов существу попадают атакой, Вы можете реакцией активировать эту руну и выбрать другое существо в пределах 30 футов от Вас, кроме атакующего. Выбранное существо становится новой целью атаки, и атака использует тот же бросок. Эта магия может передавать эффекты атаки независимо от дальности атаки.

Активировав эту руну, Вы не сможете сделать это снова, пока не закончите короткий или длинный отдых.`,
    source: {
      id: SOURCE_TCoE,
      page: 64,
    },
  },
  {
    id: RUNE_FIRE,
    name: `Огненная руна`,
    nameEn: `Fire Rune`,
    description: `Магия этой руны проводит мастерство великих кузнецов. Неся или нося предмет с этой руной, Вы удваиваете бонус мастерства при любых проверках характеристик, которые используют инструменты.

Кроме того, когда Вы попадаете по существу атакой оружием, Вы можете активировать эту руну, чтобы призвать огненные оковы: цель получает дополнительный урон огнем 2к6 и должна пройти испытание Силы, иначе станет обездвиженной на 1 минуту. Обездвиженная оковами цель получает 2к6 урона огнём в начале каждого своего хода. Цель может повторять испытание в конце каждого своего хода, изгоняя оковы при успехе.

Активировав эту руну, Вы не сможете сделать это снова, пока не закончите короткий или длинный отдых.`,
    source: {
      id: SOURCE_TCoE,
      page: 64,
    },
  },
  {
    id: RUNE_FROST,
    name: `Ледяная руна`,
    nameEn: `Frost Rune`,
    description: `Магия этой руны пробуждает мощь тех, кто выживает в холодной пустыне, например, ледяных великанов.

Неся или нося предмет с этой руной, Вы совершаете с преимуществом проверки Мудрости (Обращение с животными) и Харизмы (Запугивание).

Кроме того, Вы можете активировать руну бонусным действием, повыщая свою стойкость. На 10 минут Вы получаете бонус +2 ко всем проверкам характеристик и испытаниям Силы и Телосложения.

Активировав эту руну, Вы не сможете сделать это снова, пока не закончите короткий или длинный отдых.`,
    source: {
      id: SOURCE_TCoE,
      page: 64,
    },
  },
  {
    id: RUNE_STONE,
    name: `Каменная руна`,
    nameEn: `Stone Rune`,
    description: `Магия этой руны направляет рассудительность, присущую каменным великанам.

Неся или нося предмет с этой руной, Вы совершаете с преимуществом проверки Мудрости (Проницательность) и получаете тёмное зрение в пределах 120 футов.

Кроме того, когда видимое Вами существо заканчивает ход в пределах 30 футов, Вы можете реакцией, активировать руну и заставить существо пройти испытание Мудрости. При провале существо очаровано Вами на 1 минуту. Очарованное таким образом существо недееспособно и его скорость равна 0, поскольку оно погружается в мечтательный ступор. Существо повторяет испытание в конце каждого своего хода, оканчивая эффект при успехе. После того, как Вы призовете эту руну, Вы не сможете сделать это снова, пока не закончите короткий или долгий отдых.

Активировав эту руну, Вы не сможете сделать это снова, пока не закончите короткий или длинный отдых.`,
    source: {
      id: SOURCE_TCoE,
      page: 64,
    },
  },
  {
    id: RUNE_HILL,
    name: `Холмовая руна`,
    nameEn: `Hill Rune`,
    description: `Магия этой руны дарует стойкость холмового великана.

Неся или нося предмет с этой руной, Вы совершаете с преимуществом испытания от отравления и получаете сопротивление к урону ядом.

Кроме того, Вы можете активировать эту руну бонусным действием, получив сопротивление к дробящему, колющему и рубящему урону на 1 минуту.

Активировав эту руну, Вы не сможете сделать это снова, пока не закончите короткий или длинный отдых.`,
    lvl: 7,
    source: {
      id: SOURCE_TCoE,
      page: 64,
    },
  },
  {
    id: RUNE_STORM,
    name: `Штормовая руна`,
    nameEn: `Storm Rune`,
    description: `Используя эту руну, Вы можете заглядывать в будущее, как провидец штормовых великанов.

Неся или нося предмет с этой руной, Вы совершаете с преимуществом проверки Интеллекта (Магия), и Вас нельзя застать врасплох, пока Вы дееспособны.

Кроме того, Вы можете активировать эту руну бонусным действием, входя в пророческое состояние на 1 минуту или до тех пор, пока не станете недееспособным. Пока состояние не закончится, когда Вы или другое видимое Вами в пределах 40 футов существо, совершает бросок атаки, испытание или проверку характеристики, Вы можете реакцией дать преимущество или помеху к этому броску.

Активировав эту руну, Вы не сможете сделать это снова, пока не закончите короткий или длинный отдых.`,
    lvl: 7,
    source: {
      id: SOURCE_TCoE,
      page: 64,
    },
  },
]
  .sort(sortByName)

module.exports = runeList

module.exports.runeCollection = listToCollectionById(runeList)

module.exports.RUNE_CLOUD = RUNE_CLOUD
module.exports.RUNE_FIRE = RUNE_FIRE
module.exports.RUNE_FROST = RUNE_FROST
module.exports.RUNE_HILL = RUNE_HILL
module.exports.RUNE_STONE = RUNE_STONE
module.exports.RUNE_STORM = RUNE_STORM
