const {SOURCE_AYAGMnC} = require('./../../sourceList')

const Mind_flayer_img_AYAGMnC_img = require('./../../../images/creatures/ayagmc/mind_flayer.jpg')

module.exports = {
  MindFlayer_ElderBrain_AYAGMnC_data: {
    src: Mind_flayer_img_AYAGMnC_img,
    text: 'Старший мозг и свежеватель разума',
    source: {
      id: SOURCE_AYAGMnC,
    },
  },
}
