import React from 'react'
import classNames from 'classnames'

import PublicationInfo from '@/components/PublicationInfo'
import SourceInfo from '@/components/SourceInfo'

import './AbandonedAtStyles.less'

const AbandonedAtComponent = ({ className = '', preText, abandonedAt }) => abandonedAt
  ? (
    <PublicationInfo className={classNames('AbandonedAt', className)}>
      {preText} в <SourceInfo source={abandonedAt} themeSameSize/>
    </PublicationInfo>
  )
  : null

export default AbandonedAtComponent
