import React from "react"

import generateInvocationPageUrlById from "@/utils/generateInvocationPageUrlById"

import ListRenderer from './ListRenderer'

const AllInvocationListRenderer = props => (
  <ListRenderer
    {...props}
    pageUrlGenerator={generateInvocationPageUrlById}
  />
)

export default AllInvocationListRenderer
