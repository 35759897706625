const {
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
  SOURCE_GGTR,
  SOURCE_MM,
} = require("./../../sourceList");
const {
  CREATURE_CENTAUR
} = require("./../../creatureIdList");

const Centaur_Male_MM_1_img = require("./../../../images/creatures/mm/centaur.jpg")
const Centaur_Female_GGTR_1_img = require("./../../../images/races/centaur-1.jpg")
const Ravnica_Street_Trio_img = require('./../../../images/races/ravnica-street-trio.jpg')
const Loxodon_Selesnya_GGTR_img = require('./../../../images/races/loxodon_selesnya.webp')
const Selesnya_GGTR_img = require('./../../../images/races/selesnya.jpg')

const Centaur_Male_AYAGMnC_img = require('./../../../images/creatures/ayagmc/centaur.jpg')
const Centaur_Male_ADND2_img = require('./../../../images/creatures/adnd2_mm/Centaur.jpg')

module.exports = {
  Centaur_Male_MM_data: {
    src: Centaur_Male_MM_1_img,
    creatureId: CREATURE_CENTAUR,
    source: {
      id: SOURCE_MM,
    },
  },
  Centaur_Female_GGTR_data: {
    src: Centaur_Female_GGTR_1_img,
    text: 'Кентаврида клана Груулов',
    source: {
      id: SOURCE_GGTR,
    },
  },
  Centaur_Male_ADND2_data: {
    src: Centaur_Male_ADND2_img,
    creatureId: CREATURE_CENTAUR,
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  Centaur_Male_AYAGMnC_data: {
    src: Centaur_Male_AYAGMnC_img,
    creatureId: CREATURE_CENTAUR,
    source: {
      id: SOURCE_AYAGMnC,
    },
  },
  Loxodon_Selesnya_GGTR_data: {
    src: Loxodon_Selesnya_GGTR_img,
    text: 'Почтенный Локсодон Селезнии',
    source: {
      id: SOURCE_GGTR,
    },
  },
  Ravnica_Street_Trio_GGTR_data: {
    src: Ravnica_Street_Trio_img,
    text: 'Димиры наблюдают за женщиной из легиона Боросов, гибридом Симиков и локсодоном конклава Селезнии',
    source: {
      id: SOURCE_GGTR,
    },
  },
  Selesnya_GGTR_data: {
    src: Selesnya_GGTR_img,
    text: 'Члены конклава Селезнии — локсодон, человек, дриада, кентавр и волк',
    source: {
      id: SOURCE_GGTR,
    },
  },
};
