module.exports.INVOCATION_AGONIZING_BLAST = 'agonizing_blast'
module.exports.INVOCATION_ARMOR_OF_SHADOWS = 'armor_of_shadows'
module.exports.INVOCATION_ASCENDANT_STEP = 'ascendant_step'
module.exports.INVOCATION_ASPECT_OF_THE_MOON = 'aspect_of_the_moon'
module.exports.INVOCATION_BEAST_SPEECH = 'beast_speech'
module.exports.INVOCATION_BEGUILING_INFLUENCE = 'beguiling_influence'
module.exports.INVOCATION_BEWITCHING_WHISPERS = 'bewitching_whispers'
module.exports.INVOCATION_BOND_OF_THE_TALISMAN = 'bond_of_the_talisman'
module.exports.INVOCATION_BOOK_OF_ANCIENT_SECRETS = 'book_of_ancient_secrets'
module.exports.INVOCATION_BURNING_HEX_2017_02_13 = 'burning_hex_2017_02_13'
module.exports.INVOCATION_CAIPHON_S_BEACON_2017_02_13 = 'caiphon_s_beacon_2017_02_13'
module.exports.INVOCATION_CHAIN_MASTERS_FURY_2019_04_11 = 'chain_masters_fury_2019_04_11'
module.exports.INVOCATION_CHAINS_OF_CARCERI = 'chains_of_carceri'
module.exports.INVOCATION_CHILLING_HEX_2017_02_13 = 'chilling_hex_2017_02_13'
module.exports.INVOCATION_CHRONICLE_OF_THE_RAVEN_QUEEN_2017_02_13 = 'chronicle_of_the_raven_queen_2017_02_13'
module.exports.INVOCATION_CLAW_OF_ACAMAR_2017_02_13 = 'claw_of_acamar_2017_02_13'
module.exports.INVOCATION_CLOAK_OF_BAALZEBUL_2017_02_13 = 'cloak_of_baalzebul_2017_02_13'
module.exports.INVOCATION_CLOAK_OF_FLIES = 'cloak_of_flies'
module.exports.INVOCATION_CURSE_BRINGER_2017_02_13 = 'curse_bringer_2017_02_13'
module.exports.INVOCATION_DEVILS_SIGHT = 'devils_sight'
module.exports.INVOCATION_DREADFUL_WORD = 'dreadful_word'
module.exports.INVOCATION_ELDRITCH_ARMOR_2019_04_11 = 'eldritch_armor_2019_04_11'
module.exports.INVOCATION_ELDRITCH_MIND = 'eldritch_mind'
module.exports.INVOCATION_ELDRITCH_SIGHT = 'eldritch_sight'
module.exports.INVOCATION_ELDRITCH_SMITE = 'eldritch_smite'
module.exports.INVOCATION_ELDRITCH_SMITE_2017_06_05 = 'eldritch_smite_2017_06_05'
module.exports.INVOCATION_ELDRITCH_SPEAR = 'eldritch_spear'
module.exports.INVOCATION_EYES_OF_THE_RUNE_KEEPER = 'eyes_of_the_rune_keeper'
module.exports.INVOCATION_FAR_SCRIBE = 'far_scribe'
module.exports.INVOCATION_FIENDISH_VIGOR = 'fiendish_vigor'
module.exports.INVOCATION_FROST_LANCE_2017_02_13 = 'frost_lance_2017_02_13'
module.exports.INVOCATION_FROST_LANCE_2017_06_05 = 'frost_lance_2017_06_05'
module.exports.INVOCATION_GAZE_OF_KHIRAD_2017_02_13 = 'gaze_of_khirad_2017_02_13'
module.exports.INVOCATION_GAZE_OF_TWO_MINDS = 'gaze_of_two_minds'
module.exports.INVOCATION_GHOSTLY_GAZE = 'ghostly_gaze'
module.exports.INVOCATION_GHOSTLY_GAZE_2017_06_05 = 'ghostly_gaze_2017_06_05'
module.exports.INVOCATION_GIFT_OF_THE_DEPTHS = 'gift_of_the_depths'
module.exports.INVOCATION_GIFT_OF_THE_DEPTHS_2017_06_05 = 'gift_of_the_depths_2017_06_05'
module.exports.INVOCATION_GIFT_OF_THE_EVER_LIVING_ONES = 'gift_of_the_ever_living_ones'
module.exports.INVOCATION_GIFT_OF_THE_EVER_LIVING_ONES_2017_06_05 = 'gift_of_the_ever_living_ones_2017_06_05'
module.exports.INVOCATION_GIFT_OF_THE_PROTECTORS = 'gift_of_the_protectors'
module.exports.INVOCATION_GRASP_OF_HADAR = 'grasp_of_hadar'
module.exports.INVOCATION_GREEN_LORD_S_GIFT_2017_02_13 = 'green_lord_s_gift_2017_02_13'
module.exports.INVOCATION_IMPROVED_PACT_WEAPON = 'improved_pact_weapon'
module.exports.INVOCATION_IMPROVED_PACT_WEAPON_2017_02_13 = 'improved_pact_weapon_2017_02_13'
module.exports.INVOCATION_IMPROVED_PACT_WEAPON_2017_06_05 = 'improved_pact_weapon_2017_06_05'
module.exports.INVOCATION_INVESTMENT_OF_THE_CHAIN_MASTER = 'investment_of_the_chain_master'
module.exports.INVOCATION_KISS_OF_MEPHISTOPHELES_2017_02_13 = 'kiss_of_mephistopheles_2017_02_13'
module.exports.INVOCATION_KISS_OF_MEPHISTOPHELES_2017_06_05 = 'kiss_of_mephistopheles_2017_06_05'
module.exports.INVOCATION_LANCE_OF_LETHARGY = 'lance_of_lethargy'
module.exports.INVOCATION_LIFEDRINKER = 'lifedrinker'
module.exports.INVOCATION_MACE_OF_DISPATER_2017_02_13 = 'mace_of_dispater_2017_02_13'
module.exports.INVOCATION_MADDENING_HEX = 'maddening_hex'
module.exports.INVOCATION_MADDENING_HEX_2017_06_05 = 'maddening_hex_2017_06_05'
module.exports.INVOCATION_MASK_OF_MANY_FACES = 'mask_of_many_faces'
module.exports.INVOCATION_MASTER_OF_MYRIAD_FORMS = 'master_of_myriad_forms'
module.exports.INVOCATION_MINIONS_OF_CHAOS = 'minions_of_chaos'
module.exports.INVOCATION_MIRE_THE_MIND = 'mire_the_mind'
module.exports.INVOCATION_MISTY_VISIONS = 'misty_visions'
module.exports.INVOCATION_MOON_BOW_2017_02_13 = 'moon_bow_2017_02_13'
module.exports.INVOCATION_ONE_WITH_SHADOWS = 'one_with_shadows'
module.exports.INVOCATION_OTHERWORLDLY_LEAP = 'otherworldly_leap'
module.exports.INVOCATION_PATH_OF_THE_SEEKER_2017_02_13 = 'path_of_the_seeker_2017_02_13'
module.exports.INVOCATION_PROTECTION_OF_THE_TALISMAN = 'protection_of_the_talisman'
module.exports.INVOCATION_RAVEN_QUEEN_S_BLESSING_2017_02_13 = 'raven_queen_s_blessing_2017_02_13'
module.exports.INVOCATION_REBUKE_OF_THE_TALISMAN_2019_04_11 = 'rebuke_of_the_talisman_2019_04_11'
module.exports.INVOCATION_RELENTLESS_HEX = 'relentless_hex'
module.exports.INVOCATION_RELENTLESS_HEX_2017_02_13 = 'relentless_hex_2017_02_13'
module.exports.INVOCATION_RELENTLESS_HEX_2017_06_05 = 'relentless_hex_2017_06_05'
module.exports.INVOCATION_REPELLING_BLAST = 'repelling_blast'
module.exports.INVOCATION_SCULPTOR_OF_FLESH = 'sculptor_of_flesh'
module.exports.INVOCATION_SEA_TWINS_GIFT_2017_02_13 = 'sea_twins_gift_2017_02_13'
module.exports.INVOCATION_SEEKER_S_SPEECH_2017_02_13 = 'seeker_s_speech_2017_02_13'
module.exports.INVOCATION_SHROUD_OF_SHADOW = 'shroud_of_shadow'
module.exports.INVOCATION_SHROUD_OF_ULBAN_2017_02_13 = 'shroud_of_ulban_2017_02_13'
module.exports.INVOCATION_SIGN_OF_ILL_OMEN = 'sign_of_ill_omen'
module.exports.INVOCATION_SUPERIOR_PACT_WEAPON_2017_02_13 = 'superior_pact_weapon_2017_02_13'
module.exports.INVOCATION_THIEF_OF_FIVE_FATES = 'thief_of_five_fates'
module.exports.INVOCATION_THIRSTING_BLADE = 'thirsting_blade'
module.exports.INVOCATION_TOMB_OF_LEVISTUS = 'tomb_of_levistus'
module.exports.INVOCATION_TOMB_OF_LEVISTUS_2017_02_13 = 'tomb_of_levistus_2017_02_13'
module.exports.INVOCATION_TOMB_OF_LEVISTUS_2017_06_05 = 'tomb_of_levistus_2017_06_05'
module.exports.INVOCATION_TRICKSTERS_ESCAPE = 'tricksters_escape'
module.exports.INVOCATION_ULTIMATE_PACT_WEAPON_2017_02_13 = 'ultimate_pact_weapon_2017_02_13'
module.exports.INVOCATION_UNDYING_SERVITUDE = 'undying_servitude'
module.exports.INVOCATION_VISIONS_OF_DISTANT_REALMS = 'visions_of_distant_realms'
module.exports.INVOCATION_VOICE_OF_THE_CHAIN_MASTER = 'voice_of_the_chain_master'
module.exports.INVOCATION_WHISPERS_OF_THE_GRAVE = 'whispers_of_the_grave'
module.exports.INVOCATION_WITCH_SIGHT = 'witch_sight'
