const {SOURCE_AYAGWnW} = require('./../../sourceList')

const Barbarian_Wulfgar_AYAGWW_img = require('./../../../images/races/barbarian-wulfgar-AYAGWW.png')
const Dragonborn_Flame_Tongue_AYAGWW_img = require('./../../../images/races/dragonborn-flame-tongue-AYAGWW.png')
const Dragonborn_Paladin_Redclay_AYAGWW_img = require('./../../../images/races/dragonborn-paladin-redclay-AYAGWW.png')
const Fighter_AYAGWW_img = require('./../../../images/races/fighter-AYAGWW.png')
const Fighter_Dwarf_Bruenor_AYAGWW_img = require('./../../../images/races/fighter-dwarf-Bruenor-AYAGWW.png')
const Halfling_Lightfoot_Female_Shandie_AYAGWW_img = require('./../../../images/races/halfling-lightfoot-female-shandie-AYAGWW.png')
const Tortle_AYAGWW_img = require('./../../../images/races/tortle-AYAGWW.png')
const Kenku_Monk_Whey_Shu_AYAGWW_img = require('./../../../images/races/kenku-monk-whey-shu-AYAGWW.png')
const Tiefling_Sword_AYAGWW_img = require('./../../../images/races/tiefling-sword-AYAGWW.png')
const Tiefling_Scimitar_AYAGWW_img = require('./../../../images/races/tiefling-AYAGWW.png')
const Tabaxi_AYAGWW_img = require('./../../../images/races/tabaxi-AYAGWW.png')
const Kenku_Rogue_AYAGWW_img = require('./../../../images/races/kenku-AYAGWW.jpg')
const Kenku_Fights_Goblins_AYAGWW_img = require('./../../../images/races/kenku-fights-goblins-ayagww.jpg')
const Ranger_Rashemi_Minsk_AYAGWW_img = require('./../../../images/races/ranger-rashemi-minsk-AYAGWW.png')
const Halfling_Stout_Monk_AYAGWW_img = require('./../../../images/races/halfling-monk-AYAGWW.png')

const Potion_of_healing_AYAGWW_img = require('./../../../images/gears/ayagww/potion_of_healing.jpg')

module.exports = {
  Barbarian_Wulfgar_AYAGWW_data: {
    src: Barbarian_Wulfgar_AYAGWW_img,
    text: 'Варвар Вульфгар, с боевым молотом',
    source: {
      id: SOURCE_AYAGWnW,
    },
  },
  Dragonborn_Flame_Tongue_AYAGWW_data: {
    src: Dragonborn_Flame_Tongue_AYAGWW_img,
    text: 'Драконорождённый, с Языком пламени в руке',
    source: {
      id: SOURCE_AYAGWnW,
    },
  },
  Dragonborn_Paladin_Redclay_AYAGWW_data: {
    src: Dragonborn_Paladin_Redclay_AYAGWW_img,
    text: 'Драконорождённая Красная Глина, паладин',
    source: {
      id: SOURCE_AYAGWnW,
    },
  },
  Fighter_AYAGWW_data: {
    src: Fighter_AYAGWW_img,
    text: 'Человек-воин',
    source: {
      id: SOURCE_AYAGWnW,
    },
  },
  Fighter_Dwarf_Bruenor_AYAGWW_data: {
    src: Fighter_Dwarf_Bruenor_AYAGWW_img,
    text: 'Щитовой дварф, Бруенор Боевой Молот, воин',
    source: {
      id: SOURCE_AYAGWnW,
    },
  },
  Halfling_Lightfoot_Female_Shandie_data: {
    src: Halfling_Lightfoot_Female_Shandie_AYAGWW_img,
    text: 'Легконогая полурослица Шанди Вольноножка, плут',
    source: {
      id: SOURCE_AYAGWnW,
    },
  },
  Tortle_AYAGWW_data: {
    src: Tortle_AYAGWW_img,
    text: 'Тортл играет на лютне',
    source: {
      id: SOURCE_AYAGWnW,
    },
  },
  Kenku_Monk_Whey_Shu_AYAGWW_data: {
    src: Kenku_Monk_Whey_Shu_AYAGWW_img,
    text: 'Кенку-монах Вэй Шу',
    source: {
      id: SOURCE_AYAGWnW,
    },
  },
  Tiefling_Sword_AYAGWW_data: {
    src: Tiefling_Sword_AYAGWW_img,
    text: 'Тифлингша в полулатах',
    source: {
      id: SOURCE_AYAGWnW,
    },
  },
  Tiefling_Scimitar_AYAGWW_data: {
    src: Tiefling_Scimitar_AYAGWW_img,
    text: 'Тифлингша со скимитаром',
    source: {
      id: SOURCE_AYAGWnW,
    },
  },
  Tabaxi_AYAGWW_data: {
    src: Tabaxi_AYAGWW_img,
    text: 'Плутовка-табакси на крыши',
    source: {
      id: SOURCE_AYAGWnW,
    },
  },
  Kenku_Rogue_AYAGWW_data: {
    src: Kenku_Rogue_AYAGWW_img,
    text: 'Кенку-плут',
    source: {
      id: SOURCE_AYAGWnW,
    },
  },
  Kenku_Fights_Goblins_AYAGWW_data: {
    src: Kenku_Fights_Goblins_AYAGWW_img,
    text: 'Кенку защищается боевым посохом от гоблинов',
    source: {
      id: SOURCE_AYAGWnW,
    },
  },
  Ranger_Rashemi_Minsk_AYAGWW_data: {
    src: Ranger_Rashemi_Minsk_AYAGWW_img,
    text: 'Следопыт Минск Могучий, рашеми, одетый в чешуйчатый доспех, с длинным мечом на боку',
    source: {
      id: SOURCE_AYAGWnW,
    },
  },
  Halfling_Stout_Monk_AYAGWW_data: {
    src: Halfling_Stout_Monk_AYAGWW_img,
    text: 'Коренастый полурослик, монах',
    source: {
      id: SOURCE_AYAGWnW,
    },
  },
  Potion_of_healing_AYAGWW_data: {
    src: Potion_of_healing_AYAGWW_img,
    text: 'Зелье лечения',
    source: {
      id: SOURCE_AYAGWnW,
    },
  },
}
