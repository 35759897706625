import React from 'react'

import AdsPlacer from '@/components/AdsPlacer'

import './AdsAtNotesStyles.less'

// Баннер РСЯ в цитатах
const AdsAtNotes = () => (
  <AdsPlacer
    blockId='R-A-2421058-6'
    className='AdsAtNotes'
    incrementIndex
  />
)

export default AdsAtNotes
