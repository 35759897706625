const listToCollectionById = require('./../utils/listToCollectionById')
const sortByName = require('./../utils/sortByName')
const {GEAR_SHIELD} = require('./gearIdList')
const spellIdListByClassAndLvl = require('./spellIdListByClassAndLvl')
const {
  SOURCE_PHB,
  SOURCE_TCoE,
  SOURCE_UA_2019_11_04_CLASS_FEATURE_VARIANTS,
} = require('./sourceList')
const {
  PC_CLASS_CLERIC,
  PC_CLASS_DRUID,
} = require('./pcClassIdList')
const {
  FEATURE_COMBAT_SUPERIORITY,
} = require('./featureIdList')
const {
  FIGHTING_STYLE_ARCHERY,
  FIGHTING_STYLE_BLESSED_WARRIOR,
  FIGHTING_STYLE_BLIND_FIGHTING,
  FIGHTING_STYLE_DEFENSE,
  FIGHTING_STYLE_DRUIDIC_WARRIOR,
  FIGHTING_STYLE_DUELING,
  FIGHTING_STYLE_GREAT_WEAPON_FIGHTING,
  FIGHTING_STYLE_INTERCEPTION,
  FIGHTING_STYLE_PROTECTION,
  FIGHTING_STYLE_SUPERIOR_TECHNIQUE,
  FIGHTING_STYLE_THROWN_WEAPON_FIGHTING,
  FIGHTING_STYLE_TWO_WEAPON_FIGHTING,
  FIGHTING_STYLE_UNARMED_FIGHTING,
} = require('./fightingStyleIdList')

const fightingStyleList = [
  {
    id: FIGHTING_STYLE_DUELING,
    name: 'Дуэлянт',
    nameEn: 'Dueling',
    description: `Пока Вы держите рукопашное оружие в одной руке, и не используете другого оружия, Вы получаете бонус +2 к броскам урона этим оружием.`,
    source: {
      id: SOURCE_PHB,
    },
  },
  {
    id: FIGHTING_STYLE_PROTECTION,
    name: 'Защита',
    nameEn: 'Protection',
    description: `Если существо, которое Вы видите, атакует не Вас, а другое существо, находящееся в пределах 5 футов от Вас, Вы можете реакцией создать помеху его броску атаки. Для этого Вы должны использовать [щит](GEAR:${GEAR_SHIELD}).`,
    source: {
      id: SOURCE_PHB,
    },
  },
  {
    id: FIGHTING_STYLE_DEFENSE,
    name: 'Оборона',
    nameEn: 'Defense',
    description: `Пока Вы носите доспехи, Вы получаете бонус +1 к КД.`,
    source: {
      id: SOURCE_PHB,
    },
  },
  {
    id: FIGHTING_STYLE_GREAT_WEAPON_FIGHTING,
    name: 'Сражение большим оружием',
    nameEn: 'Great Weapon Fighting',
    description: `Если у Вас выпало «1» или «2» на кости урона оружия при атаке, которую Вы совершали рукопашным оружием, удерживая его двумя руками, то Вы можете перебросить эту кость, и должны использовать новый результат, даже если снова выпало «1» или «2».

Чтобы воспользоваться этим преимуществом, Ваше оружие должно иметь свойство «двуручное» или «универсальное».`,
    source: {
      id: SOURCE_PHB,
    },
  },
  {
    id: FIGHTING_STYLE_TWO_WEAPON_FIGHTING,
    name: 'Сражение двумя оружиями',
    nameEn: 'Two-Weapon Fighting',
    description: `Если Вы сражаетесь двумя оружиями, Вы можете добавить модификатор характеристики к урону от второй атаки.`,
    source: {
      id: SOURCE_PHB,
    },
  },
  {
    id: FIGHTING_STYLE_ARCHERY,
    name: 'Стрельба',
    nameEn: 'Archery',
    description: `Вы получаете бонус +2 к броску атаки, когда атакуете дальнобойным оружием.`,
    source: {
      id: SOURCE_PHB,
    },
  },
  {
    id: FIGHTING_STYLE_SUPERIOR_TECHNIQUE,
    name: 'Техника превосходства',
    nameEn: 'Superior Technique',
    description: `Вы изучаете один приём во Вашему выбору из [списка приёмов подкласса Мастера боевых искусств](FEATURE:${FEATURE_COMBAT_SUPERIORITY}).

Если приём требует, чтобы цель прошла испытание для сопротивления эффекту приёма, СЛ испытания равна 8 + Ваш бонус мастерства + модификатор Силы или Ловкости (на Ваш выбор).

Вы получаете одну кость превосходства: к6. Эта кость подпитывает Ваши приёмы. Кость превосходства тратится, когда Вы её используете, и восстанавливается после окончания короткого или длинного отдыха.`,
    source:[
      {
        id: SOURCE_TCoE,
        page: 59,
      },
      {
        id: SOURCE_UA_2019_11_04_CLASS_FEATURE_VARIANTS,
        page: 5,
      },
    ],
  },
  {
    id: FIGHTING_STYLE_BLESSED_WARRIOR,
    name: 'Благословлённый воин',
    nameEn: 'Blessed Warrior',
    description: `Вы узнаёте два заговора во Вашему выбору из списка заклинаний [жреца](PC_CLASS:${PC_CLASS_CLERIC}).

Они считаются для Вас заклинаниями паладина и Харизма — Ваша заклинательная характеристика для них.

Когда Вы получаете уровень в классе паладина, Вы можете заменить один из этих заговоров на другой заговор из списка заклинаний [жреца](PC_CLASS:${PC_CLASS_CLERIC}).`,
    spellIdList: spellIdListByClassAndLvl[PC_CLASS_CLERIC][0],
    source: [
      {
        id: SOURCE_TCoE,
        page: 76,
      },
      {
        id: SOURCE_UA_2019_11_04_CLASS_FEATURE_VARIANTS,
        page: 5,
      },
    ],
  },
  {
    id: FIGHTING_STYLE_DRUIDIC_WARRIOR,
    name: 'Друидический воин',
    nameEn: 'Druidic Warrior',
    description: `Вы узнаёте два заговора по Вашему выбору из списка заклинаний [друида](PC_CLASS:${PC_CLASS_DRUID}).

Они считаются для Вас заклинаниями следопыта и Мудрость — Ваша заклинательная характеристика для них.

Когда Вы получаете уровень в классе следопыта, Вы можете заменить один из этих заговоров на другой заговор из списка заклинаний [друида](PC_CLASS:${PC_CLASS_DRUID}).`,
    spellIdList: spellIdListByClassAndLvl[PC_CLASS_DRUID][0],
    source: [
      {
        id: SOURCE_TCoE,
        page: 84,
      },
      {
        id: SOURCE_UA_2019_11_04_CLASS_FEATURE_VARIANTS,
        page: 7,
      },
    ],
  },
  {
    id: FIGHTING_STYLE_BLIND_FIGHTING,
    name: 'Бой вслепую',
    nameEn: 'Blind Fighting',
    description: `Вы получаете слепое зрение радиусом 10 футов.

В пределах этого радиуса Вы можете видеть всё, не закрытое полным укрытием, даже если Вы ослеплены или находитесь во тьме.

Кроме того, в пределах этого радиуса Вы можете видеть невидимых существ, если только они не смогли успешно скрыться от Вас.`,
    source: {
      id: SOURCE_TCoE,
      page: 59,
    },
  },
  {
    id: FIGHTING_STYLE_INTERCEPTION,
    name: 'Перехват',
    nameEn: 'Interception',
    description: `Когда видимое Вами существо попадает атакой по цели в пределах 5 футов от Вас, Вы можете реакцией уменьшить наносимый цели урон на 1к10 + Ваш бонус мастерства (минимум до 0 урона).

Для совершения этой реакции Вы должны держать щит, простое или воинское оружие.`,
    source: [
      {
        id: SOURCE_UA_2019_11_04_CLASS_FEATURE_VARIANTS,
        page: 12,
      },
      {
        id: SOURCE_TCoE,
        page: 59,
      },
    ],
  },
  {
    id: FIGHTING_STYLE_THROWN_WEAPON_FIGHTING,
    name: 'Сражение метательным оружием',
    nameEn: 'Thrown Weapon Fighting',
    description: `Вы можете доставать оружие со свойством «Метательное» частью совершаемой им атаки.

Кроме того, когда Вы попадаете по цели дальнобойной атакой метательным оружием, Вы получаете +2 к броску урона.`,
    source: {
      id: SOURCE_TCoE,
      page: 59,
    },
  },
  {
    id: FIGHTING_STYLE_UNARMED_FIGHTING,
    name: 'Безоружный бой',
    nameEn: 'Unarmed Fighting',
    description: `Ваши безоружные удары наносят дробящий урон равный 1к6 + Ваш модификатор Силы. Если Вы наносите удар двумя свободными руками (не держа в них ни оружия, ни щита), то кость урона становится к8.

В начале каждого своего хода Вы можете нанести одному захваченному Вами существу 1к4 дробящего урона.`,
    source: {
      id: SOURCE_TCoE,
      page: 59,
    },
  },
]
  .sort(sortByName)

module.exports = fightingStyleList

module.exports.fightingStyleCollection = listToCollectionById(fightingStyleList)
