const {SOURCE_MM} = require('./../../sourceList')
const {
  CREATURE_FAERIE_DRAGON_LIGHT_BLUE,
  CREATURE_GRICK,
  CREATURE_SATYR,
} = require('./../../creatureIdList')

const Aarakocra_MM_img = require('./../../../images/creatures/mm/aarakocra.jpg')
const Drow_Mage_MM_img = require('./../../../images/creatures/mm/drow_mage.jpeg')
const Druid_MM_img = require('./../../../images/creatures/mm/druid.jpg')
const Faerie_Dragon_Light_Blue_MM_img = require('./../../../images/creatures/mm/faerie_dragon_light_blue.jpg')
const Satyr_MM_img = require('./../../../images/creatures/mm/satyr.jpeg')
const Grick_MM_img = require('./../../../images/creatures/mm/grick.jpg')

module.exports = {
  Aarakocra_MM_data: {
    src: Aarakocra_MM_img,
    text: 'Ааракокра',
    source: {
      id: SOURCE_MM,
    },
  },
  Drow_Mage_MM_data: {
    src: Drow_Mage_MM_img,
    text: 'Маг дроу',
    source: {
      id: SOURCE_MM,
    },
  },
  Druid_MM_data: {
    src: Druid_MM_img,
    text: 'Друид',
    source: {
      id: SOURCE_MM,
    },
  },
  Faerie_Dragon_Light_Blue_MM_data: {
    src: Faerie_Dragon_Light_Blue_MM_img,
    creatureId: CREATURE_FAERIE_DRAGON_LIGHT_BLUE,
    source: {
      id: SOURCE_MM,
    },
  },
  Satyr_MM_data: {
    src: Satyr_MM_img,
    creatureId: CREATURE_SATYR,
    source: {
      id: SOURCE_MM,
    },
  },
  Grick_MM_data: {
    creatureId: CREATURE_GRICK,
    src: Grick_MM_img,
    source: {
      id: SOURCE_MM,
    },
  },
}
