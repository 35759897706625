import React from "react"

import invocationList from "@/constants/invocationList"

import sortByName from "@/utils/sortByName"

import ToggleList from '@/components/ToggleList'

import AllInvocationListRenderer from './AllInvocationListRenderer'

const AllInvocationList = () => {
  const list = invocationList.sort(sortByName)

  return (
    <ToggleList
      list={list}
      ListRenderer={AllInvocationListRenderer}
      text='список таинственных воззваний'
    />
  )
}

export default AllInvocationList
