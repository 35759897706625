const {SOURCE_SKT} = require('./../../sourceList')

const Giant_Fights_SKT_img = require('./../../../images/creatures/skt/giant-fights.jpg')
const Orc_Axe_Beak_Attacks_Halfling_SKT_img = require('./../../../images/creatures/skt/orc_axebeak_attacks_halfling.jpg')
const Fire_Giants_Attack_SKT_img = require('./../../../images/creatures/skt/fire-giants-attack.png')
const Aarakocra_Cloud_Castle_SKT_img = require('./../../../images/creatures/skt/aarakocra_cloud_castle.png')
const Storm_Giant_Ladies_Plays_SKT_img = require('./../../../images/creatures/skt/storm_giant_ladies_plays.jpg')
const Dire_Wolf_Attack_Northfolk_Family_SKT_img = require('./../../../images/creatures/skt/dire_wolf_attack_northfolk_family.jpg')

module.exports = {
  Giant_Fights_SKT_data: {
    src: Giant_Fights_SKT_img,
    text: 'Штормовой и огненный великаны сражаются',
    source: {
      id: SOURCE_SKT,
    },
  },
  Orc_Axe_Beak_Attacks_Halfling_SKT_data: {
    src: Orc_Axe_Beak_Attacks_Halfling_SKT_img,
    text: 'Орк на топороклюве гонится за полуросликом',
    source: {
      id: SOURCE_SKT,
    },
  },
  Fire_Giants_Attack_SKT_data: {
    src: Fire_Giants_Attack_SKT_img,
    text: 'Огненный великан и магмины нападают на город',
    source: {
      id: SOURCE_SKT,
    },
  },
  Aarakocra_Cloud_Castle_SKT_data: {
    src: Aarakocra_Cloud_Castle_SKT_img,
    text: 'Ааракокра летит к летающему замку облачных великанов',
    source: {
      id: SOURCE_SKT,
    },
  },
  Storm_Giant_Ladies_Plays_SKT_data: {
    src: Storm_Giant_Ladies_Plays_SKT_img,
    text: 'Штормовые великанши играют на гигантском крабе и поют',
    source: {
      id: SOURCE_SKT,
    },
  },
  Dire_Wolf_Attack_Northfolk_Family_SKT_data: {
    src: Dire_Wolf_Attack_Northfolk_Family_SKT_img,
    text: 'Лютый волк нападает на семью северян',
    source: {
      id: SOURCE_SKT,
    },
  },
}
