import React from 'react'
import classNames from "classnames"
import { Link } from 'gatsby'

import generateSpellPageUrlById from '@/utils/generateSpellPageUrlById'

import MagicSchool from '@/components/MagicSchool'

import './SpellListStyles.less'

const SpellList = ({ list, className = '' }) => (
  <ol className={classNames(
    'SpellList',
    {
      'SpellList-single': list.length === 1,
    },
    className
  )}>
    {
      list.map(
        ({ id, name, nameEn, magicSchoolId }) => (
          <li
            key={id}
            className='SpellList_item'
          >
            <MagicSchool magicSchoolId={magicSchoolId}/>
            <Link
              to={generateSpellPageUrlById(id)}
              title={`${name} [${nameEn}]`}
            >
              {name}
            </Link>
          </li>
        )
      )
    }
  </ol>
)

export default SpellList
