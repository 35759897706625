import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './MagicSchoolStyles.less'

const MagicSchoolComponent = ({ magicSchoolId, name, showLarge, showName, className, ...rest }) => (
  <span
    className={classNames(
      `MagicSchool MagicSchool-${magicSchoolId} ${className}`,
      {
        'MagicSchool-large': showLarge,
        'MagicSchool-small': !showLarge,
      }
    )}
    {...rest}
  >
    {
      showName
        ? name
        : null
    }
  </span>
)

MagicSchoolComponent.propTypes = {
  className: PropTypes.string,
  magicSchoolId: PropTypes.string.isRequired,
  name: PropTypes.string,
  showLarge: PropTypes.bool,
  showName: PropTypes.bool,
}

MagicSchoolComponent.defaultProps = {
  className: '',
  name: '',
  showLarge: false,
  showName: false,
}

export default MagicSchoolComponent
