const {SOURCE_DND3_FnP} = require('./../../sourceList')

const Torm_DND3_FnP_img = require('./../../../images/gods/torm-DND3_FnP.jpg')
const Akadi_Gargauth_Eldath_Finder_Grumbar_DND3_FnP_img = require('./../../../images/gods/akadi-gargauth-eldath-finder_wyvernspur-grumbar-DND3_FnP.webp')
const Istishia_Jergal_Gwaeron_Hoar_Milil_DND3_FnP_img = require('./../../../images/gods/istishia-jergal-gwaeron_windstrom-hoar-milil-DND3_FnP.webp')
const Shiallia_Nobanion_Savras_Red_Knight_Sharess_DND3_FnP_img = require('./../../../images/gods/shiallia-nobanion-savras-red_knight-sharess-DND3_FnP.webp')
const Ulutiu_Ubtao_Siamorphe_Tiamat_Valkur_DND3_FnP_img = require('./../../../images/gods/ulutiu-ubtao-siamorphe-tiamat-valkur-DND3_FnP.jpg')
const Kiaransalee_Vhaeraun_Lolth_Selvetarm_Ghaunadaur_Eilistraee_DND3_FnP_img = require('./../../../images/gods/kiaransalee-vhaeraun-lolth-selvetarm-ghaunadaur-eilistraee-DND3_FnP.jpg')
const Abbathor_Berronar_Clangeddin_Duerra_Dumathoin_Dugmaren_Gorm_DND3_FnP_img = require('./../../../images/gods/abbathor-berronar-clangeddin-duerra-dumathoin-dugmaren_brightmantle-gorm-DND3_FnP.jpg')
const Haela_Moradin_Laduguer_Sharindlar_Marthammer_Thard_Vergadain_DND3_FnP_img = require('./../../../images/gods/haela_brightaxe-moradin-laduguer-sharindlar-marthammer-thard-vergadain-DND3_FnP.jpg')
const Angharradh_Aerdrie_Corellon_Fenmarel_Sashelas_Erevan_DND3_FnP_img = require('./../../../images/gods/angharradh-aerdrie_faenya-corellon_larethian-fenmarel-deep_sashelas-erevan_ilesere-DND3_FnP.jpg')
const Corellon_Takes_the_Eye_of_Gruumsh_DND3_FnP_img = require('./../../../images/gods/corellon-larethian-takes-the-eye-of-gruumsh-DND3_FnP.jpg')
const Hanali_Labelas_Rillifane_Sehanine_Shevarash_Solonor_DND3_FnP_img = require('./../../../images/gods/hanali_celanil-labelas_enoreth-rillifane_rallathil-sehanine_moonbow-shevarash-solonor_thelandira_DND3_FnP.jpg')
const Baervan_Baravar_Callarduran_Flandal_Gaerdal_Garl_Segojan_Urdlen_DND3_FnP_img = require('./../../../images/gods/baervan-baravar-callarduran-flandal-gaerdal-garl-segojan-urdlen_DND3_FnP.jpg')
const Arvoreen_Brandobaris_Cyrrollalee_Sheela_Peryroyl_Urogalan_Yondalla_DND3_FnP_img = require('./../../../images/gods/arvoreen-brandobaris-cyrrollalee-sheela_peryroyl-urogalan-yondalla_DND3_FnP.jpg')
const Bahgtru_Ilneval_Gruumsh_Luthic_Shargaas_Yurtrus_DND3_FnP_img = require('./../../../images/gods/bahgtru-ilneval-gruumsh-luthic-shargaas-yurtrus-DND3_FnP.jpg')
const Uthgardt_Totems_DND3_FnP_img = require('./../../../images/pcSubClasses/barbarian/uthgardt_totems.jpg')

module.exports = {
  Torm_DND3_FnP_data: {
    src: Torm_DND3_FnP_img,
    text: 'Торм, Рука Праведности, верхом на золотом драконе',
    source: {
      id: SOURCE_DND3_FnP,
    },
  },
  Akadi_Gargauth_Eldath_Finder_Grumbar_DND3_FnP_data: {
    src: Akadi_Gargauth_Eldath_Finder_Grumbar_DND3_FnP_img,
    text: 'Слева направо — Акади, Горгот, Эльдат, Файндер Вивернспур, Грумбар',
    source: {
      id: SOURCE_DND3_FnP,
    },
  },
  Istishia_Jergal_Gwaeron_Hoar_Milil_DND3_FnP_data: {
    src: Istishia_Jergal_Gwaeron_Hoar_Milil_DND3_FnP_img,
    text: 'Слева направо — Истишия, Джергал, Гвейрон Буреветер, Хоар, Милил',
    source: {
      id: SOURCE_DND3_FnP,
    },
  },
  Shiallia_Nobanion_Savras_Red_Knight_Sharess_DND3_FnP_data: {
    src: Shiallia_Nobanion_Savras_Red_Knight_Sharess_DND3_FnP_img,
    text: 'Слева направо — Шиаллия, Нобэньон, Саврас, Красный Рыцарь, Шаресс',
    source: {
      id: SOURCE_DND3_FnP,
    },
  },
  Ulutiu_Ubtao_Siamorphe_Tiamat_Valkur_DND3_FnP_data: {
    src: Ulutiu_Ubtao_Siamorphe_Tiamat_Valkur_DND3_FnP_img,
    text: 'Слева направо — Улутиу, Убтао, Сиаморф, Тиамат, Валькур',
    source: {
      id: SOURCE_DND3_FnP,
    },
  },
  Kiaransalee_Vhaeraun_Lolth_Selvetarm_Ghaunadaur_Eilistraee_DND3_FnP_data: {
    src: Kiaransalee_Vhaeraun_Lolth_Selvetarm_Ghaunadaur_Eilistraee_DND3_FnP_img,
    text: 'Слева направо — Киарансали, Ваэрон, Ллос, Селветарм, Гонадор, Эйлистри',
    source: {
      id: SOURCE_DND3_FnP,
      page: 113,
    },
  },
  Abbathor_Berronar_Clangeddin_Duerra_Dumathoin_Dugmaren_Gorm_DND3_FnP_data: {
    src: Abbathor_Berronar_Clangeddin_Duerra_Dumathoin_Dugmaren_Gorm_DND3_FnP_img,
    text: 'Слева направо — Аббатор, Берронар, Клангеддин, Дуэрра, Думатойн, Дагмарен Светлая Мантия, Горм',
    source: {
      id: SOURCE_DND3_FnP,
      page: 115,
    },
  },
  Haela_Moradin_Laduguer_Sharindlar_Marthammer_Thard_Vergadain_DND3_FnP_data: {
    src: Haela_Moradin_Laduguer_Sharindlar_Marthammer_Thard_Vergadain_DND3_FnP_img,
    text: 'Слева направо — Хаэла Светлый Топор, Морадин, Ладугуэр, Шариндлар, Мартхаммер, Тард, Вергадэйн',
    source: {
      id: SOURCE_DND3_FnP,
      page: 122,
    },
  },
  Angharradh_Aerdrie_Corellon_Fenmarel_Sashelas_Erevan_DND3_FnP_data: {
    src: Angharradh_Aerdrie_Corellon_Fenmarel_Sashelas_Erevan_DND3_FnP_img,
    text: 'Слева направо — Ангаррад, Аэрдри Фэйня, Кореллон Ларетиан, Фенмарел, Сашелас Глубинный, Эреван Илисир',
    source: {
      id: SOURCE_DND3_FnP,
      page: 125,
    },
  },
  Corellon_Takes_the_Eye_of_Gruumsh_DND3_FnP_data: {
    src: Corellon_Takes_the_Eye_of_Gruumsh_DND3_FnP_img,
    text: 'Кореллон Ларетиан лишает Груумша глаза',
    source: {
      id: SOURCE_DND3_FnP,
      page: 127,
    },
  },
  Hanali_Labelas_Rillifane_Sehanine_Shevarash_Solonor_DND3_FnP_data: {
    src: Hanali_Labelas_Rillifane_Sehanine_Shevarash_Solonor_DND3_FnP_img,
    text: 'Слева направо — Ханали Селанил, Лабелас Энорет, Риллифэйн Раллатил, Сеанин Лунная Радуга, Шевараш, Солонор Теландир',
    source: {
      id: SOURCE_DND3_FnP,
      page: 131,
    },
  },
  Baervan_Baravar_Callarduran_Flandal_Gaerdal_Garl_Segojan_Urdlen_DND3_FnP_data: {
    src: Baervan_Baravar_Callarduran_Flandal_Gaerdal_Garl_Segojan_Urdlen_DND3_FnP_img,
    text: 'Слева направо — Баэрван Дикий Странник, Баравар Теневой Плащ, Каллардюран Гладкорукий, Фландал Стальнокожий, Гаэрдал Железнорукий, Гарл Златоблеск, Сегожан Зовущий Землю, Урдлен',
    source: {
      id: SOURCE_DND3_FnP,
      page: 133,
    },
  },
  Arvoreen_Brandobaris_Cyrrollalee_Sheela_Peryroyl_Urogalan_Yondalla_DND3_FnP_data: {
    src: Arvoreen_Brandobaris_Cyrrollalee_Sheela_Peryroyl_Urogalan_Yondalla_DND3_FnP_img,
    text: 'Слева направо — Арворин, Брандобарис, Цирроллали, Шила Периройл, Урогалан, Йондалла',
    source: {
      id: SOURCE_DND3_FnP,
      page: 139,
    },
  },
  Bahgtru_Ilneval_Gruumsh_Luthic_Shargaas_Yurtrus_DND3_FnP_data: {
    src: Bahgtru_Ilneval_Gruumsh_Luthic_Shargaas_Yurtrus_DND3_FnP_img,
    text: 'Слева направо — Богтру, Илневал, Груумш, Лутик, Шаргаас, Юртрус',
    source: {
      id: SOURCE_DND3_FnP,
      page: 150,
    },
  },
  Uthgardt_Totems_DND3_FnP_data: {
    src: Uthgardt_Totems_DND3_FnP_img,
    text: 'Тотемы племён Утгарта',
    source: {
      id: SOURCE_DND3_FnP,
    },
  },
}
