import React from 'react'
import * as PropTypes from 'prop-types'

import SpellList from '@/components/SpellList'

import SpellGroupByPcClassLvlList from '@/components/SpellGroupByPcClassLvlList'

const SpellByPcLvlListRenderer = ({spellCircleLvl, list}) => spellCircleLvl
  ? (
    <SpellGroupByPcClassLvlList
      className='SpellByPcLvlList_list'
      spellCircleLvl={spellCircleLvl}
      spellList={list}
    />
  )
  : (
    <SpellList
      list={list}
      className='SpellByPcLvlList_list'
    />
  )

SpellByPcLvlListRenderer.propTypes = {
  spellCircleLvl: PropTypes.array,
  list: PropTypes.array,
}

SpellByPcLvlListRenderer.defaultProps = {
  spellCircleLvl: null,
  list: [],
}

export default SpellByPcLvlListRenderer
