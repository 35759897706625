const {SOURCE_DMG} = require('./../../sourceList')

const Goblins_Band_DMG_img = require('./../../../images/creatures/dmg/goblins_band.jpg')
const Green_Dragon_meets_Bard_DMG_img = require('./../../../images/creatures/dmg/green_dragon_meets_bard.jpg')
const Kenku_DMG_img = require('./../../../images/creatures/dmg/kenku.jpg')
const Samurai_Human_Male_DMG_img = require('./../../../images/pcSubClasses/fighter/samurai.png')
const Gnome_Rock_Wizard_Male_DMG_img = require('./../../../images/races/gnome_rock_wizard_male_DMG.png')
const Doss_lute_DMG_img = require('./../../../images/magicItems/dmg/doss_lute.jpg')

module.exports = {
  Kenku_DMG_data: {
    src: Kenku_DMG_img,
    text: 'Кенку',
    source: {
      id: SOURCE_DMG,
    },
  },
  Samurai_Human_Male_DMG_data: {
    src: Samurai_Human_Male_DMG_img,
    text: 'Мужчина, воин-самурай',
    source: {
      id: SOURCE_DMG,
    },
  },
  Gnome_Rock_Wizard_Male_DMG_data: {
    src: Gnome_Rock_Wizard_Male_DMG_img,
    text: 'Скальный гном, волшебник',
    source: {
      id: SOURCE_DMG,
    },
  },
  Goblins_Band_DMG_data: {
    src: Goblins_Band_DMG_img,
    text: 'Гоблины готовятся напасть на лагерь приключенцев',
    source: {
      id: SOURCE_DMG,
    },
  },
  Green_Dragon_meets_Bard_DMG_data: {
    src: Green_Dragon_meets_Bard_DMG_img,
    text: 'Бард и зелёный дракон',
    source: {
      id: SOURCE_DMG,
    },
  },
  Doss_lute_DMG_data: {
    src: Doss_lute_DMG_img,
    text: 'Лютня Досс',
    source: {
      id: SOURCE_DMG,
    },
  },
}
