import React from "react"

import Markdown from "@/components/Markdown"
import MagicSchool from "@/components/MagicSchool"

import './AllArcaneShotListStyles.less'

const AllInfusionListRenderer = ({list}) => (
  <ol className='AllInfusionListRenderer'>
    {
      list.map(
        ({ name, description, magicSchoolId }) => (
          <li className='AllInfusionListRenderer_row'>
            <header className='AllInfusionListRenderer_header'>
              <MagicSchool
                magicSchoolId={magicSchoolId}
                className='AllInfusionListRenderer_magic'
              />
              {name}
            </header>
            <Markdown className='AllInfusionListRenderer_text'>{description}</Markdown>
          </li>
        )
      )
    }
  </ol>
)

export default AllInfusionListRenderer
