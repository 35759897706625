const {
  SOURCE_MTG_CLB,
} = require('./../../sourceList')
const {
  PC_SUBCLASS_RANGER_FEY_WANDERER,
  PC_SUBCLASS_RANGER_GLOOM_STALKER,
  PC_SUBCLASS_RANGER_SWARMKEEPER,
} = require('./../../pcSubClassIdList')

const {
  Erinis_gloom_stalker_MTG_CLB_data,
  Human_Ranger_Fey_Wanderer_TCoE_data,
  Gnome_Ranger_Swarmkeeper_TCoE_data,
} = require('./../commonImageCollection')

const Swarmkeeper_MTG_CLB_img = require('./../../../images/pcSubClasses/ranger/swarmkeeper_mtg_clb.webp')

module.exports = {
  [PC_SUBCLASS_RANGER_FEY_WANDERER]: [
    Human_Ranger_Fey_Wanderer_TCoE_data,
  ],
  [PC_SUBCLASS_RANGER_SWARMKEEPER]: [
    Gnome_Ranger_Swarmkeeper_TCoE_data,
    {
      src: Swarmkeeper_MTG_CLB_img,
      text: 'Хранитель роя из Скрытолесья',
      source: SOURCE_MTG_CLB,
    },
  ],
  [PC_SUBCLASS_RANGER_GLOOM_STALKER]: [
    Erinis_gloom_stalker_MTG_CLB_data,
  ],
}
