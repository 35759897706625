const {SOURCE_EGTW} = require('./../../sourceList')
const {
  PC_SUBCLASS_FIGHTER_ECHO_KNIGHT,
  PC_SUBCLASS_FIGHTER_PSI_WARRIOR,
  PC_SUBCLASS_FIGHTER_PURPLE_DRAGON_KNIGHT,
  PC_SUBCLASS_FIGHTER_RUNE_KNIGHT,
  PC_SUBCLASS_FIGHTER_SAMURAI,
} = require('./../../pcSubClassIdList')
const {
  Dragonborn_Purple_dragon_knight_TCoE_data,
  High_elf_Psi_warrior_TCoE_data,
  Orc_Rune_knight_TCoE_data,
  Knight_of_the_order_SCAG_data,
  Samurai_Human_Male_DMG_data,
} = require('./../commonImageCollection')

const Echo_Knight_Fighter_EGTW_img = require('./../../../images/pcSubClasses/fighter/EchoKnight.webp')

module.exports = {
  [PC_SUBCLASS_FIGHTER_PSI_WARRIOR]: [
    High_elf_Psi_warrior_TCoE_data,
  ],
  [PC_SUBCLASS_FIGHTER_ECHO_KNIGHT]: {
    src: Echo_Knight_Fighter_EGTW_img,
    text: 'Эхо-рыцарь',
    source: {
      id: SOURCE_EGTW,
    },
  },
  [PC_SUBCLASS_FIGHTER_PURPLE_DRAGON_KNIGHT]: [
    Knight_of_the_order_SCAG_data,
    Dragonborn_Purple_dragon_knight_TCoE_data,
  ],
  [PC_SUBCLASS_FIGHTER_RUNE_KNIGHT]: [
    Orc_Rune_knight_TCoE_data,
  ],
  [PC_SUBCLASS_FIGHTER_SAMURAI]: [
    Samurai_Human_Male_DMG_data,
  ],
}
