module.exports = {
  ...require('./pcSubClassImageCollection_artificer'),
  ...require('./pcSubClassImageCollection_barbarian'),
  ...require('./pcSubClassImageCollection_bard'),
  ...require('./pcSubClassImageCollection_cleric'),
  ...require('./pcSubClassImageCollection_druid'),
  ...require('./pcSubClassImageCollection_fighter'),
  ...require('./pcSubClassImageCollection_monk'),
  ...require('./pcSubClassImageCollection_paladin'),
  ...require('./pcSubClassImageCollection_ranger'),
  ...require('./pcSubClassImageCollection_rogue'),
  ...require('./pcSubClassImageCollection_sorcerer'),
  ...require('./pcSubClassImageCollection_warlock'),
  ...require('./pcSubClassImageCollection_wizard'),
}
