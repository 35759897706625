import React from "react"

import elementalDisciplineList from "@/constants/elementalDisciplineList"

import ToggleList from '@/components/ToggleList'

import sortByName from '@/utils/sortByName'
import AllElementalDisciplineListRenderer from './AllElementalDisciplineListRenderer'

const AllElementalDisciplineList = () => {
  const list = elementalDisciplineList.sort(sortByName)

  return (
    <ToggleList
      list={list}
      ListRenderer={AllElementalDisciplineListRenderer}
      text='стихийные практики'
    />
  )
}

export default AllElementalDisciplineList
