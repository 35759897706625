import React from "react"

import { pcSubClassCollection, pcSubClassIdByClassIdCollection } from '@/constants/pcSubClassList'

import sortByName from "@/utils/sortByName"

import ToggleList from '@/components/ToggleList'

import PcSubClassListRenderer from './PcSubClassListRenderer'

const PcSubClassList = ({pcClassId}) => {
  const pcSubClassIdList = pcSubClassIdByClassIdCollection[pcClassId]
  const list = pcSubClassIdList
    .map(id => ({
      ...pcSubClassCollection[id],
      pcClassIdToRender: pcClassId,
    }))
    .filter(
      ({isReReleased}) => !isReReleased
    )
    .sort(sortByName)

  return (
    <ToggleList
      list={list}
      ListRenderer={PcSubClassListRenderer}
      text='подклассы'
    />
  )
}

export default PcSubClassList
