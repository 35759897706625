const {
  PC_SUBCLASS_BARBARIAN_PATH_OF_THE_BATTLERAGER,
  PC_SUBCLASS_BARBARIAN_PATH_OF_THE_BEAST,
  PC_SUBCLASS_BARBARIAN_PATH_OF_WILD_MAGIC,
  PC_SUBCLASS_BARBARIAN_PATH_OF_THE_TOTEM_WARRIOR,
} = require('./../../pcSubClassIdList')

const {
  Half_Orc_Male_Barbarian_Totem_SCAG_data,
  Human_Path_of_the_beast_TCoE_data,
  Wood_elf_Path_of_wild_magic_TCoE_data,
  Uthgardt_Totems_DND3_FnP_data,
  Battlerager_MTG_CLB_data,
} = require('./../commonImageCollection')

module.exports = {
  [PC_SUBCLASS_BARBARIAN_PATH_OF_THE_BATTLERAGER]: [
    Battlerager_MTG_CLB_data,
  ],
  [PC_SUBCLASS_BARBARIAN_PATH_OF_THE_BEAST]: [
    Human_Path_of_the_beast_TCoE_data,
  ],
  [PC_SUBCLASS_BARBARIAN_PATH_OF_WILD_MAGIC]: [
    Wood_elf_Path_of_wild_magic_TCoE_data,
  ],
  [PC_SUBCLASS_BARBARIAN_PATH_OF_THE_TOTEM_WARRIOR]: [
    Half_Orc_Male_Barbarian_Totem_SCAG_data,
    Uthgardt_Totems_DND3_FnP_data,
  ],
}
