const {SOURCE_MTG_AHBG} = require('./../../sourceList')

const Sune_s_intervention_MTG_AHBG_img = require('./../../../images/gods/sunes-intervention_mtg_ahbg.webp')

module.exports = {
  Sune_s_intervention_MTG_AHBG_data: {
    src: Sune_s_intervention_MTG_AHBG_img,
    text: 'Вмешательство богини Сьюни',
    source: SOURCE_MTG_AHBG,
  },
}
