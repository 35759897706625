const {
  PC_SUBCLASS_BARD_COLLEGE_OF_CREATION,
  PC_SUBCLASS_BARD_COLLEGE_OF_ELOQUENCE,
  PC_SUBCLASS_BARD_COLLEGE_OF_GLAMOUR,
} = require('./../../pcSubClassIdList')

const {
  Dragonborn_College_of_creation_TCoE_data,
  Drow_College_of_eloquence_TCoE_data,
  Filborg_flute_enthralling_performance_MTG_CLB_data,
} = require('./../commonImageCollection')

module.exports = {
  [PC_SUBCLASS_BARD_COLLEGE_OF_CREATION]: [
    Dragonborn_College_of_creation_TCoE_data,
  ],
  [PC_SUBCLASS_BARD_COLLEGE_OF_ELOQUENCE]: [
    Drow_College_of_eloquence_TCoE_data,
  ],
  [PC_SUBCLASS_BARD_COLLEGE_OF_GLAMOUR]: [
    Filborg_flute_enthralling_performance_MTG_CLB_data,
  ],
}
