import React from 'react'
import classNames from 'classnames'

import './PublicationInfoStyles.less'

const PublicationInfoComponent = ({ className = '', children }) => (
  <div className={classNames('PublicationInfo', className)}>
    {children}
  </div>
)

export default PublicationInfoComponent
