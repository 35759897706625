const {SOURCE_PotA} = require('./../../sourceList')

const Marlos_urnrayle_with_ironfang_PotA_img = require('./../../../images/creatures/pota/marlos_urnrayle_with_ironfang.jpeg')

module.exports = {
  Marlos_urnrayle_with_ironfang_PotA_data: {
    src: Marlos_urnrayle_with_ironfang_PotA_img,
    text: 'Марлос Урнрейл, с Железным клыком в руке, воодушевляет подземных акул',
    source: {
      id: SOURCE_PotA,
    },
  },
}
