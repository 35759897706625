const {
  CREATURE_GUARD_DRAKE_BLUE,
} = require('./../../creatureIdList')

const {SOURCE_HOTDQ} = require('./../../sourceList')

const Guard_drake_img_HOTDQ_img = require('./../../../images/creatures/hotdq/guard_drake.png')

module.exports = {
  Guard_drake_blue_HOTDQ_data: {
    src: Guard_drake_img_HOTDQ_img,
    creatureId: CREATURE_GUARD_DRAKE_BLUE,
    source: {
      id: SOURCE_HOTDQ,
    },
  },
}
