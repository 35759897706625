const {SOURCE_MTOF} = require('./../../sourceList')
const {
  CREATURE_GITHYANKI_GISH,
  CREATURE_GITHYANKI_KITHRAK,
  CREATURE_GITHYANKI_SUPREME_COMMANDER,
  CREATURE_GITHZERAI_ANARCH,
  CREATURE_GITHZERAI_ENLIGHTENED,
} = require('./../../creatureIdList')

const Drow_Arachnomancer = require('./../../../images/creatures/mtof/drow_arachnomancer.png')
const Drow_Matron_Mother = require('./../../../images/creatures/mtof/drow_matron_mother.png')
const Githyanki_Gish = require('./../../../images/creatures/mtof/githyanki_gish.png')
const Githyanki_Kithrak = require('./../../../images/creatures/mtof/githyanki_kithrak.png')
const Githyanki_Supreme_Commander = require('./../../../images/creatures/mtof/githyanki_supreme_commander.png')
const Githzerai_Anarch = require('./../../../images/creatures/mtof/githzerai_anarch.png')
const Githzerai_Enlightened = require('./../../../images/creatures/mtof/githzerai_enlightened.png')
const Narzugon_MTOF_img = require('./../../../images/creatures/mtof/narzugon.png')
const Giff_MTOF_img = require('./../../../images/creatures/mtof/giff.png')

module.exports = {
  Drow_Arachnomancer_MTOF_data: {
    src: Drow_Arachnomancer,
    text: 'Дроу-арахномант',
    source: {
      id: SOURCE_MTOF,
    },
  },
  Giff_MTOF_data: {
    src: Giff_MTOF_img,
    text: 'Гифф с мушкетом',
    source: {
      id: SOURCE_MTOF,
    },
  },
  Narzugon_MTOF_data: {
    src: Narzugon_MTOF_img,
    text: 'Нарзугон, верхом на маре, снаряжённой инфернальной сбруей',
    source: {
      id: SOURCE_MTOF,
    },
  },
  Drow_Matron_mother_MTOF_data: {
    src: Drow_Matron_Mother,
    text: 'Дроу верховная мать',
    source: {
      id: SOURCE_MTOF,
    },
  },
  Githyanki_Gish_MTOF_data: {
    src: Githyanki_Gish,
    creatureId: CREATURE_GITHYANKI_GISH,
    source: {
      id: SOURCE_MTOF,
    },
  },
  Githyanki_Kithrak_MTOF_data: {
    src: Githyanki_Kithrak,
    creatureId: CREATURE_GITHYANKI_KITHRAK,
    source: {
      id: SOURCE_MTOF,
    },
  },
  Githyanki_Supreme_Commander_MTOF_data: {
    src: Githyanki_Supreme_Commander,
    creatureId: CREATURE_GITHYANKI_SUPREME_COMMANDER,
    source: {
      id: SOURCE_MTOF,
    },
  },
  Githzerai_Anarch_MTOF_data: {
    src: Githzerai_Anarch,
    creatureId: CREATURE_GITHZERAI_ANARCH,
    source: {
      id: SOURCE_MTOF,
    },
  },
  Githzerai_Enlightened_MTOF_data: {
    src: Githzerai_Enlightened,
    creatureId: CREATURE_GITHZERAI_ENLIGHTENED,
    source: {
      id: SOURCE_MTOF,
    },
  },
}
