import React from "react"

import runeList from "@/constants/runeList"

import ToggleList from '@/components/ToggleList'

import sortByName from '@/utils/sortByName'

import AllRuneListRenderer from './AllRuneListRenderer'

const AllRuneList = () => {
  const list = runeList
    .sort(sortByName)
    .map(
      rune => rune.lvl
        ? {
          ...rune,
          lvlText: `, от ${rune.lvl} уровня`,
        }
        : {
          ...rune,
          lvlText: '',
        }
    )

  return (
    <ToggleList
      list={list}
      ListRenderer={AllRuneListRenderer}
      text='руны'
    />
  )
}

export default AllRuneList
