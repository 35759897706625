const {
  SOURCE_MTG_AFR,
  SOURCE_MTG_CLB,
} = require('./../../sourceList')

const {
  PC_SUBCLASS_DRUID_CIRCLE_OF_SPORES,
  PC_SUBCLASS_DRUID_CIRCLE_OF_STARS,
  PC_SUBCLASS_DRUID_CIRCLE_OF_THE_LAND,
  PC_SUBCLASS_DRUID_CIRCLE_OF_THE_MOON,
  PC_SUBCLASS_DRUID_CIRCLE_OF_WILDFIRE,
} = require('./../../pcSubClassIdList')

const {
  Gnome_Circle_of_spores_TCoE_data,
  Tiefling_Circle_of_stars_TCoE_data,
  Dwarf_Druid_Wildfire_TCoE_data,
} = require('./../commonImageCollection')

const Circle_of_the_Moon_Druid_MTG_AFR_img = require('./../../../images/pcSubClasses/druid/circle-of-the-moon-druid-mtg_afr.jpg')
const Circle_of_the_Land_Druid_MTG_CLB_img = require('./../../../images/pcSubClasses/druid/circle_of_the_land_druid_mtg_clb.webp')

module.exports = {
  // GGTR Страница 23, 28
  [PC_SUBCLASS_DRUID_CIRCLE_OF_SPORES]: Gnome_Circle_of_spores_TCoE_data,
  [PC_SUBCLASS_DRUID_CIRCLE_OF_STARS]: Tiefling_Circle_of_stars_TCoE_data,
  [PC_SUBCLASS_DRUID_CIRCLE_OF_WILDFIRE]: Dwarf_Druid_Wildfire_TCoE_data,
  [PC_SUBCLASS_DRUID_CIRCLE_OF_THE_LAND]: {
    src: Circle_of_the_Land_Druid_MTG_CLB_img,
    text: 'Друид круга Земли',
    source: SOURCE_MTG_CLB,
  },
  [PC_SUBCLASS_DRUID_CIRCLE_OF_THE_MOON]: {
    src: Circle_of_the_Moon_Druid_MTG_AFR_img,
    text: 'Друид круга Луны',
    source: SOURCE_MTG_AFR,
  },
}
