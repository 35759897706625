import React from "react"

import Markdown from "@/components/Markdown"

import './AllRuneListStyles.less'

const AllRuneListRenderer = ({list}) => (
  <ol className='AllRuneListRenderer'>
    {
      list.map(
        ({ name, description, lvlText }) => (
          <li className='AllRuneListRenderer_row'>
            <header className='AllRuneListRenderer_header'>
              {name}{lvlText}
            </header>
            <Markdown className='AllRuneListRenderer_text'>
              {description}
            </Markdown>
          </li>
        )
      )
    }
  </ol>
)

export default AllRuneListRenderer
