const {SOURCE_EE} = require('./../../sourceList')

const Aarakokra_1_img = require('./../../../images/races/aarakokra-1.jpg')

module.exports = {
  Aarakocra_EE_data: {
    src: Aarakokra_1_img,
    text: 'Ааракокра',
    source: {
      id: SOURCE_EE,
    },
  },
}
