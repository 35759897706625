const arrify = require('arrify')

module.exports = description => {
  const descriptionList = description
    ? arrify(description)
    : null
  const descriptionMain = descriptionList && descriptionList.length
    ? descriptionList[0]
    : null
  const descriptionOtherList = descriptionList && descriptionList.length > 1
    ? descriptionList.slice(1)
    : null

  return [
    descriptionMain,
    descriptionOtherList,
  ]
}
