const {SOURCE_MTG_CLB} = require('./../../sourceList')

const Summon_undead_MTG_CLB_img = require('./../../../images/spells/mtg_clb/summon-undead-mtg_clb.jpg')

const Imoen_Mystic_Trickster_MTG_CLB_img = require('./../../../images/pcSubClasses/rogue/imoen-mystic-trickster-mtg_cls.jpg')
const Dynaheir_MTG_CLB_img = require('./../../../images/pcSubClasses/wizard/dynaheir-mtg_clb.jpg')
const Shadowheart_MTG_CLB_img = require('./../../../images/pcSubClasses/cleric/shadowheart-mtg_clb.jpg')
const Erinis_gloom_stalker_MTG_CLB_img = require('./../../../images/pcSubClasses/ranger/erinis_gloom_stalker.jpg')
const Kobolds_MTG_CLB_img = require('./../../../images/creatures/mtg_clb/kobolds_mtg_clb.jpg')
const Orc_feywild_caretaker_MTG_CLB_img = require('./../../../images/races/orc_feywild_caretaker.webp')
const Kenku_artificer_MTG_CLB_img = require('./../../../images/pcClasses/artificer/kenku-artificer.jpg')
const Battlerager_MTG_CLB_img = require('./../../../images/pcSubClasses/barbarian/battlerager_MTG_CLB.webp')
const Filborg_flute_enthralling_performance_MTG_CLB_img = require('./../../../images/pcSubClasses/bard/filborg_flute_enthralling_performance_mtg_clb.jpg')
const Dawnbringer_cleric_MTG_CLB_img = require('./../../../images/pcClasses/cleric/dawnbringer_cleric_mtg_clb.jpg')

module.exports = {
  Dawnbringer_cleric_MTG_CLB_data: {
    src: Dawnbringer_cleric_MTG_CLB_img,
    text: 'Жрец Утреннего Лорда',
    source: SOURCE_MTG_CLB,
  },
  Filborg_flute_enthralling_performance_MTG_CLB_data: {
    src: Filborg_flute_enthralling_performance_MTG_CLB_img,
    text: 'Завораживающее выступление фирболга-флейтиста',
    source: SOURCE_MTG_CLB,
  },
  Battlerager_MTG_CLB_data: {
    src: Battlerager_MTG_CLB_img,
    text: 'Бушующий в бою дварф в шипованном доспехе',
    source: SOURCE_MTG_CLB,
  },
  Kenku_artificer_MTG_CLB_data: {
    src: Kenku_artificer_MTG_CLB_img,
    text: 'Кенку-изобретатель',
    source: SOURCE_MTG_CLB,
  },
  Orc_feywild_caretaker_MTG_CLB_data: {
    src: Orc_feywild_caretaker_MTG_CLB_img,
    text: 'Орк-волшебник с волшебными дракончиками',
    source: SOURCE_MTG_CLB,
  },
  Summon_undead_MTG_data: {
    src: Summon_undead_MTG_CLB_img,
    text: 'Призыв духа призрачной нежити',
    source: SOURCE_MTG_CLB,
  },
  Imoen_Mystic_Trickster_MTG_data: {
    src: Imoen_Mystic_Trickster_MTG_CLB_img,
    text: 'Имоэн, мистическая ловкачка',
    source: SOURCE_MTG_CLB,
  },
  Dynaheir_MTG_CLB_data: {
    src: Dynaheir_MTG_CLB_img,
    text: 'Рашеменка Дайнахейр, воплотительница',
    source: SOURCE_MTG_CLB,
  },
  Shadowheart_MTG_CLB_data: {
    src: Shadowheart_MTG_CLB_img,
    text: 'Полуэльфийка Шэдоухарт, тёмный юстициар богини Шар, жрица домена обмана',
    source: SOURCE_MTG_CLB,
  },
  Kobolds_MTG_CLB_data: {
    src: Kobolds_MTG_CLB_img,
    text: 'Кобольды, атакующие стаей',
    source: SOURCE_MTG_CLB,
  },
  Erinis_gloom_stalker_MTG_CLB_data: {
    src: Erinis_gloom_stalker_MTG_CLB_img,
    text: 'Полурослица Эринис, Сумрачная охотница',
    source: SOURCE_MTG_CLB,
  },
}
