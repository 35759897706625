const {SOURCE_WDH} = require('./../../sourceList')

const Vajra_Safahr_img_WDH = require('./../../../images/creatures/wdh/vajra_safahr.png')

module.exports = {
  Vajra_Safahr_WDH_data: {
    src: Vajra_Safahr_img_WDH,
    text: 'Чёрный Посох Ваджра Сафар c Чёрным посохом в руке',
    source: {
      id: SOURCE_WDH,
    },
  },
}
