module.exports = {
  ...require('./commonImageCollection_ADND2_MM'),
  ...require('./commonImageCollection_AYAGMnC'),
  ...require('./commonImageCollection_AYAGWnW'),
  ...require('./commonImageCollection_BGDIA'),
  ...require('./commonImageCollection_DMG'),
  ...require('./commonImageCollection_DND3_5_DTBoD'),
  ...require('./commonImageCollection_DND3_FnP'),
  ...require('./commonImageCollection_EE'),
  ...require('./commonImageCollection_ERFTLW'),
  ...require('./commonImageCollection_FTD'),
  ...require('./commonImageCollection_GAME_BG3'),
  ...require('./commonImageCollection_GGTR'),
  ...require('./commonImageCollection_HOTDQ'),
  ...require('./commonImageCollection_MGZN_DRGN_378'),
  ...require('./commonImageCollection_MM'),
  ...require('./commonImageCollection_MPMotM'),
  ...require('./commonImageCollection_MTG_AFR'),
  ...require('./commonImageCollection_MTG_AHBG'),
  ...require('./commonImageCollection_MTG_CLB'),
  ...require('./commonImageCollection_MTOF'),
  ...require('./commonImageCollection_PHB'),
  ...require('./commonImageCollection_PotA'),
  ...require('./commonImageCollection_RoT'),
  ...require('./commonImageCollection_SCAG'),
  ...require('./commonImageCollection_SKT'),
  ...require('./commonImageCollection_TCoE'),
  ...require('./commonImageCollection_TTP'),
  ...require('./commonImageCollection_VGtM'),
  ...require('./commonImageCollection_VRGtR'),
  ...require('./commonImageCollection_WDH'),
  ...require('./commonImageCollection_WGtE'),
  ...require('./commonImageCollection_XGtE'),
}
