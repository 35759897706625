import React from 'react'

import { pcSubClassCollection } from '@/constants/pcSubClassList'
import {
  featureCollection,
  featureIdListByPcSubClassId,
} from '@/constants/featureList'

import generateNameStr from '@/utils/generateNameStr'
import upLetter from '@/utils/upLetter'
import splitDescription from '@/utils/splitDescription'

import PcSubClassComponent from './PcSubClassComponent'

export default ({ id: pcSubClassId }) => {
  if (pcSubClassId) {
    const pcSubClass = pcSubClassCollection[pcSubClassId]
    const featureList = featureIdListByPcSubClassId[pcSubClassId].map(
      id => featureCollection[id]
    )

    if (pcSubClass) {
      const {
        name,
        fullName,
        nameEn,
        description,
      } = pcSubClass

      const { header, subHeader } = generateNameStr({ name: fullName, nameEn })
      const [descriptionMain, descriptionOther] = splitDescription(description) || [null]
      const descriptionMainText = descriptionMain && descriptionMain.text || descriptionMain

      return (
        <PcSubClassComponent
          descriptionMain={descriptionMainText}
          descriptionOther={descriptionOther}
          nameText={name}
          featureList={featureList}
          header={upLetter(header)}
          id={pcSubClassId}
          subHeader={upLetter(subHeader)}
          {...pcSubClass}
        />
      )
    }
  }

  return null
}
