const {SOURCE_ROT} = require('./../../sourceList')

const Tiamat_ROT_img = require('./../../../images/creatures/rot/tiamat.webp')
const Color_Dragons_Attack_ROT_img = require('./../../../images/creatures/rot/color_dragons_attack.jpg')
const Metallic_Dragons_ROT_img = require('./../../../images/creatures/rot/metallic_dragons.jpg')
const Green_Dragon_ROT_img = require('./../../../images/creatures/rot/green-dragon.png')
const White_Dragon_ROT_img = require('./../../../images/creatures/rot/white-dragon.png')

module.exports = {
  Tiamat_ROT_data: {
    src: Tiamat_ROT_img,
    text: 'Тиамат, Королева Драконов',
    source: {
      id: SOURCE_ROT,
    },
  },
  Color_Dragons_Attack_ROT_data: {
    src: Color_Dragons_Attack_ROT_img,
    text: 'Атака белого, синего и красного драконов',
    source: {
      id: SOURCE_ROT,
    },
  },
  Metallic_Dragons_ROT_data: {
    src: Metallic_Dragons_ROT_img,
    text: 'Металлические драконы: золотой и два серебряных',
    source: {
      id: SOURCE_ROT,
    },
  },
  Green_Dragon_ROT_data: {
    src: Green_Dragon_ROT_img,
    text: 'Зелёный дракон',
    source: {
      id: SOURCE_ROT,
    },
  },
  White_Dragon_ROT_data: {
    src: White_Dragon_ROT_img,
    text: 'Белый дракон',
    source: {
      id: SOURCE_ROT,
    },
  },
}
