const {SOURCE_EGTW} = require('./../../sourceList')
const {
  PC_SUBCLASS_WIZARD_BLADESINGING,
  PC_SUBCLASS_WIZARD_CHRONURGY_MAGIC,
  PC_SUBCLASS_WIZARD_GRAVITURGY_MAGIC,
  PC_SUBCLASS_WIZARD_ORDER_OF_SCRIBES,
  PC_SUBCLASS_WIZARD_SCHOOL_OF_EVOCATION,
} = require('./../../pcSubClassIdList')
const {
  Drow_High_elf_Bladesinger_TCoE_data,
  Dynaheir_MTG_CLB_data,
  Human_Wizard_Order_of_Scribes_TCoE_data,
} = require('./../commonImageCollection')

const Chronurgy_Wizard_EGTW_img = require('./../../../images/pcSubClasses/wizard/chronurgy_wizard.png')
const Graviturgy_Wizard_EGTW_img = require('./../../../images/pcSubClasses/wizard/graviturgy_wizard.png')

module.exports = {
  [PC_SUBCLASS_WIZARD_BLADESINGING]: [
    Drow_High_elf_Bladesinger_TCoE_data,
  ],
  [PC_SUBCLASS_WIZARD_CHRONURGY_MAGIC]: {
    src: Chronurgy_Wizard_EGTW_img,
    text: 'Волшебник-хронург',
    source: {
      id: SOURCE_EGTW,
    },
  },
  [PC_SUBCLASS_WIZARD_GRAVITURGY_MAGIC]: {
    src: Graviturgy_Wizard_EGTW_img,
    text: 'Волшебница-гравитург',
    source: {
      id: SOURCE_EGTW,
    },
  },
  [PC_SUBCLASS_WIZARD_ORDER_OF_SCRIBES]: [
    Human_Wizard_Order_of_Scribes_TCoE_data,
  ],
  [PC_SUBCLASS_WIZARD_SCHOOL_OF_EVOCATION]: [
    Dynaheir_MTG_CLB_data,
  ],
}
