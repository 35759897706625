const {SOURCE_FTD} = require('./../../sourceList')

const Ancient_Dragons_Hoard_FTD_img = require('./../../../images/creatures/ftd/ancient-dragons-hoard.png')
const Ashardalon_s_Stride_FTD_img = require('./../../../images/spells/ftd/ashardalon_s_stride.png')
const Bahamut_as_Fizban_Helps_FTD_img = require('./../../../images/gods/bahamut-as-fizban-helps.jpg')
const Council_of_Metallic_Dragons_FTD_img = require('./../../../images/creatures/ftd/council-of-metallic-dragons.webp')
const Greatwyrm_Blue_FTD_img = require('./../../../images/creatures/ftd/greatwyrm_blue.webp')
const Bahamut_Young_Monk_FTD_img = require('./../../../images/creatures/ftd/bahamut_young_monk_fight_young_red_dragon.jpg')
const Rime_s_Binding_Ice_FTD_img = require('./../../../images/spells/ftd/rime_s_binding_ice.png')
const Kugluk_FTD_img = require('./../../../images/creatures/ftd/kugluk.png')
const Tiamat_Emergence_from_Avernus_FTD_img = require('./../../../images/gods/tiamats-emergence-from-avernus.png')
const Tiefling_Burglar_FTD_img = require('./../../../images/races/tiefling-burglar.png')

module.exports = {
  Council_of_Metallic_Dragons_FTD_data: {
    src: Council_of_Metallic_Dragons_FTD_img,
    text: 'Физбан обращается к совету металлических драконов по чрезвычайно важному вопросу, вероятно, никак не связанному с выпечкой',
    source: {
      id: SOURCE_FTD,
    },
  },
  Greatwyrm_Blue_FTD_data: {
    src: Greatwyrm_Blue_FTD_img,
    text: 'Неиствующий великий синий вирм угрожает существованию мира, и Бахамут стоит рядом с величайшими героями',
    source: {
      id: SOURCE_FTD,
    },
  },
  Bahamut_Young_Monk_FTD_data: {
    src: Bahamut_Young_Monk_FTD_img,
    text: 'Бахамут, маскируясь под юного монаха, помогает паре приключенцев положить конец налётам молодого красного дракона',
    source: {
      id: SOURCE_FTD,
    },
  },
  Ashardalon_s_Stride_FTD_data: {
    src: Ashardalon_s_Stride_FTD_img,
    text: 'Огонь наполняет следы красного дракона, сотворившего заклинание Ашардалонов путь',
    source: {
      id: SOURCE_FTD,
    },
  },
  Rime_s_Binding_Ice_FTD_data: {
    src: Rime_s_Binding_Ice_FTD_img,
    text: 'Инеев сковывающий лёд приводит к травмам от лап белого дракона-заклинателя',
    source: {
      id: SOURCE_FTD,
    },
  },
  Bahamut_as_Fizban_Helps_FTD_data: {
    src: Bahamut_as_Fizban_Helps_FTD_img,
    text: 'Маскируясь под Физбана Невероятного, Бахамут помогает двум героям спастись от пламенного гнева красного дракона',
    source: {
      id: SOURCE_FTD,
    },
  },
  Kugluk_FTD_data: {
    src: Kugluk_FTD_img,
    text: 'Куглюк считает пламенное дыхание своего «Дедули» поджаривающим благословением',
    source: {
      id: SOURCE_FTD,
    },
  },
  Tiamat_Emergence_from_Avernus_data: {
    src: Tiamat_Emergence_from_Avernus_FTD_img,
    text: 'Выход Тиамат из Аверно',
    source: {
      id: SOURCE_FTD,
    },
  },
  Ancient_Dragons_Hoard_data: {
    src: Ancient_Dragons_Hoard_FTD_img,
    text: 'Клад древнего дракона содержит достаточно сокровищ для вознесения и свержения династий',
    source: {
      id: SOURCE_FTD,
    },
  },
  Tiefling_Burglar_FTD_data: {
    src: Tiefling_Burglar_FTD_img,
    text: 'Пока дракон спит, опытный вор может выкрасть парочку сокровищ, но утащить весь клад — совсем другое дело',
    source: {
      id: SOURCE_FTD,
    },
  },
}
