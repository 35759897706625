import React from 'react'

import SpellList from '@/components/SpellList'

import './SpellGroupByPcClassLvlListStyles.less'

const SpellGroupByPcClassLvlListComponent = ({spellGroupList, className = ''}) => (
  <table className={`SpellGroupByPcClassLvlList ${className}`}>
    <thead>
      <tr className='SpellGroupByPcClassLvlList_row'>
        <th className='SpellGroupByPcClassLvlList_cell SpellGroupByPcClassLvlList_cell-head'>Уровень в классе</th>
        <th className='SpellGroupByPcClassLvlList_cell SpellGroupByPcClassLvlList_cell-head'>Круг заклинаний</th>
        <th className='SpellGroupByPcClassLvlList_cell'>Заклинания</th>
      </tr>
    </thead>
    <tbody>
      {
        spellGroupList.map(
          ({ pcClassLvl, list, spellLvl }) => (
            <tr
              key={pcClassLvl}
              className='SpellGroupByPcClassLvlList_row'
            >
              <th className='SpellGroupByPcClassLvlList_cell SpellGroupByPcClassLvlList_cell-head SpellGroupByPcClassLvlList_lvlCell'>
                {pcClassLvl}
              </th>
              <th className='SpellGroupByPcClassLvlList_cell SpellGroupByPcClassLvlList_cell-head SpellGroupByPcClassLvlList_spellLvl'>
                {spellLvl}
              </th>
              <td className='SpellGroupByPcClassLvlList_cell'>
                <SpellList list={list}/>
              </td>
            </tr>
          )
        )
      }
    </tbody>
  </table>
)

export default SpellGroupByPcClassLvlListComponent
