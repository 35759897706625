const {
  SOURCE_ADND2_MM,
  SOURCE_AYAGMnC,
  SOURCE_AYAGWnW,
  SOURCE_EGTW,
  SOURCE_IDRotF,
  SOURCE_MM,
  SOURCE_OGA,
  SOURCE_VGTM,
} = require('./../../sourceList')
const {
  CREATURE_GOLIATH_WARRIOR,
  CREATURE_HOBGOBLIN,
  CREATURE_HOBGOBLIN_DEVASTATOR,
  CREATURE_HOBGOBLIN_IRON_SHADOW,
  CREATURE_KENKU,
  CREATURE_KOBOLD_INVENTOR,
  CREATURE_KOBOLD_SCALE_SORCERER,
  CREATURE_LIZARDFOLK,
  CREATURE_ORC_EYE_OF_GRUUMSH,
  CREATURE_YUAN_TI_MALISON_1,
  CREATURE_YUAN_TI_MIND_WHISPERER,
  CREATURE_YUAN_TI_NIGHTMARE_SPEAKER,
  CREATURE_YUAN_TI_PIT_MASTER,
} = require('./../../creatureIdList')

const Cloud_giant_Castle_VGTM_img = require(`./../../../images/creatures/vgtm/cloud_giant_castle.png`)
const Ceremorphosis_VGTM_img = require('./../../../images/creatures/vgtm/ceremorphosis.jpg')
const Beholder_keen_VGTM_img = require('./../../../images/creatures/vgtm/beholder_keen.png')
const Bugbear_img_ADND2 = require('./../../../images/creatures/adnd2_mm/Bugbear.jpg')
const Bugbear_img_AYAGMnC = require('./../../../images/creatures/ayagmc/bugbear.jpg')
const Elder_brain_2_VGTM_img = require('./../../../images/creatures/vgtm/elder_brain.png')
const Frost_giant_fight_Remorhaz_VGTM_img = require(`./../../../images/creatures/vgtm/frost_giant_fight_remorhaz.jpg`)
const Goblins_bugbears_AYAGWW = require('./../../../images/creatures/ayagww/goblins_bugbears.jpg')
const Goblins_VGTM_img = require('./../../../images/creatures/vgtm/goblins.png')
const Goliath_male_1_img = require('./../../../images/races/goliath-1.png')
const Goliath_Warrior_and_Spellcaster_EGTW_img = require('./../../../images/races/goliath-2.jpg')
const Goliath_warrior_img_IDROTF = require('./../../../images/creatures/idrotf/goliath_warrior.jpg')
const Grung_Green_Bbloop_OGA_img = require(`./../../../images/creatures/oga/grung_green_bloop.jpg`)
const Grung_Green_Blahp_OGA_img = require(`./../../../images/creatures/oga/grung_green_blahp.jpg`)
const Grung_Green_Bleep_OGA_img = require(`./../../../images/creatures/oga/grung_green_bleep.jpg`)
const Grung_Green_Blip_OGA_img = require(`./../../../images/creatures/oga/grung_green_blip.jpg`)
const Grung_Orange_Dahtenkhan_OGA_img = require(`./../../../images/creatures/oga/grung_orange_dahtenkhan.jpg`)
const Grung_Purple_Bangrang_OGA_img = require(`./../../../images/creatures/oga/grung_purple_bangrang.jpg`)
const Grung_Red_Cahbulos_OGA_img = require(`./../../../images/creatures/oga/grung_red_cahbulos.jpg`)
const Grungs_VGTM_img = require(`./../../../images/creatures/vgtm/grungs.jpeg`)
const Hag_lair_VGTM_img = require('./../../../images/creatures/vgtm/hag_lair.png')
const Hobgoblin_Devastator_VGTM_img = require(`./../../../images/creatures/vgtm/hobgoblin_devastator.jpeg`)
const Hobgoblin_Iron_Shadow_VGTM_img = require(`./../../../images/creatures/vgtm/hobgoblin_iron_shadow.jpeg`)
const Hobgoblin_MM_img = require('./../../../images/creatures/mm/hobgoblin.jpg')
const Kenku_MM_img = require('./../../../images/races/kenku-1.jpg')
const Kenku_VGTM_img = require('./../../../images/creatures/mm/kenku.jpg')
const Kobold_and_Orc_VGTM_img = require('./../../../images/races/kobold-and-orc.jpg')
const Kobold_Dragonshield_img = require(`./../../../images/creatures/vgtm/kobold_dragonshield.jpeg`)
const Kobold_Inventor = require(`./../../../images/creatures/vgtm/kobold_inventor.jpeg`)
const Kobold_Scale_sorcerer = require(`./../../../images/creatures/vgtm/kobold_scale_sorcerer.jpeg`)
const Kobold_Trap_VGTM_img = require('./../../../images/races/kobold-trap.png')
const Kobold_Tribe_VGTM_img = require('./../../../images/creatures/vgtm/kobold_tribe.jpg')
const Lizardfolk_MM_img = require('./../../../images/creatures/mm/lizardfolk.jpg')
const Lizardfolk_VGTM_img = require('./../../../images/races/lizardfolk-1.png')
const Hobgoblin_War_camp_VGTM_img = require('./../../../images/races/hobgoblin_war_camp.jpg')
const Orcs_looking_for_wounded_elf_VGTM_img = require('./../../../images/races/orcs_looking_for_wounded_elf.png')
const Orc_red_fang_of_shargaas_VGTM_img = require('./../../../images/creatures/vgtm/orc_red_fang_of_shargaas.jpeg')
const Orc_eye_of_Gruumsh_VGTM_img = require('./../../../images/creatures/vgtm/orc_eye_of_gruumsh.jpg')
const Orc_s_War_wagon_VGTM_img = require('./../../../images/creatures/vgtm/orcs_war_wagon.jpg')
const Yuan_ti_Mind_whisperer_VGTM_img = require('./../../../images/creatures/vgtm/yuan_ti_mind_whisperer.jpeg')
const Yuan_ti_sacrifice_VGTM_img = require('./../../../images/creatures/vgtm/yuan_ti_sacrifice.jpg')
const Yuan_ti_Nightmare_speaker_VGTM_img = require('./../../../images/creatures/vgtm/yuan_ti_nightmare_speaker.jpeg')
const Yuan_ti_Pit_master_VGTM_img = require('./../../../images/creatures/vgtm/yuan_ti_pit_master.jpeg')

const Yuan_ti_Malison_type_1_img = require('./../../../images/creatures/mm/yuan_ti_malison_type_1.jpeg')

module.exports = {
  Bugbear_ADND2_data: {
    src: Bugbear_img_ADND2,
    text: 'Багбир',
    source: {
      id: SOURCE_ADND2_MM,
    },
  },
  Bugbear_AYAGMnC_data: {
    src: Bugbear_img_AYAGMnC,
    text: 'Багбир',
    source: {
      id: SOURCE_AYAGMnC,
    },
  },
  Ceremorphosis_VGTM_data: {
    src: Ceremorphosis_VGTM_img,
    text: 'Цереморфоз',
    source: {
      id: SOURCE_VGTM,
    },
  },
  Cloud_giant_Castle_VGTM_data: {
    src: Cloud_giant_Castle_VGTM_img,
    text: 'Летающий замок облачного великана',
    source: {
      id: SOURCE_VGTM,
    },
  },
  Frost_giant_fight_Remorhaz_VGTM_data: {
    src: Frost_giant_fight_Remorhaz_VGTM_img,
    text: 'Ледяная великанша борется с реморазом',
    source: {
      id: SOURCE_VGTM,
    },
  },
  Goblins_bugbears_AYAGWnW_data: {
    src: Goblins_bugbears_AYAGWW,
    text: 'Гоблины и багбиры',
    source: {
      id: SOURCE_AYAGWnW,
    },
  },
  Goblins_VGTM_data: {
    src: Goblins_VGTM_img,
    text: 'Гоблины с пленным дварфом',
    source: {
      id: SOURCE_VGTM,
    },
  },
  Goliath_Warrior_IDRotF_data: {
    src: Goliath_warrior_img_IDROTF,
    creatureId: CREATURE_GOLIATH_WARRIOR,
    source: {
      id: SOURCE_IDRotF,
    },
  },
  Goliath_VGTM_data: {
    src: Goliath_male_1_img,
    text: 'Голиаф',
    source: {
      id: SOURCE_VGTM,
    },
  },
  Goliath_Warrior_and_Spellcaster_EGTW_data: {
    src: Goliath_Warrior_and_Spellcaster_EGTW_img,
    text: 'Голиафы',
    source: {
      id: SOURCE_EGTW,
    },
  },
  Grungs_Green_OGA_list: [
    {
      src: Grung_Green_Blahp_OGA_img,
      text: 'Зелёный грунг Б’Лэп',
      source: {
        id: SOURCE_OGA,
      },
    },
    {
      src: Grung_Green_Bleep_OGA_img,
      text: 'Зелёный грунг Б’Лип',
      source: {
        id: SOURCE_OGA,
      },
    },
    {
      src: Grung_Green_Blip_OGA_img,
      text: 'Зелёный грунг Б’Лап',
      source: {
        id: SOURCE_OGA,
      },
    },
    {
      src: Grung_Green_Bbloop_OGA_img,
      text: 'Зелёный грунг Б’Луп',
      source: {
        id: SOURCE_OGA,
      },
    },
  ],
  Grungs_Orange_OGA_data: {
    src: Grung_Orange_Dahtenkhan_OGA_img,
    text: 'Оранжевый грунг Д’Атен’Хан',
    source: {
      id: SOURCE_OGA,
    },
  },
  Grungs_Purple_OGA_data: {
    src: Grung_Purple_Bangrang_OGA_img,
    text: 'Фиолетовый грунг Б’Анг’Р’Анг',
    source: {
      id: SOURCE_OGA,
    },
  },
  Grungs_Red_OGA_data: {
    src: Grung_Red_Cahbulos_OGA_img,
    text: 'Красный грунг к’Абюлос',
    source: {
      id: SOURCE_OGA,
    },
  },
  Grungs_VGTM_data: {
    src: Grungs_VGTM_img,
    text: 'Грунги золотой, зелёной и красной каст',
    source: {
      id: SOURCE_VGTM,
    },
  },
  Hobgoblin_Devastator_VGTM_data: {
    src: Hobgoblin_Devastator_VGTM_img,
    creatureId: CREATURE_HOBGOBLIN_DEVASTATOR,
    source: {
      id: SOURCE_VGTM,
    },
  },
  Hobgoblin_Iron_Shadow_VGTM_data: {
    src: Hobgoblin_Iron_Shadow_VGTM_img,
    creatureId: CREATURE_HOBGOBLIN_IRON_SHADOW,
    source: {
      id: SOURCE_VGTM,
    },
  },
  Hobgoblin_MM_data: {
    src: Hobgoblin_MM_img,
    creatureId: CREATURE_HOBGOBLIN,
    source: {
      id: SOURCE_MM,
    },
  },
  Kenku_MM_data: {
    src: Kenku_MM_img,
    text: 'Кенку',
    source: {
      id: SOURCE_MM,
    },
  },
  Kenku_VGTM_data: {
    creatureId: CREATURE_KENKU,
    src: Kenku_VGTM_img,
    source: {
      id: SOURCE_VGTM,
    },
  },
  Kobold_Dragonshield_VGTM_data: {
    src: Kobold_Dragonshield_img,
    text: 'Кобольд драконий щит',
    source: {
      id: SOURCE_VGTM,
    },
  },
  Kobold_Inventor_VGTM_data: {
    src: Kobold_Inventor,
    creatureId: CREATURE_KOBOLD_INVENTOR,
    source: {
      id: SOURCE_VGTM,
    },
  },
  Kobold_Scale_sorcerer_VGTM_data: {
    src: Kobold_Scale_sorcerer,
    creatureId: CREATURE_KOBOLD_SCALE_SORCERER,
    source: {
      id: SOURCE_VGTM,
    },
  },
  Kobold_Trap_VGTM_data: {
    src: Kobold_Trap_VGTM_img,
    text: 'Ловушка кобольдов',
    source: {
      id: SOURCE_VGTM,
    },
  },
  Kobold_Tribe_VGTM_data: {
    src: Kobold_Tribe_VGTM_img,
    text: 'Сокровища племени кобольдов',
    source: {
      id: SOURCE_VGTM,
    },
  },
  Lizardfolk_VGTM_data: {
    src: Lizardfolk_VGTM_img,
    creatureId: CREATURE_LIZARDFOLK,
    source: {
      id: SOURCE_VGTM,
    },
  },
  Lizardfolk_MM_data: {
    src: Lizardfolk_MM_img,
    creatureId: CREATURE_LIZARDFOLK,
    source: {
      id: SOURCE_MM,
    },
  },
  Elder_brain_traveling_VGTM_data: {
    src: Elder_brain_2_VGTM_img,
    text: 'Иллитиды запрягли квагготов перевозить старший мозг',
    source: {
      id: SOURCE_VGTM,
    },
  },
  Kobold_and_Orc_VGTM_data: {
    src: Kobold_and_Orc_VGTM_img,
    text: 'Кобольд с орком в таверне',
    source: {
      id: SOURCE_VGTM,
    },
  },
  Hobgoblin_War_camp_VGTM_data: {
    src: Hobgoblin_War_camp_VGTM_img,
    text: 'Военный лагерь хобгоблинов',
    source: {
      id: SOURCE_VGTM,
    },
  },
  Orcs_looking_for_wounded_elf_VGTM_data: {
    src: Orcs_looking_for_wounded_elf_VGTM_img,
    text: 'Орки ищут раненого эльфа',
    source: {
      id: SOURCE_VGTM,
    },
  },
  Orc_on_giant_bat_VGTM_data: {
    src: Orc_red_fang_of_shargaas_VGTM_img,
    text: 'Орк красный клык Шаргааса на гигантской летучей мыши',
    source: {
      id: SOURCE_VGTM,
    },
  },
  Orc_eye_of_Gruumsh_VGTM_data: {
    src: Orc_eye_of_Gruumsh_VGTM_img,
    creatureId: CREATURE_ORC_EYE_OF_GRUUMSH,
    source: {
      id: SOURCE_VGTM,
    },
  },
  Orc_s_War_wagon_VGTM_data: {
    src: Orc_s_War_wagon_VGTM_img,
    text: 'Боевая повозка орков',
    source: {
      id: SOURCE_VGTM,
    },
  },
  Beholder_keen_VGTM_data: {
    src: Beholder_keen_VGTM_img,
    text: 'Родственники бехолдеров',
    source: {
      id: SOURCE_VGTM,
    },
  },
  Hag_lair_VGTM_data: {
    src: Hag_lair_VGTM_img,
    text: 'Логово карг',
    source: {
      id: SOURCE_VGTM,
    },
  },
  Yuan_ti_mind_whisperer_VGTM_data: [
    {
      src: Yuan_ti_Mind_whisperer_VGTM_img,
      creatureId: CREATURE_YUAN_TI_MIND_WHISPERER,
      source: {
        id: SOURCE_VGTM,
      },
    },
    {
      src: Yuan_ti_Malison_type_1_img,
      creatureId: CREATURE_YUAN_TI_MALISON_1,
      source: {
        id: SOURCE_MM,
      },
    },
  ],
  Yuan_ti_nightmare_speaker_VGTM_data: {
    src: Yuan_ti_Nightmare_speaker_VGTM_img,
    creatureId: CREATURE_YUAN_TI_NIGHTMARE_SPEAKER,
    source: {
      id: SOURCE_VGTM,
    },
  },
  Yuan_ti_pit_master_VGTM_data: {
    src: Yuan_ti_Pit_master_VGTM_img,
    creatureId: CREATURE_YUAN_TI_PIT_MASTER,
    source: {
      id: SOURCE_VGTM,
    },
  },
  Yuan_ti_sacrifice_VGTM_data: {
    src: Yuan_ti_sacrifice_VGTM_img,
    text: 'Юань-ти приносит человека в жертву',
    source: {
      id: SOURCE_VGTM,
    },
  },
}
