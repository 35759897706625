import React from 'react'
import PropTypes from 'prop-types'

import { dndMagicCollection } from '@/constants/magicList'

import MagicSchoolComponent from './MagicSchoolComponent'

const MagicSchoolContainer = ({ magicSchoolId, ...rest }) => {
  if (magicSchoolId) {
    const { name, nameFull } = dndMagicCollection[magicSchoolId]

    return (
      <MagicSchoolComponent
        magicSchoolId={magicSchoolId}
        name={name}
        title={nameFull}
        {...rest}
      />
    )
  }

  return null
}

MagicSchoolContainer.propTypes = {
  magicSchoolId: PropTypes.string,
}

MagicSchoolContainer.defaultProps = {
  magicSchoolId: '',
}

export default MagicSchoolContainer
