const {SOURCE_MPMotM} = require('./../../sourceList')

const Cover_MPMotM_img = require('./../../../images/creatures/mpmotm/MPMotM-coverart.jpg')

module.exports = {
  Cover_MPMotM_data: {
    src: Cover_MPMotM_img,
    text: 'Волшебник Морденкайнен парит по Астральному плану верхом на ки-рине, не подозревая о подкрадывающемся к ним астральном дредноуте.',
    source: {
      id: SOURCE_MPMotM,
    },
  },
}
