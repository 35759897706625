import React from "react"

import Markdown from "@/components/Markdown"
import SourceInfo from "@/components/SourceInfo"
import SpellByPcLvlList from '@/components/SpellByPcLvlList'

import generateTextLinks from "@/utils/generateTextLinks"

import './ManeuverListStyles.less'

const ManeuverListRenderer = ({list}) => (
  <ol className='ManeuverListRenderer'>
    {
      list.map(
        ({ name, description, source, spellIdList }) => (
          <li className='ManeuverListRenderer_row'>
            <header className='ManeuverListRenderer_header'>
              {name}
            </header>
            <Markdown className='ManeuverListRenderer_text'>
              {generateTextLinks(description)}
            </Markdown>
            <SourceInfo
              className='ManeuverListRenderer_source'
              source={source}
              useFullName
            />
            {
              spellIdList
                ? <SpellByPcLvlList spellIdList={spellIdList}/>
                : null
            }
          </li>
        )
      )
    }
  </ol>
)

export default ManeuverListRenderer
