import React from 'react'
import PropTypes from 'prop-types'

import ImageList from '@/components/ImageList'

import pcSubClassImageCollection from '@/constants/images/pcSubClassImageCollection'
import {pcSubClassCollection} from '@/constants/pcSubClassList'

const PcSubClassImageListContainer = ({ pcSubClassId }) => {
  if (pcSubClassId) {
    const pcSubClass = pcSubClassCollection[pcSubClassId]
    const imageList = pcSubClassImageCollection[pcSubClassId]

    if (imageList) {
      return (
        <ImageList
          imageList={imageList}
          {...pcSubClass}
        />
      )
    }

    return null
  }

  return null
}

PcSubClassImageListContainer.propTypes = {
  pcSubClassId: PropTypes.string.isRequired,
}

export default PcSubClassImageListContainer
