import React from "react"

import PcClassAbilityComponent from "./PcClassAbilityComponent"

export default (
  {
    subHeaderGenerator,
    ability,
    ability: {
      isSpellCircleBound,
      lvl,
    },
  }
) => isSpellCircleBound
  ? null
  : (
    <PcClassAbilityComponent
      subHeaderText={subHeaderGenerator(lvl)}
      {...ability}
    />
  )
