const listToCollectionById = require('./../utils/listToCollectionById')
const sortByName = require('./../utils/sortByName')
const {SOURCE_XGTE} = require('./sourceList')
const {
  MAGIC_ABJURATION,
  MAGIC_CONJURATION,
  MAGIC_DIVINATION,
  MAGIC_ENCHANTMENT,
  MAGIC_EVOCATION,
  MAGIC_ILLUSION,
  MAGIC_NECROMANCY,
  MAGIC_TRANSMUTATION,
} = require('./magicList')
const {
  DAMAGE_FORCE,
  DAMAGE_NECROTIC,
  DAMAGE_PIERCING,
  DAMAGE_POISON,
  DAMAGE_PSYCHIC,
  DAMAGE_SLASHING,
} = require('./damageTypeList')

const ARCANE_SHOT_BANISHING_ARROW = 'banishing_arrow'
const ARCANE_SHOT_BEGUILING_ARROW = 'beguiling_arrow'
const ARCANE_SHOT_BURSTING_ARROW = 'bursting_arrow'
const ARCANE_SHOT_ENFEEBLING_ARROW = 'enfeebling_arrow'
const ARCANE_SHOT_GRASPING_ARROW = 'grasping_arrow'
const ARCANE_SHOT_PIERCING_ARROW = 'piercing_arrow'
const ARCANE_SHOT_SEEKING_ARROW = 'seeking_arrow'
const ARCANE_SHOT_SHADOW_ARROW = 'shadow_arrow'

const arcaneShotList = [
  {
    id: ARCANE_SHOT_BANISHING_ARROW,
    name: 'Изгоняющая стрела',
    nameEn: 'Banishing Arrow',
    magicSchoolId: MAGIC_ABJURATION,
    damageTypeId: DAMAGE_FORCE,
    description: `Вы используете магию ограждения, чтобы временно изгнать свою цель в безвредное место в Стране Фей. Существо, в которое попала стрела, должно успешно пройти испытание Харизмы или оно будет изгнано. Будучи изгнанным существо становится недееспособным и его скорость равна 0. В конце своего следующего хода, цель появляется в месте, которое занимала, или ближайшем незанятом месте.

После достижения 18 уровня в этом классе цель также получает 2к6 урона силовым полем, когда по ней попадает стрела.`,
    source: {
      id: SOURCE_XGTE,
      page: 22,
    },
  },
  {
    id: ARCANE_SHOT_BEGUILING_ARROW,
    name: 'Обманная стрела',
    nameEn: 'Beguiling Arrow',
    magicSchoolId: MAGIC_ENCHANTMENT,
    damageTypeId: DAMAGE_PSYCHIC,
    description: `Ваша магия очарования заставляет эту стрелу временно запутать свою цель. Существо, в которое попала эта стрела, получает дополнительно 2к6 психического урона и выбирает одного из Ваших союзников не далее 30 футов от цели. Цель должна успешно пройти испытание Мудрости или будет очарована выбранным союзником до начала Вашего следующего хода. Этот эффект заканчивается раньше если выбранный союзник атакует очарованную цель, нанесет ей урон или заставит пройти испытание.

Психический урон увеличивается до 4к6, когда Вы достигаете 18 уровня в этом классе.`,
    source: {
      id: SOURCE_XGTE,
      page: 22,
    },
  },
  {
    id: ARCANE_SHOT_BURSTING_ARROW,
    name: 'Взрывная стрела',
    nameEn: 'Bursting Arrow',
    magicSchoolId: MAGIC_EVOCATION,
    damageTypeId: DAMAGE_FORCE,
    description: `Вы наделяете Вашу стрелу силовым полем с помощью школы воплощения. Эта энергия взрывается после Вашей атаки. Сразу же после того как стрела попадает по существу, цель и все остальные существа в радиусе 10 футов от неё получают по 2к6 урона силовым полем.

Урон силовым полем увеличивается до 4к6, когда Вы достигаете 18 уровня в этом классе.`,
    source: {
      id: SOURCE_XGTE,
      page: 22,
    },
  },
  {
    id: ARCANE_SHOT_ENFEEBLING_ARROW,
    name: 'Ослабляющая стрела',
    nameEn: 'Enfeebling Arrow',
    magicSchoolId: MAGIC_NECROMANCY,
    damageTypeId: DAMAGE_NECROTIC,
    description: `Вы вплетаете магию некромантии в Вашу стрелу. Существо, по которому попала стрела, получает дополнительно 2к6 урона некротической энергией. Цель должна также пройти испытание Телосложения, или урон, наносимый её атаками оружием, уменьшается в два раза до начала Вашего следующего хода.

Некротический урон увеличивается до 4к6, когда Вы достигаете 18 уровня в этом классе.`,
    source: {
      id: SOURCE_XGTE,
      page: 22,
    },
  },
  {
    id: ARCANE_SHOT_GRASPING_ARROW,
    name: 'Сжимающая стрела',
    nameEn: 'Grasping Arrow',
    magicSchoolId: MAGIC_CONJURATION,
    damageTypeId: [
      DAMAGE_POISON,
      DAMAGE_SLASHING,
    ],
    description: `Когда эта стрела попадает по цели, магия призыва создает хватающие, ядовитые колючие кусты, которые обвивают цель. Существо, по которому попала стрела, получает дополнительно 2к6 урона ядом, его скорость уменьшена на 10 футов и оно получает 2к6 рубящего урона каждый ход, когда сдвигается на 1 фут или больше без телепортации в первый раз за ход. Цель или любое существо, которое может до него дотянуться, может действием убрать кусты с помощью успешной проверки Силы (Атлетика). В противном случае кусты остаются на 1 минуту либо пока Вы снова не используете этот вариант выстрела.

Урон ядом и рубящий урон оба увеличиваются до 4к6, когда Вы достигаете 18 уровня в этом классе.`,
    source: {
      id: SOURCE_XGTE,
      page: 22,
    },
  },
  {
    id: ARCANE_SHOT_PIERCING_ARROW,
    name: 'Пронзающая стрела',
    nameEn: 'Piercing Arrow',
    magicSchoolId: MAGIC_TRANSMUTATION,
    damageTypeId: DAMAGE_PIERCING,
    description: `Вы используете магию преобразования, чтобы придать стреле свойство бесплотности. Когда Вы используете этот вариант, Вы не делаете бросок атаки для атаки. Вместо этого стрела летит по линии в 1 фут шириной и 30 футов длиной, до того как исчезнуть. Стрела проходит сквозь объекты беспрепятственно, игнорируя укрытия. Каждое существо в этой линии должно пройти испытание Ловкости. При провале существо получает урон, как будто стрела попала по нему, плюс дополнительно 1к6 колющего урона. При успешном испытании цель получает половину этого урона.

Колющий урон увеличивается до 2к6, когда Вы достигаете 18 уровня в этом классе.`,
    source: {
      id: SOURCE_XGTE,
      page: 22,
    },
  },
  {
    id: ARCANE_SHOT_SEEKING_ARROW,
    name: 'Ищущая стрела',
    nameEn: 'Seeking Arrow',
    magicSchoolId: MAGIC_DIVINATION,
    damageTypeId: DAMAGE_FORCE,
    description: `Используя магию прорицания, Вы даруете своей стреле способность искать цель. Когда Вы используете этот вариант, Вы не делаете бросок атаки для атаки. Вместо этого, выберите одно существо, которое Вы видели за прошедшую минуту. Стрела летит к этому существу, огибает углы, если нужно, и игнорирует укрытие на половину и укрытие на три четверти. Если цель находится в радиусе досягаемости оружия, и если есть путь, по которому стрела может добраться до цели, то цель должна пройти испытание Ловкости. Иначе стрела исчезает, пролетев столько, сколько смогла. При провале цель получает урон, как будто в неё попала стрела плюс дополнительно 1к6 урона силовым полем, и Вы узнаёте где сейчас находится цель. При успешном испытании цель получает половину урона, и Вы не узнаёте где она.

Урон силовым полем увеличивается до 2к6 когда Вы достигаете 18 уровня в этом классе.`,
    source: {
      id: SOURCE_XGTE,
      page: 22,
    },
  },
  {
    id: ARCANE_SHOT_SHADOW_ARROW,
    name: 'Теневая стрела',
    nameEn: 'Shadow Arrow',
    magicSchoolId: MAGIC_ILLUSION,
    damageTypeId: DAMAGE_PSYCHIC,
    description: `Вы вплетаете магию иллюзии в свою стрелу, которая заслоняет зрение врага тенями. Существо, в которое попала стрела, получает дополнительно 2к6 урона психической энергией и должно успешно пройти испытание Мудрости или не сможет видеть далее 5 футов до начала Вашего следующего хода.

Урон психической энергией увеличивается до 4к6 когда Вы достигаете 18 уровня в этом классе.`,
    source: {
      id: SOURCE_XGTE,
      page: 23,
    },
  },
]
  .sort(sortByName)

module.exports = arcaneShotList

module.exports.arcaneShotCollection = listToCollectionById(arcaneShotList)

module.exports.ARCANE_SHOT_BANISHING_ARROW = ARCANE_SHOT_BANISHING_ARROW
module.exports.ARCANE_SHOT_BEGUILING_ARROW = ARCANE_SHOT_BEGUILING_ARROW
module.exports.ARCANE_SHOT_BURSTING_ARROW = ARCANE_SHOT_BURSTING_ARROW
module.exports.ARCANE_SHOT_ENFEEBLING_ARROW = ARCANE_SHOT_ENFEEBLING_ARROW
module.exports.ARCANE_SHOT_GRASPING_ARROW = ARCANE_SHOT_GRASPING_ARROW
module.exports.ARCANE_SHOT_PIERCING_ARROW = ARCANE_SHOT_PIERCING_ARROW
module.exports.ARCANE_SHOT_SEEKING_ARROW = ARCANE_SHOT_SEEKING_ARROW
module.exports.ARCANE_SHOT_SHADOW_ARROW = ARCANE_SHOT_SHADOW_ARROW
