import React from 'react'
import * as PropTypes from 'prop-types'

import { spellCollection } from '@/constants/spellList'

import ToggleList from '@/components/ToggleList'

import SpellByPcLvlListRenderer from './components/SpellByPcLvlListRenderer'

class SpellByPcLvlListContainer extends React.Component {
  state = {
    visible: false,
  }

  toggleVisibility = () => {
    const { visible } = this.state

    this.setState({
      visible: !visible,
    })
  }

  render() {
    const { spellIdList, ...rest } = this.props

    return spellIdList && spellIdList.length
      ? (
        <ToggleList
          collection={spellCollection}
          idList={spellIdList}
          ListRenderer={SpellByPcLvlListRenderer}
          text='список заклинаний'
          {...rest}
        />
      )
      : null
  }
}

SpellByPcLvlListContainer.propTypes = {
  spellIdList: PropTypes.arrayOf(PropTypes.string),
}

SpellByPcLvlListContainer.defaultProps = {
  spellIdList: [],
}

export default SpellByPcLvlListContainer
