import React from 'react'
import classNames from 'classnames'

import './ToggleListStyles.less'

const ToggleListComponent = (
  {
    list,
    toggleVisibility,
    visible,
    text,
    ListRenderer,
    ...rest
  }
) => list
  ? (
    <section className='ToggleList'>
      <button
        className={classNames(
          'ToggleList_btn',
          {
            'ToggleList_btn-opened': visible,
            'ToggleList_btn-closed': !visible,
          }
        )}
        onClick={toggleVisibility}
      >
        {
          visible
            ? 'Скрыть '
            : 'Показать '
        }
        {text} ({list.length})
      </button>

      {
        visible
          ? (
            <ListRenderer
              className='ToggleList_list'
              list={list}
              {...rest}
            />
          )
          : null
      }
    </section>
  )
  : null

export default ToggleListComponent
