import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import get from 'lodash/get'

import PublicationInfo from '@/components/PublicationInfo'

import './ReleasedAsStyles.less'

const ReleasedAsComponent = (
  {
    className = '',
    collection,
    preText,
    releasedAs: id,
    urlGenerator,
  }
) => {
  if (!id) {
    return null
  }

  const item = collection[id]
  const {nameScheme = 'name'} = item

  return (
    <PublicationInfo className={classNames('ReleasedAs', className)}>
      {preText} как <Link to={urlGenerator(id)}>{get(item, nameScheme)}</Link>
    </PublicationInfo>
  )
}

export default ReleasedAsComponent
