import React from "react"

import sortByName from "@/utils/sortByName"

import {maneuverCollection} from "@/constants/maneuverList"

import ToggleList from '@/components/ToggleList'

import ManeuverListRenderer from './ManeuverListRenderer'

const ManeuverList = ({idList}) => {
  const list = idList
    .map(id => maneuverCollection[id])
    .sort(sortByName)

  return (
    <ToggleList
      list={list}
      ListRenderer={ManeuverListRenderer}
      text='приёмы'
    />
  )
}

export default ManeuverList
