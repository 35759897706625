import React from 'react'

import Anchor from "@/components/Anchor"
import Description from '@/components/Description'
import HeaderBlock from "@/components/HeaderBlock"
import SpellByPcLvlList from "@/components/SpellByPcLvlList"
import PcClassAbilityList from '@/components/PcClassAbilityList'

import AllArcaneShotList from "./components/AllArcaneShotList"
import AllElementalDisciplineList from "./components/AllElementalDisciplineList"
import AllInfusionList from "./components/AllInfusionList"
import AllInvocationList from "./components/AllInvocationList"
import AllRuneList from "./components/AllRuneList"
import FightingStyleList from "./components/FightingStyleList"
import ManeuverList from "./components/ManeuverList"
import PcSubClassList from "./components/PcSubClassList"

import './PcClassAbilityStyles.less'

export default (
  {
    childFeatureList,
    fightingStyleIdList,
    id,
    listAllArcaneShots,
    listAllElementalDisciplines,
    listAllInfusions,
    listAllInvocations,
    listAllRunes,
    maneuverIdList,
    name,
    pcClassId,
    showSubClassList,
    source,
    spellCircleLvl,
    spellIdList,
    spellIdListOriginal,
    subHeaderText,
    text,
  }
) => (
  <section
    className='PcClassAbility Anchor_wrapper'
    id={id}
  >
    <HeaderBlock
      className='PcClassAbility_header'
      subHeader={subHeaderText}
    >
      <Anchor id={id} name={name} />
      {name}
    </HeaderBlock>
    <Description
      className='PcClassAbility_description'
      text={text}
      source={source}
    />
    <SpellByPcLvlList
      spellIdList={spellIdListOriginal || spellIdList}
      spellCircleLvl={spellCircleLvl}
    />
    {
      listAllInfusions
        ? <AllInfusionList/>
        : null
    }
    {
      listAllInvocations
        ? <AllInvocationList/>
        : null
    }
    {
      listAllArcaneShots
        ? <AllArcaneShotList/>
        : null
    }
    {
      listAllElementalDisciplines
        ? <AllElementalDisciplineList/>
        : null
    }
    {
      listAllRunes
        ? <AllRuneList/>
        : null
    }
    {
      fightingStyleIdList
        ? <FightingStyleList idList={fightingStyleIdList}/>
        : null
    }
    {
      maneuverIdList
        ? <ManeuverList idList={maneuverIdList}/>
        : null
    }
    {
      showSubClassList
        ? <PcSubClassList pcClassId={pcClassId}/>
        : null
    }
    {
      childFeatureList
        ? (
          <PcClassAbilityList
            abilityList={childFeatureList}
            subHeaderGenerator={lvl => `${name}, умение ${lvl} уровня`}
          />
        )
        : null
    }
  </section>
)
