const {SOURCE_MTG_AFR} = require('./../../sourceList')

const Hexblade_MTG_AFR_img = require('./../../../images/pcSubClasses/warlock/hexblade-mtg_afr.jpg')
const Vecna_MTG_AFR_img = require('./../../../images/magicItems/mtg_afr/vecna-mtg_afr.jpg')
const Tiefling_wizard_MTG_AFR_img = require('./../../../images/races/tiefling_wizard_mtg_afr.jpg')
const Tiamat_MTG_AFR_img = require('./../../../images/gods/tiamat_mtg_afr.jpg')
const Tiamat_alt_MTG_AFR_img = require('./../../../images/gods/tiamat_alt_mtg_afr.jpg')
const Potion_of_Healing_MTG_AFR_img = require('./../../../images/magicItems/mtg_afr/potion-of-healing-mtg_afr.jpg')

module.exports = {
  Hexblade_MTG_AFR_data: {
    src: Hexblade_MTG_AFR_img,
    text: 'Дроу, колдун ведьмовского клинка',
    source: {
      id: SOURCE_MTG_AFR,
    },
  },
  Vecna_MTG_AFR_data: {
    src: Vecna_MTG_AFR_img,
    text: 'Архилич Векна с Книгой Мерзкой Тьмы',
    source: {
      id: SOURCE_MTG_AFR,
    },
  },
  Tiefling_wizard_MTG_AFR_data: {
    src: Tiefling_wizard_MTG_AFR_img,
    text: 'Тифлингша-волшебница',
    source: {
      id: SOURCE_MTG_AFR,
    },
  },
  Tiamat_MTG_AFR_data: {
    src: Tiamat_MTG_AFR_img,
    text: 'Тиамат уничтожает группу глупцов',
    source: {
      id: SOURCE_MTG_AFR,
    },
  },
  Tiamat_alt_MTG_AFR_data: {
    src: Tiamat_alt_MTG_AFR_img,
    source: {
      id: SOURCE_MTG_AFR,
    },
  },
  Potion_of_Healing_MTG_AFR_data: {
    src: Potion_of_Healing_MTG_AFR_img,
    text: 'Зелье лечения',
    source: {
      id: SOURCE_MTG_AFR,
    },
  },
}
