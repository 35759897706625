import React from "react"

import generateInfusionPageUrlById from "@/utils/generateInfusionPageUrlById"

import ListRenderer from './ListRenderer'

const AllInfusionListRenderer = props => (
  <ListRenderer
    {...props}
    pageUrlGenerator={generateInfusionPageUrlById}
  />
)

export default AllInfusionListRenderer
