import React from 'react'
import * as PropTypes from 'prop-types'

import sortByName from '@/utils/sortByName'

import ToggleListComponent from './ToggleListComponent'

class ToggleListContainer extends React.Component {
  state = {
    visible: false,
  }

  toggleVisibility = () => {
    const { visible } = this.state

    this.setState({
      visible: !visible,
    })
  }

  render() {
    let { idList, list, collection, ...rest } = this.props
    const { visible } = this.state
    const { toggleVisibility } = this

    if (
      list
      || (
        collection
        && idList
        && idList.length
      )
    ) {
      list = list
        || (
          idList
            .map(id => collection[id])
            .sort(sortByName)
        )

      return list
        ? (
          <ToggleListComponent
            list={list}
            visible={visible}
            toggleVisibility={toggleVisibility}
            {...rest}
          />
        )
        : null
    } else {
      return null
    }
  }
}

ToggleListContainer.propTypes = {
  collection: PropTypes.object,
  idList: PropTypes.arrayOf(PropTypes.string),
  list: PropTypes.array,
}

ToggleListContainer.defaultProps = {
  collection: null,
  idList: [],
  list: null,
}

export default ToggleListContainer
