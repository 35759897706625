const {
  PC_SUBCLASS_WARLOCK_THE_ARCHFEY,
  PC_SUBCLASS_WARLOCK_THE_HEXBLADE,
} = require('./../../pcSubClassIdList')

const {
  Warlock_Archfey_Elf_Female_SCAG_data,
  Hexblade_MTG_AFR_data,
} = require('./../commonImageCollection')

module.exports = {
  [PC_SUBCLASS_WARLOCK_THE_ARCHFEY]: [
    Warlock_Archfey_Elf_Female_SCAG_data,
  ],
  [PC_SUBCLASS_WARLOCK_THE_HEXBLADE]: [
    Hexblade_MTG_AFR_data,
  ],
}
