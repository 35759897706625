import React from "react"
import arrify from "arrify"
import { Link } from 'gatsby'

import generatePcSubClassPageUrlById from '@/utils/generatePcSubClassPageUrlById'

import { sourceCollection } from '@/constants/sourceList'

import './PcSubClassListStyles.less'

const PcSubClassListRenderer = ({list}) => (
  <ul className='PcSubClassListRenderer'>
    {
      list.map(
        ({ id, isReady, name, fullName, source, pcClassIdToRender: pcClassId }) => {
          const { isUA, date, shortName: sourceName, name: sourceTitle } = sourceCollection[arrify(source)[0].id]
          const dateText = isUA
            ? `[${date}]`
            : ``
          const title = `${fullName}\n\n${sourceTitle} ${dateText}`
          const text = (
            <>
              <span className='PcSubClassListRenderer_name'>{name}</span>
              <span className='PcSubClassListRenderer_source'>{sourceName}</span>
            </>
          )

          return (
            <li
              className='PcSubClassListRenderer_item'
              title={title}
            >
              {
                isReady
                  ? (
                    <Link
                      className={`PcSubClassListRenderer_text PcSubClassListRenderer_text-link ${pcClassId}`}
                      to={generatePcSubClassPageUrlById(id, pcClassId)}
                    >
                      {text}
                    </Link>
                  )
                  : (
                    <span className='PcSubClassListRenderer_text'>
                      {text}
                    </span>
                  )
              }
            </li>
          )
        }
      )
    }
  </ul>
)

export default PcSubClassListRenderer
