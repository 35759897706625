const {SOURCE_TTP} = require('./../../sourceList')
const {CREATURE_TORTLE} = require('./../../creatureIdList')

const Tortle_TTP_img = require('./../../../images/creatures/mtof/tortle.png')

module.exports = {
  Tortle_TTP_data: {
    src: Tortle_TTP_img,
    creatureId: CREATURE_TORTLE,
    source: {
      id: SOURCE_TTP,
    },
  },
}
