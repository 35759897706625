const {SOURCE_BGDIA} = require("./../../sourceList");
const {CREATURE_CHAIN_DEVIL} = require("./../../creatureIdList");

const Princeps_Kovik_BGDIA_img = require('./../../../images/creatures/bgdia/Princeps_Kovik.webp')

module.exports = {
  Princeps_Kovik_BGDIA_data: {
    src: Princeps_Kovik_BGDIA_img,
    creatureId: CREATURE_CHAIN_DEVIL,
    text: 'Цепной дьявол Принцепс Ковик',
    source: {
      id: SOURCE_BGDIA,
    },
  },
};
