const listToCollectionById = require('./../utils/listToCollectionById')
const sortByName = require('./../utils/sortByName')
const {
  SOURCE_PHB,
  SOURCE_ERRATA_PHB,
} = require('./sourceList')
const {
  SPELL_BURNING_HANDS,
  SPELL_CONE_OF_COLD,
  SPELL_FIREBALL,
  SPELL_FLY,
  SPELL_GASEOUS_FORM,
  SPELL_GUST_OF_WIND,
  SPELL_HOLD_PERSON,
  SPELL_SHATTER,
  SPELL_STONESKIN,
  SPELL_THUNDERWAVE,
  SPELL_WALL_OF_FIRE,
  SPELL_WALL_OF_STONE,
} = require('./spellIdList')

const ELEMENTAL_DISCIPLINE_BREATH_OF_WINTER = 'breath_of_winter'
const ELEMENTAL_DISCIPLINE_CLENCH_OF_THE_NORTH_WIND = 'clench_of_the_north_wind'
const ELEMENTAL_DISCIPLINE_ELEMENTAL_ATTUNEMENT = 'elemental_attunement'
const ELEMENTAL_DISCIPLINE_ETERNAL_MOUNTAIN_DEFENSE = 'eternal_mountain_defense'
const ELEMENTAL_DISCIPLINE_FANGS_OF_THE_FIRE_SNAKE = 'fangs_of_the_fire_snake'
const ELEMENTAL_DISCIPLINE_FIST_OF_FOUR_THUNDERS = 'fist_of_four_thunders'
const ELEMENTAL_DISCIPLINE_FIST_OF_UNBROKEN_AIR = 'fist_of_unbroken_air'
const ELEMENTAL_DISCIPLINE_FLAMES_OF_THE_PHOENIX = 'flames_of_the_phoenix'
const ELEMENTAL_DISCIPLINE_GONG_OF_THE_SUMMIT = 'gong_of_the_summit'
const ELEMENTAL_DISCIPLINE_MIST_STANCE = 'mist_stance'
const ELEMENTAL_DISCIPLINE_RIDE_THE_WIND = 'ride_the_wind'
const ELEMENTAL_DISCIPLINE_RIVER_OF_HUNGRY_FLAME = 'river_of_hungry_flame'
const ELEMENTAL_DISCIPLINE_RUSH_OF_THE_GALE_SPIRITS = 'rush_of_the_gale_spirits'
const ELEMENTAL_DISCIPLINE_SHAPE_THE_FLOWING_RIVER = 'shape_the_flowing_river'
const ELEMENTAL_DISCIPLINE_SWEEPING_CINDER_STRIKE = 'sweeping_cinder_strike'
const ELEMENTAL_DISCIPLINE_WATER_WHIP = 'water_whip'
const ELEMENTAL_DISCIPLINE_WAVE_OF_ROLLING_EARTH = 'wave_of_rolling_earth'

const elementalDisciplineList = [
  {
    id: ELEMENTAL_DISCIPLINE_WATER_WHIP,
    name: `Водяной кнут`,
    nameEn: `Water Whip`,
    description: `Вы можете действием потратить 2 очка Ци и создать водяной кнут, сбивающий существ с ног.

Видимое Вами в пределах 30 футов существо должно пройти испытание Ловкости.

При провале существо получает дробящий урон 3к10, плюс дополнительный дробящий урон 1к10 за каждое дополнительное потраченное очко Ци, и Вы можете либо сбить его с ног, либо подтянуть его на 25 футов ближе к себе.

При успехе существо получает половину урона, не подтягивается и не сбивается с ног.`,
    lvl: 3,
    source: [
      {
        id: SOURCE_PHB,
        page: 82,
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Теперь требует действия, а не бонусного действия',
        page: 1,
      },
    ],
  },
  {
    id: ELEMENTAL_DISCIPLINE_GONG_OF_THE_SUMMIT,
    name: `Гонг на вершине горы`,
    nameEn: `Gong of the Summit`,
    description: `Вы можете потратить 3 очка Ци, чтобы использовать заклинание [Дребезги](SPELL:${SPELL_SHATTER})`,
    lvl: 6,
    spellIdList: SPELL_SHATTER,
    source: {
      id: SOURCE_PHB,
      page: 81,
    },
  },
  {
    id: ELEMENTAL_DISCIPLINE_BREATH_OF_WINTER,
    name: `Дыхание зимы`,
    nameEn: `Breath of Winter`,
    description: `Вы можете потратить 6 очков Ци, чтобы использовать заклинание [Конус холода](SPELL:${SPELL_CONE_OF_COLD}).`,
    lvl: 17,
    spellIdList: SPELL_CONE_OF_COLD,
    source: {
      id: SOURCE_PHB,
      page: 82,
    },
  },
  {
    id: ELEMENTAL_DISCIPLINE_WAVE_OF_ROLLING_EARTH,
    name: `Земляной вал`,
    nameEn: `Wave of Rolling Earth`,
    description: `Вы можете потратить 6 очков Ци, чтобы использовать заклинание [Каменная стена](SPELL:${SPELL_WALL_OF_STONE}).`,
    lvl: 17,
    spellIdList: SPELL_WALL_OF_STONE,
    source: {
      id: SOURCE_PHB,
      page: 82,
    },
  },
  {
    id: ELEMENTAL_DISCIPLINE_SWEEPING_CINDER_STRIKE,
    name: `Испепеляющий удар`,
    nameEn: `Sweeping Cinder Strike`,
    description: `Вы можете потратить 2 очка Ци, чтобы использовать заклинание [Огненные ладони](SPELL:${SPELL_BURNING_HANDS}).`,
    lvl: 3,
    spellIdList: SPELL_BURNING_HANDS,
    source: {
      id: SOURCE_PHB,
      page: 82,
    },
  },
  {
    id: ELEMENTAL_DISCIPLINE_FIST_OF_FOUR_THUNDERS,
    name: `Кулак четырёх громов`,
    nameEn: `Fist of Four Thunders`,
    description: `Вы можете потратить 2 очка Ци, чтобы использовать заклинание [Волна грома](SPELL:${SPELL_THUNDERWAVE}). `,
    lvl: 3,
    spellIdList: SPELL_THUNDERWAVE,
    source: {
      id: SOURCE_PHB,
      page: 82,
    },
  },
  {
    id: ELEMENTAL_DISCIPLINE_RUSH_OF_THE_GALE_SPIRITS,
    name: `Натиск штормовых духов`,
    nameEn: `Rush of the Gale Spirits`,
    description: `Вы можете потратить 2 очка Ци, чтобы использовать заклинание [Порыв ветра](SPELL:${SPELL_GUST_OF_WIND}).`,
    lvl: 3,
    spellIdList: SPELL_GUST_OF_WIND,
    source: {
      id: SOURCE_PHB,
      page: 82,
    },
  },
  {
    id: ELEMENTAL_DISCIPLINE_FIST_OF_UNBROKEN_AIR,
    name: `Несокрушимый воздушный кулак`,
    nameEn: `Fist of Unbroken Air`,
    description: `Вы создаёте область сжатого воздуха, бьющую как могучий кулак. Вы действием тратите 2 очка Ци и выбираете существо в пределах 30 футов от себя. Это существо должно пройти испытание Силы.

При провале существо получает дробящий урон 3к10, плюс дополнительно 1к10 дробящего урона за каждое дополнительно потраченное очко Ци, и Вы можете отбросить существо на 20 футов от себя и сбить его с ног.

При успехе существо получает половину урона, не отбрасывается, не сбивается с ног.`,
    lvl: 3,
    source: {
      id: SOURCE_PHB,
      page: 82,
    },
  },
  {
    id: ELEMENTAL_DISCIPLINE_CLENCH_OF_THE_NORTH_WIND,
    name: `Объятья северного ветра`,
    nameEn: `Clench of the North Wind`,
    description: `Вы можете потратить 3 очка Ци, чтобы использовать заклинание [Удержание личности](SPELL:${SPELL_HOLD_PERSON}). `,
    lvl: 6,
    spellIdList: SPELL_HOLD_PERSON,
    source: {
      id: SOURCE_PHB,
      page: 82,
    },
  },
  {
    id: ELEMENTAL_DISCIPLINE_RIDE_THE_WIND,
    name: `Осёдланный ветер`,
    nameEn: `Ride the Wind`,
    description: `Вы можете потратить 4 очка Ци, чтобы использовать заклинание [Полёт](SPELL:${SPELL_FLY}), нацеленное на себя.`,
    lvl: 11,
    spellIdList: SPELL_FLY,
    source: {
      id: SOURCE_PHB,
      page: 82,
    },
  },
  {
    id: ELEMENTAL_DISCIPLINE_FLAMES_OF_THE_PHOENIX,
    name: `Пламя феникса`,
    nameEn: `Flames of the Phoenix`,
    description: `Вы можете потратить 4 очка Ци, чтобы использовать заклинание [Огненный шар](SPELL:${SPELL_FIREBALL}).`,
    lvl: 11,
    spellIdList: SPELL_FIREBALL,
    source: {
      id: SOURCE_PHB,
      page: 82,
    },
  },
  {
    id: ELEMENTAL_DISCIPLINE_ETERNAL_MOUNTAIN_DEFENSE,
    name: `Прочность вечных гор`,
    nameEn: `Eternal Mountain Defense`,
    description: `Вы можете потратить 5 очков Ци, чтобы использовать заклинание [Каменная кожа](SPELL:${SPELL_STONESKIN}), нацеленное на себя.`,
    lvl: 17,
    spellIdList: SPELL_STONESKIN,
    source: [
      {
        id: SOURCE_PHB,
        page: 82,
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Теперь требует 17 уровень, а не 11',
        page: 1,
      },
    ],
  },
  {
    id: ELEMENTAL_DISCIPLINE_RIVER_OF_HUNGRY_FLAME,
    name: `Река голодного пламени`,
    nameEn: `River of Hungry Flame`,
    description: `Вы можете потратить 5 очков Ци, чтобы использовать заклинание [Огненная стена](SPELL:${SPELL_WALL_OF_FIRE}).`,
    lvl: 17,
    spellIdList: SPELL_WALL_OF_FIRE,
    source: {
      id: SOURCE_PHB,
      page: 82,
    },
  },
  {
    id: ELEMENTAL_DISCIPLINE_ELEMENTAL_ATTUNEMENT,
    name: `Родство со стихией`,
    nameEn: `Elemental Attunement`,
    description: `Вы можете действием на некоторое время взять под контроль стихийные силы, расположенные в пределах 30 футов. Это позволит выбрать один из следующих эффектов:

* Создаётся безвредный мгновенный эффект, связанный с водой, воздухом, землёй или огнём, такой как сноп искр, дуновение ветра, поток лёгкого тумана или тихое гудение камня.
* Мгновенно загорается или тушится свеча, факел или небольшой костёр.
* Остужается или нагревается до 1 фунта неживой материи на время до 1 часа.
* Вода, воздух, земля или огонь, способные втиснуться в куб с длиной ребра 1 фут, принимают на 1 минуту выбранную Вами грубую форму.`,
    lvl: 3,
    source: [
      {
        id: SOURCE_PHB,
        page: 82,
      },
      {
        id: SOURCE_ERRATA_PHB,
        problemText: 'Дальность действия теперь 30 футов, а не «поблизости»',
        page: 1,
      },
    ],
  },
  {
    id: ELEMENTAL_DISCIPLINE_MIST_STANCE,
    name: `Туманная стойка`,
    nameEn: `Mist Stance`,
    description: `Вы можете потратить 4 очка Ци, чтобы использовать заклинание [Газообразная форма](SPELL:${SPELL_GASEOUS_FORM}), нацеленное на себя.`,
    lvl: 11,
    spellIdList: SPELL_GASEOUS_FORM,
    source: {
      id: SOURCE_PHB,
      page: 82,
    },
  },
  {
    id: ELEMENTAL_DISCIPLINE_FANGS_OF_THE_FIRE_SNAKE,
    name: `Зубы огненной змеи`,
    nameEn: `Fangs of the Fire Snake`,
    description: `Когда Вы в свой ход используете действие Атака, Вы можете потратить 1 очко Ци, и во время атаки из ваших рук и ног будут вырываться языки пламени.

Досягаемость безоружных атак для этой и последующих атак текущего хода увеличивается на 10 футов. Попадание таких атак причиняет урон огнём вместо дробящего урона, и Вы можете при попадании потратить ещё 1 очко Ци, чтобы увеличить урон конкретной атаки на урон огнём 1к10. `,
    lvl: 3,
    source: {
      id: SOURCE_PHB,
      page: 82,
    },
  },
  {
    id: ELEMENTAL_DISCIPLINE_SHAPE_THE_FLOWING_RIVER,
    name: `Формирование текущей реки`,
    nameEn: `Shape the Flowing River`,
    description: `Вы можете действием потратить 1 очко Ци, чтобы выбрать область льда или воды в пространстве с длиной стороны не больше 30 футов, в пределах 120 футов от себя.

Вы можете превратить в этой области воду в лёд и наоборот, и Вы можете придавать льду в этой области любую форму. Вы можете повысить или понизить уровень льда, создать или заполнить углубление, возвести или сравнять стену, или создать ледяной столб. 

Размер любого изменения не может превышать половину наибольшей стороны области. Например, если Вы изменяете пространство с длиной стороны в 30 футов, можно создать столб до 15 футов в высоту, поднять или опустить высоту области до 15 футов, вырыть углубление до 15 футов, и так далее.

Вы не можете замуровать существо в лёд или причинить этим воздействием другому существу урон.`,
    lvl: 3,
    source: {
      id: SOURCE_PHB,
      page: 82,
    },
  },
]
  .sort(sortByName)

module.exports = elementalDisciplineList

module.exports.elementalDisciplineCollection = listToCollectionById(elementalDisciplineList)

module.exports.ELEMENTAL_DISCIPLINE_BREATH_OF_WINTER = ELEMENTAL_DISCIPLINE_BREATH_OF_WINTER
module.exports.ELEMENTAL_DISCIPLINE_CLENCH_OF_THE_NORTH_WIND = ELEMENTAL_DISCIPLINE_CLENCH_OF_THE_NORTH_WIND
module.exports.ELEMENTAL_DISCIPLINE_ELEMENTAL_ATTUNEMENT = ELEMENTAL_DISCIPLINE_ELEMENTAL_ATTUNEMENT
module.exports.ELEMENTAL_DISCIPLINE_ETERNAL_MOUNTAIN_DEFENSE = ELEMENTAL_DISCIPLINE_ETERNAL_MOUNTAIN_DEFENSE
module.exports.ELEMENTAL_DISCIPLINE_FANGS_OF_THE_FIRE_SNAKE = ELEMENTAL_DISCIPLINE_FANGS_OF_THE_FIRE_SNAKE
module.exports.ELEMENTAL_DISCIPLINE_FIST_OF_FOUR_THUNDERS = ELEMENTAL_DISCIPLINE_FIST_OF_FOUR_THUNDERS
module.exports.ELEMENTAL_DISCIPLINE_FIST_OF_UNBROKEN_AIR = ELEMENTAL_DISCIPLINE_FIST_OF_UNBROKEN_AIR
module.exports.ELEMENTAL_DISCIPLINE_FLAMES_OF_THE_PHOENIX = ELEMENTAL_DISCIPLINE_FLAMES_OF_THE_PHOENIX
module.exports.ELEMENTAL_DISCIPLINE_GONG_OF_THE_SUMMIT = ELEMENTAL_DISCIPLINE_GONG_OF_THE_SUMMIT
module.exports.ELEMENTAL_DISCIPLINE_MIST_STANCE = ELEMENTAL_DISCIPLINE_MIST_STANCE
module.exports.ELEMENTAL_DISCIPLINE_RIDE_THE_WIND = ELEMENTAL_DISCIPLINE_RIDE_THE_WIND
module.exports.ELEMENTAL_DISCIPLINE_RIVER_OF_HUNGRY_FLAME = ELEMENTAL_DISCIPLINE_RIVER_OF_HUNGRY_FLAME
module.exports.ELEMENTAL_DISCIPLINE_RUSH_OF_THE_GALE_SPIRITS = ELEMENTAL_DISCIPLINE_RUSH_OF_THE_GALE_SPIRITS
module.exports.ELEMENTAL_DISCIPLINE_SHAPE_THE_FLOWING_RIVER = ELEMENTAL_DISCIPLINE_SHAPE_THE_FLOWING_RIVER
module.exports.ELEMENTAL_DISCIPLINE_SWEEPING_CINDER_STRIKE = ELEMENTAL_DISCIPLINE_SWEEPING_CINDER_STRIKE
module.exports.ELEMENTAL_DISCIPLINE_WATER_WHIP = ELEMENTAL_DISCIPLINE_WATER_WHIP
module.exports.ELEMENTAL_DISCIPLINE_WAVE_OF_ROLLING_EARTH = ELEMENTAL_DISCIPLINE_WAVE_OF_ROLLING_EARTH
