const {SOURCE_MTG_AFR} = require('./../../sourceList')
const {
  PC_SUBCLASS_SORCERER_STORM_SORCERY,
  PC_SUBCLASS_SORCERER_WILD_MAGIC,
} = require('./../../pcSubClassIdList')

const {
  Sorcerer_Storm_Human_Male_SCAG_data,
} = require('./../commonImageCollection')
const Wild_magic_MTG_AFR_img = require('./../../../images/pcSubClasses/sorcerer/wild_magic_mtg_afr.jpg')

module.exports = {
  [PC_SUBCLASS_SORCERER_STORM_SORCERY]: [
    Sorcerer_Storm_Human_Male_SCAG_data,
  ],
  [PC_SUBCLASS_SORCERER_WILD_MAGIC]: {
    src: Wild_magic_MTG_AFR_img,
    text: 'Чародей дикой магии',
    source: {
      id: SOURCE_MTG_AFR,
    },
  },
}
