const {SOURCE_TCoE} = require('./../../sourceList')

const Artificer_Alchemist_TCoE_img = require('./../../../images/pcSubClasses/artificer/alchemist.png')
const Artificer_Armorer_TCoE_img = require('./../../../images/pcSubClasses/artificer/armorer.png')
const Artificer_Artillerist_TCoE_img = require('./../../../images/pcSubClasses/artificer/artillerist.png')
const Artificer_Battle_smith_TCoE_img = require('./../../../images/pcSubClasses/artificer/battle_smith.png')
const Artificer_Battle_smith_2_TCoE_img = require('./../../../images/pcSubClasses/artificer/battle_smith_2.png')

const Barbarian_Path_of_the_beast_TCoE_img = require('./../../../images/pcSubClasses/barbarian/path_of_the_beast.png')
const Barbarian_Path_of_wild_magic_TCoE_img = require('./../../../images/pcSubClasses/barbarian/path_of_wild_magic.png')

const Bard_College_of_creation_TCoE_img = require('./../../../images/pcSubClasses/bard/college_of_creation.png')
const Bard_College_of_eloquence_TCoE_img = require('./../../../images/pcSubClasses/bard/college_of_eloquence.png')

const Cleric_hardness_TCoE_img = require('./../../../images/pcClasses/cleric/cleric-harnesses-divine-power.png')
const Cleric_Order_domain_TCoE_img = require('./../../../images/pcSubClasses/cleric/order_domain.png')
const Cleric_Peace_domain_TCoE_img = require('./../../../images/pcSubClasses/cleric/peace_domain.png')
const Cleric_Twilight_domain_TCoE_img = require('./../../../images/pcSubClasses/cleric/twilight_domain.png')

const Druid_Circle_of_wildfire_TCoE_img = require('./../../../images/pcSubClasses/druid/circle_of_wildfire.png')
const Druid_Wild_companion_TCoE_img = require('./../../../images/pcClasses/druid/druid_wild_companion.png')
const Druid_Circle_of_spores_TCoE_img = require('./../../../images/pcSubClasses/druid/circle_of_spores.png')
const Druid_Circle_of_stars_TCoE_img = require('./../../../images/pcSubClasses/druid/circle_of_stars.png')

const Fighter_Sparring_TCoE_img = require('./../../../images/pcClasses/fighter/fighter_sparring.png')
const Fighter_Psi_warrior_TCoE_img = require('./../../../images/pcSubClasses/fighter/psi_warrior.png')
const Fighter_Purple_dragon_knight_TCoE_img = require('./../../../images/pcSubClasses/fighter/purple_dragon_knight.png')
const Fighter_Rune_knight_TCoE_img = require('./../../../images/pcSubClasses/fighter/rune_knight.png')

const Monk_Way_of_the_astral_self_TCoE_img = require('./../../../images/pcSubClasses/monk/way_of_the_astral_self.png')
const Monk_Way_of_mercy_TCoE_img = require('./../../../images/pcSubClasses/monk/way_of_mercy.png')
const Monk_Way_of_the_four_elements_TCoE_img = require('./../../../images/pcSubClasses/monk/way_of_the_four_elements.png')

const Paladin_seek_guidance_TCoE_img = require('./../../../images/pcClasses/paladin/young_paladin_seek_guidance.png')
const Paladin_Oath_of_glory_TCoE_img = require('./../../../images/pcSubClasses/paladin/oath_of_glory.png')

const Halfling_Ranger_TCoE_img = require('./../../../images/pcClasses/ranger/halfling_ranger.png')
const Human_Ranger_Fey_Wanderer_TCoE_img = require('./../../../images/pcSubClasses/ranger/human-ranger-fey-wanderer.png')
const Gnome_Ranger_Swarmkeeper_TCoE_img = require('./../../../images/pcSubClasses/ranger/gnome-ranger-swarmkeeper.png')

const Drow_Rogue_TCoE_img = require('./../../../images/pcClasses/rogue/drow_rogue.png')
const Tiefling_Rogue_Phantom_TCoE_img = require('./../../../images/pcSubClasses/rogue/tiefling-rogue-phantom.png')

const Dragonborn_Sorcerer_TCoE_img = require('./../../../images/pcClasses/sorcerer/dragonborn_sorcerer_transmuted.png')

const Tiefling_Warlock_talisman_TCoE_img = require('./../../../images/pcClasses/warlock/tiefling_warlock_talisman.png')

const Wizard_Students_TCoE_img = require('./../../../images/pcClasses/wizard/wizard_students.png')
const Wizard_Bladesinger_TCoE_img = require('./../../../images/pcSubClasses/wizard/bladesinger.png')
const Human_Wizard_Order_of_Scribes_TCoE_img = require('./../../../images/pcSubClasses/wizard/order_of_scribes.png')

const Orc_tattoo_Elf_TCoE_img = require('./../../../images/races/orc_tattoo_elf.png')

module.exports = {
  Drow_Tiefling_Armorer_TCoE_data: {
    src: Artificer_Armorer_TCoE_img,
    text: 'Бронники — дроу-лазутчик и тифлинг-страж',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Dwarf_Alchemist_TCoE_data: {
    src: Artificer_Alchemist_TCoE_img,
    text: 'Горная дварфийка-алхимик со слугой-гомункулом',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Dwarf_Druid_Wildfire_TCoE_data: {
    src: Druid_Circle_of_wildfire_TCoE_img,
    text: 'Горная дварфийка, друид круга лесного пожара',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Gnome_Battle_smith_TCoE_data: {
    src: Artificer_Battle_smith_TCoE_img,
    text: 'Гномка-изобретательница Ви и её стальной защитник-куролиск бьются с противниками на крыше молниевого поезда',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Gnome_Battle_smith_2_TCoE_data: {
    src: Artificer_Battle_smith_2_TCoE_img,
    text: 'Скальный гном боевой кузнец со стальным защитником',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Human_Artillerist_TCoE_data: {
    src: Artificer_Artillerist_TCoE_img,
    text: 'Человек-артиллерист с волшебной пушкой',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Human_Path_of_the_beast_TCoE_data: {
    src: Barbarian_Path_of_the_beast_TCoE_img,
    text: 'Женщина-варварша пути зверя',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Wood_elf_Path_of_wild_magic_TCoE_data: {
    src: Barbarian_Path_of_wild_magic_TCoE_img,
    text: 'Лесная эльфийка-варварша пути дикой магии',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Dragonborn_College_of_creation_TCoE_data: {
    src: Bard_College_of_creation_TCoE_img,
    text: 'Драконорождённый бард коллегии творения оживляет статую для танца',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Drow_College_of_eloquence_TCoE_data: {
    src: Bard_College_of_eloquence_TCoE_img,
    text: 'Дроу бард коллегии красноречия',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Dragonborn_Order_domain_TCoE_data: {
    src: Cleric_Order_domain_TCoE_img,
    text: 'Драконорождённый жрец порядка',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Gnome_Circle_of_spores_TCoE_data: {
    src: Druid_Circle_of_spores_TCoE_img,
    text: 'Гном-друид круга спор собирает грибы вместе со своим спутником — жаболюдом-зомби',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Orc_Way_of_the_four_elements_TCoE_data: {
    src: Monk_Way_of_the_four_elements_TCoE_img,
    text: 'Орк-монах четырёх стихий проводит наполненный Ци удар',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Tiefling_Oath_of_glory_TCoE_data: {
    src: Paladin_Oath_of_glory_TCoE_img,
    text: 'Тифлинг-паладин клятвы славы',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Drow_High_elf_Bladesinger_TCoE_data: {
    src: Wizard_Bladesinger_TCoE_img,
    text: 'Певцы клинка — дроу и высший эльф',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Human_Wizard_Order_of_Scribes_TCoE_data: {
    src: Human_Wizard_Order_of_Scribes_TCoE_img,
    text: 'Человек, волшебник ордена писцов',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Dragonborn_Purple_dragon_knight_TCoE_data: {
    src: Fighter_Purple_dragon_knight_TCoE_img,
    text: 'Драконорождённый — посыльный Ордена Пурпурного Дракона',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Human_Peace_domain_TCoE_data: {
    src: Cleric_Peace_domain_TCoE_img,
    text: 'Жрец домена мира, служащий Эльдат',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Human_Twilight_domain_TCoE_data: {
    src: Cleric_Twilight_domain_TCoE_img,
    text: 'Жрица сумрака',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Tiefling_Circle_of_stars_TCoE_data: {
    src: Druid_Circle_of_stars_TCoE_img,
    text: 'Тифлинг-друид круга звёзд',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Female_Cleric_hardness_TCoE_data: {
    src: Cleric_hardness_TCoE_img,
    text: 'Жрица усмиряет божественную мощь',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Female_Druid_Wild_companion_TCoE_data: {
    src: Druid_Wild_companion_TCoE_img,
    text: 'Друид с диким спутником',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Human_Tiefling_Sparring_TCoE_data: {
    src: Fighter_Sparring_TCoE_img,
    text: 'Молодой воин спаррингует с тифлингом-инструктором',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Human_Paladin_seek_guidance_TCoE_data: {
    src: Paladin_seek_guidance_TCoE_img,
    text: 'Молодой паладин ищет божественных наставлений',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Halfling_Ranger_TCoE_data: {
    src: Halfling_Ranger_TCoE_img,
    text: 'Легконогий полурослик-следопыт исследует дикую природу',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Drow_Rogue_TCoE_data: {
    src: Drow_Rogue_TCoE_img,
    text: 'Дроу-плут прицеливается',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Dragonborn_Sorcerer_TCoE_data: {
    src: Dragonborn_Sorcerer_TCoE_img,
    text: 'Драконорождённый чародей сотворяет преобразованное заклинание',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Tiefling_Warlock_talisman_TCoE_data: {
    src: Tiefling_Warlock_talisman_TCoE_img,
    text: 'Тифлинг-колдун взывает к силам своего талисмана',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Tiefling_Rogue_Phantom_TCoE_data: {
    src: Tiefling_Rogue_Phantom_TCoE_img,
    text: 'Тифлинг, плут-фантом',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Wizard_Students_TCoE_data: {
    src: Wizard_Students_TCoE_img,
    text: 'Ученики волшебного анклава отдыхают, пока их одноклассники практикуют магию',
    source: {
      id: SOURCE_TCoE,
    },
  },
  High_elf_Psi_warrior_TCoE_data: {
    src: Fighter_Psi_warrior_TCoE_img,
    text: 'Высший эльф пси-воин',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Orc_Rune_knight_TCoE_data: {
    src: Fighter_Rune_knight_TCoE_img,
    text: 'Орчиха рунный рыцарь',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Wood_elf_Way_of_mercy_TCoE_data: {
    src: Monk_Way_of_mercy_TCoE_img,
    text: 'Лесной эльф монах пути милосердия',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Dragonborn_Monk_astral_self_TCoE_data: {
    src: Monk_Way_of_the_astral_self_TCoE_img,
    text: 'Драконорождённый монах пути астрального Я',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Human_Ranger_Fey_Wanderer_TCoE_data: {
    src: Human_Ranger_Fey_Wanderer_TCoE_img,
    text: 'Человек-следопыт фейский странник',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Gnome_Ranger_Swarmkeeper_TCoE_data: {
    src: Gnome_Ranger_Swarmkeeper_TCoE_img,
    text: 'Лесная гномка-роеводка',
    source: {
      id: SOURCE_TCoE,
    },
  },
  Orc_tattoo_Elf_TCoE_data: {
    src: Orc_tattoo_Elf_TCoE_img,
    text: 'Орк татуирует своего друга-эльфа',
    source: {
      id: SOURCE_TCoE,
    },
  },
}
