const {SOURCE_MTG_CLB} = require('./../../sourceList')
const {
  PC_SUBCLASS_ARTIFICER_ALCHEMIST,
  PC_SUBCLASS_ARTIFICER_ARMORER,
  PC_SUBCLASS_ARTIFICER_BATTLE_SMITH,
  PC_SUBCLASS_ARTIFICER_ARTILLERIST,
} = require('./../../pcSubClassIdList')

const {
  Drow_Tiefling_Armorer_TCoE_data,
  Dwarf_Alchemist_TCoE_data,
  Gnome_Battle_smith_2_TCoE_data,
  Gnome_Battle_smith_TCoE_data,
  Human_Artillerist_TCoE_data,
  Shifter_Artillerist_ERFTLW_data,
} = require('./../commonImageCollection')

const Ingenious_artillerist_MTG_CLB_img = require('./../../../images/pcSubClasses/artificer/ingenious_artillerist_mtg_clb.webp')

module.exports = {
  [PC_SUBCLASS_ARTIFICER_BATTLE_SMITH]: [
    Gnome_Battle_smith_2_TCoE_data,
    Gnome_Battle_smith_TCoE_data,
  ],
  [PC_SUBCLASS_ARTIFICER_ALCHEMIST]: [
    Dwarf_Alchemist_TCoE_data,
  ],
  [PC_SUBCLASS_ARTIFICER_ARMORER]: [
    Drow_Tiefling_Armorer_TCoE_data,
  ],
  [PC_SUBCLASS_ARTIFICER_ARTILLERIST]: [
    Human_Artillerist_TCoE_data,
    Shifter_Artillerist_ERFTLW_data,
    {
      src: Ingenious_artillerist_MTG_CLB_img,
      text: 'Гениальный артиллерист',
      source: SOURCE_MTG_CLB,
    },
  ],
}
