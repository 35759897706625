const {SOURCE_PHB} = require('./../../sourceList')

const Criminal_Background_PHB_img = require('./../../../images/backgrounds/criminal.webp')
const Drow_img_PHB = require('./../../../images/creatures/phb/drow.jpg')
const Dwarf_Female_Cleric_Knowledge_PHB_img = require('./../../../images/races/dwarf_female_cleric_knowledge_PHB.png')
const Elf_sun_female_1_img = require('./../../../images/races/elf-sun-1.webp')
const Entertainer_Background_PHB_img = require('./../../../images/backgrounds/entertainer.webp')
const Guild_Artisan_Background_PHB_img = require('./../../../images/backgrounds/guild_artisan.jpg')
const Hermit_Background_PHB_img = require('./../../../images/backgrounds/hermit.jpg')
const Human_Cleric_Oghma_female_1_img = require('./../../../images/races/human-1.png')
const Outlander_Background_PHB_img = require('./../../../images/backgrounds/outlander.jpg')
const Soldier_Background_PHB_img = require('./../../../images/backgrounds/soldier.jpg')

module.exports = {
  Drow_PHB_data: {
    src: Drow_img_PHB,
    text: 'Дроу',
    source: {
      id: SOURCE_PHB,
    },
  },
  Elf_sun_female_PHB_data: {
    src: Elf_sun_female_1_img,
    text: 'Солнечная эльфийка, волшебница, мудрец',
    source: {
      id: SOURCE_PHB,
    },
  },
  Criminal_Background_PHB_data: {
    src: Criminal_Background_PHB_img,
    text: 'Тифлинг, преступник',
    source: {
      id: SOURCE_PHB,
    },
  },
  Guild_Artisan_Background_PHB_data: {
    src: Guild_Artisan_Background_PHB_img,
    text: 'Коренастый полурослик, гильдейский ремесленник',
    source: {
      id: SOURCE_PHB,
    },
  },
  Hermit_Background_PHB_data: {
    src: Hermit_Background_PHB_img,
    text: 'Отшельник',
    source: {
      id: SOURCE_PHB,
    },
  },
  Outlander_Background_PHB_data: {
    src: Outlander_Background_PHB_img,
    text: 'Чужеземка',
    source: {
      id: SOURCE_PHB,
    },
  },
  Soldier_Background_PHB_data: {
    src: Soldier_Background_PHB_img,
    text: 'Солдатка',
    source: {
      id: SOURCE_PHB,
    },
  },
  Entertainer_Background_PHB_data: {
    src: Entertainer_Background_PHB_img,
    text: 'Артист',
    source: {
      id: SOURCE_PHB,
    },
  },
  Human_Cleric_Oghma_female_PHB_data: {
    src: Human_Cleric_Oghma_female_1_img,
    text: 'Жрица Огмы',
    source: {
      id: SOURCE_PHB,
    },
  },
  Dwarf_Female_Cleric_Knowledge_PHB_data: {
    src: Dwarf_Female_Cleric_Knowledge_PHB_img,
    text: 'Щитовая дварфийка, жрица Морадина',
    source: {
      id: SOURCE_PHB,
    },
  },
}
