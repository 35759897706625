const {
  PC_SUBCLASS_PALADIN_OATH_OF_GLORY,
} = require('./../../pcSubClassIdList')

const {
  Tiefling_Oath_of_glory_TCoE_data,
} = require('./../commonImageCollection')

module.exports = {
  [PC_SUBCLASS_PALADIN_OATH_OF_GLORY]: [
    Tiefling_Oath_of_glory_TCoE_data,
  ],
}
