const {SOURCE_SCAG} = require('./../../sourceList')

const City_watch_Bg_img_SCAG = require('./../../../images/backgrounds/city_watch.png')
const Cleric_of_Lathander_Human_Male_SCAG_img = require('./../../../images/pcClasses/cleric/cleric-of-lathander.png')
const Dwarf_Paladin_Female_SCAG_img = require('./../../../images/races/dwarf_paladin_female_SCAG.png')
const Elf_Sun_Male_SCAG_img = require('./../../../images/races/elf_sun_male_SCAG.png')
const Elturgard_Chain_Shirt_Breastplate_SCAG_img = require('./../../../images/gears/scag/elturgard_chain_shirt_breastplate.png')
const Far_traveler_Bg_img_SCAG = require('./../../../images/backgrounds/far_traveler.png')
const Fighter_Tiefling_SCAG_img = require('./../../../images/pcClasses/fighter/tiefling_fighter.png')
const Gnome_Forest_Druid_Female_SCAG_img = require('./../../../images/pcClasses/druid/gnome_forest_druid_female_SCAG.jpg')
const Half_Elf_Female_Druid_SCAG_img = require('./../../../images/races/half_elf.webp')
const Half_Orc_Male_Barbarian_Totem_SCAG_img = require('./../../../images/pcSubClasses/barbarian/half_orc_male_barbarian_totem.png')
const Halfling_Rogue_Female_SCAG_img = require('./../../../images/races/halfling_rogue_female_SCAG.png')
const Human_Female_Barbarian_SCAG_img = require('./../../../images/races/human_female_barbarian.png')
const Human_Female_Bard_SCAG_img = require('./../../../images/pcClasses/bard/human_female_bard_SCAG.png')
const Human_Monk_Male_SCAG_img = require('./../../../images/pcClasses/monk/human_monk_male_SCAG.png')
const Humans_Waterdhavian_SCAG_img = require('./../../../images/races/humans_waterdhavian.webp')
const Inheritor_Bg_img_SCAG = require('./../../../images/backgrounds/inheritor.png')
const Knight_of_the_order_Bg_img_SCAG = require('./../../../images/backgrounds/knight_of_the_order.png')
const Ranger_Drizzt_and_Guenhwyvar_SCAG_img = require('./../../../images/pcClasses/ranger/Ranger_Drizzt_and_guenhwyvar.webp')
const Sorcerer_Storm_Human_Male_SCAG_img = require('./../../../images/pcSubClasses/sorcerer/sorcerer_storm_human_male_scag.webp')
const Uthgardt_tribe_member_Bg_img_SCAG = require('./../../../images/backgrounds/uthgardt_tribe_member.png')
const Warlock_Archfey_Elf_Female_SCAG_img = require('./../../../images/pcClasses/warlock/warlock_archfey_elf_female.jpg')
const Wizard_Sun_Elf_Female_SCAG_img = require('./../../../images/pcClasses/wizard/wizard_sun_elf_female.png')

module.exports = {
  City_watch_SCAG_data: {
    src: City_watch_Bg_img_SCAG,
    text: 'Городской стражник',
    source: {
      id: SOURCE_SCAG,
    },
  },
  Sorcerer_Storm_Human_Male_SCAG_data: {
    src: Sorcerer_Storm_Human_Male_SCAG_img,
    text: 'Человек, штормовой чародей',
    source: {
      id: SOURCE_SCAG,
    },
  },
  Far_traveler_SCAG_data: {
    src: Far_traveler_Bg_img_SCAG,
    text: 'Жительница Соссала',
    source: {
      id: SOURCE_SCAG,
    },
  },
  Inheritor_SCAG_data: {
    src: Inheritor_Bg_img_SCAG,
    text: 'Лунная эльфийка, наследница',
    source: {
      id: SOURCE_SCAG,
    },
  },
  Knight_of_the_order_SCAG_data: {
    src: Knight_of_the_order_Bg_img_SCAG,
    text: 'Женщина, рыцарша ордена пурпурного дракона',
    source: {
      id: SOURCE_SCAG,
    },
  },
  Uthgardt_tribe_member_SCAG_data: {
    src: Uthgardt_tribe_member_Bg_img_SCAG,
    text: 'Женщина, член одного из Утгартских племён',
    source: {
      id: SOURCE_SCAG,
    },
  },
  Human_Female_Bard_SCAG_data: {
    src: Human_Female_Bard_SCAG_img,
    text: 'Женщина, бард',
    source: {
      id: SOURCE_SCAG,
    },
  },
  Gnome_Forest_Druid_Female_SCAG_data: {
    src: Gnome_Forest_Druid_Female_SCAG_img,
    text: 'Лесная гномка, друид',
    source: {
      id: SOURCE_SCAG,
    },
  },
  Dwarf_Paladin_Female_SCAG_data: {
    src: Dwarf_Paladin_Female_SCAG_img,
    text: 'Щитовая дварфийка, паладин',
    source: {
      id: SOURCE_SCAG,
    },
  },
  Humans_Waterdhavian_SCAG_data: {
    src: Humans_Waterdhavian_SCAG_img,
    text: 'Люди, жители Глубоководья',
    source: {
      id: SOURCE_SCAG,
    },
  },
  Warlock_Archfey_Elf_Female_SCAG_data: {
    src: Warlock_Archfey_Elf_Female_SCAG_img,
    text: 'Солнечная эльфийка, колдунья Принца Мороза',
    source: {
      id: SOURCE_SCAG,
    },
  },
  Wizard_Sun_Elf_Female_SCAG_data: {
    src: Wizard_Sun_Elf_Female_SCAG_img,
    text: 'Солнечная эльфийка, волшебница',
    source: {
      id: SOURCE_SCAG,
    },
  },
  Fighter_Tiefling_SCAG_data: {
    src: Fighter_Tiefling_SCAG_img,
    text: 'Тифлинг-воин',
    source: {
      id: SOURCE_SCAG,
    },
  },
  Ranger_Drizzt_and_Guenhwyvar_SCAG_data: {
    src: Ranger_Drizzt_and_Guenhwyvar_SCAG_img,
    text: 'Следопыт Севера Дриззт До’Урден и его пантера Гвенвивар',
    source: {
      id: SOURCE_SCAG,
    },
  },
  Cleric_of_Lathander_Human_Male_SCAG_data: {
    src: Cleric_of_Lathander_Human_Male_SCAG_img,
    text: 'Жрец Латандера',
    source: {
      id: SOURCE_SCAG,
    },
  },
  Halfling_Rogue_Female_SCAG_data: {
    src: Halfling_Rogue_Female_SCAG_img,
    text: 'Коренастая полурослица, плут',
    source: {
      id: SOURCE_SCAG,
    },
  },
  Human_Monk_Male_SCAG_data: {
    src: Human_Monk_Male_SCAG_img,
    text: 'Мужчина монах',
    source: {
      id: SOURCE_SCAG,
    },
  },
  Elf_Sun_Male_SCAG_data: {
    src: Elf_Sun_Male_SCAG_img,
    text: 'Солнечный эльф',
    source: {
      id: SOURCE_SCAG,
    },
  },
  Half_Orc_Male_Barbarian_Totem_SCAG_data: {
    src: Half_Orc_Male_Barbarian_Totem_SCAG_img,
    text: 'Полуорк, варвар пути тотемного воина',
    source: {
      id: SOURCE_SCAG,
    },
  },
  Half_Elf_Female_Druid_SCAG_data: {
    src: Half_Elf_Female_Druid_SCAG_img,
    text: 'Полуэльфийка, друид',
    source: {
      id: SOURCE_SCAG,
    },
  },
  Human_Female_Barbarian_SCAG_data: {
    src: Human_Female_Barbarian_SCAG_img,
    text: 'Женщина, варварша',
    source: {
      id: SOURCE_SCAG,
    },
  },
  Elturgard_Chain_Shirt_Breastplate_SCAG_data: {
    src: Elturgard_Chain_Shirt_Breastplate_SCAG_img,
    text: 'Нагрудник и кольчужная рубаха паладинов элтургардского Ордена Компаньона',
    source: {
      id: SOURCE_SCAG,
    },
  },
}
