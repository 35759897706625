const {SOURCE_XGTE} = require('./../../sourceList')

const Ettin_XGTE_img = require('./../../../images/creatures/xgte/ettin.jpg')

module.exports = {
  Ettin_XGTE_data: {
    src: Ettin_XGTE_img,
    text: 'Легконогая полурослица и эттин',
    source: {
      id: SOURCE_XGTE,
    },
  },
}
