import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './NoteStyles.less'

const NoteComponent = ({ author, text }) => {
  const textLineList = text.split('\n')
  const isQuote = Boolean(author)

  return (
    <section className='Note'>
      <div
        className={classNames(
          'Note_text',
          {
            'Note_text-isQuote': isQuote,
          }
        )}
      >
        {
          textLineList.map(
            (textLine, i) => (
              <p
                className='Note_textLine'
                key={i}
              >
                {textLine}
              </p>
            )
          )
        }
      </div>
      {
        author
          ? <div className='Note_author'>{author}</div>
          : null
      }
    </section>
  )
}

NoteComponent.defaultProps = {
  author: null,
}

NoteComponent.propTypes = {
  author: PropTypes.string,
  text: PropTypes.string.isRequired,
}

export default NoteComponent
