import arrify from 'arrify'
import React from 'react'

import AbandonedAt from '@/components/AbandonedAt'
import DescriptionList from '@/components/DescriptionList'
import HeaderBlock from '@/components/HeaderBlock'
import Markdown from '@/components/Markdown'
import Note from '@/components/Note'
import PcClassAbilityList from '@/components/PcClassAbilityList'
import ReleasedAs from '@/components/ReleasedAs'
import ReleasedBeforeAs from '@/components/ReleasedBeforeAs/ReleasedBeforeAsComponent'
import SourceInfo from '@/components/SourceInfo'

import generatePcSubClassPageUrlById from '@/utils/generatePcSubClassPageUrlById'
import generateTextLinks from '@/utils/generateTextLinks'

import { pcSubClassCollection } from '@/constants/pcSubClassList'

import PcSubClassImageList from './components/PcSubClassImageList'

import './PcSubClassStyles.less'

const PcSubClassComponent = (
  {
    abandonedAt,
    descriptionMain,
    descriptionOther,
    featureList,
    nameText,
    id,
    header,
    note,
    releasedAs,
    releasedBeforeAsList,
    source,
    subHeader,
  },
) => (
  <section className='PcSubClass'>
    <section className='PcSubClass_infoBlock'>
      <HeaderBlock
        className='PcSubClass_header'
        subHeader={subHeader}
      >
        <Markdown>{header}</Markdown>
      </HeaderBlock>

      {
        descriptionMain
          ? (
            <Markdown className='PcSubClass_description'>
              {generateTextLinks(descriptionMain)}
            </Markdown>
          )
          : null
      }

      <SourceInfo
        className='PcSubClass_source'
        source={source}
        useFullName
        themeColumn
      />
    </section>

    <AbandonedAt
      preText='Этот подкласс был отменён'
      abandonedAt={abandonedAt}
    />
    <ReleasedAs
      preText='Этот подкласс был переиздан'
      releasedAs={releasedAs}
      collection={pcSubClassCollection}
      urlGenerator={generatePcSubClassPageUrlById}
    />
    <ReleasedBeforeAs
      preText='Этот подкласс ранее издавался'
      releasedAs={releasedBeforeAsList}
      collection={pcSubClassCollection}
      urlGenerator={generatePcSubClassPageUrlById}
    />

    <Note
      note={note}
      className='PcSubClass_note'
    />

    {
      descriptionOther
        ? <DescriptionList description={arrify(descriptionOther)}/>
        : null
    }

    <PcClassAbilityList
      abilityList={featureList}
      subHeaderGenerator={lvl => `${nameText}, умение ${lvl} уровня`}
    />
    <PcSubClassImageList pcSubClassId={id}/>
  </section>
)

export default PcSubClassComponent
