import React from "react"

import infusionList from "@/constants/infusionList"

import sortByName from "@/utils/sortByName"

import ToggleList from '@/components/ToggleList'

import AllInfusionListRenderer from './AllInfusionListRenderer'

const AllInfusionList = () => {
  const list = infusionList.sort(sortByName)

  return (
    <ToggleList
      list={list}
      ListRenderer={AllInfusionListRenderer}
      text='список инфузий'
    />
  )
}

export default AllInfusionList
