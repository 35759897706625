import React from "react"
import { Link } from "gatsby"

import './ListRendererStyles.less'

const ListRenderer = ({list, pageUrlGenerator}) => (
  <ol className='ListRenderer'>
    {
      list.map(
        ({ name, id }) => (
          <li className='ListRenderer_item'>
            <Link to={pageUrlGenerator(id)}>
              {name}
            </Link>
          </li>
        )
      )
    }
  </ol>
)

export default ListRenderer
