const {SOURCE_GAME_BG_3} = require('./../../sourceList')

const Flaming_fist_shield_GAME_BG_3_img = require('./../../../images/gears/game_bg3/flaming_fist_shield_bg3.webp')

module.exports = {
  Flaming_fist_shield_GAME_BG_3_data: {
    src: Flaming_fist_shield_GAME_BG_3_img,
    text: 'Щит Пылающего Кулака',
    source: SOURCE_GAME_BG_3,
  },
}
