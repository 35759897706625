import React from "react"

import arcaneShotList from "@/constants/arcaneShotList"

import ToggleList from '@/components/ToggleList'

import AllArcaneShotListRenderer from './AllArcaneShotListRenderer'

const AllArcaneShotList = () => (
  <ToggleList
    list={arcaneShotList}
    ListRenderer={AllArcaneShotListRenderer}
    text='варианты магического выстрела'
  />
)

export default AllArcaneShotList
