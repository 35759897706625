import arrify from 'arrify'
import React from 'react'

import PcSubClass from '@/components/PcSubClass'

import {pcClassCollection} from "@/constants/pcClassList"
import pcSubClassImageCollection from '@/constants/images/pcSubClassImageCollection'
import {
  pcSubClassCollection,
  pcClassIdBySubClassIdCollection,
} from "@/constants/pcSubClassList"

import CommonCatalogPageTemplate from '@/templates/CommonCatalogPageTemplate'
import generateNameStr from "@/utils/generateNameStr";

const PcSubClassPageTemplate = (props) => {
  const { pageContext: {slug: pcSubClassId} } = props
  // TODO: Получать нужный class ID для многоклассовых подклассов
  const [pcClassId] = arrify(pcClassIdBySubClassIdCollection[pcSubClassId])
  const pcClass = pcClassCollection[pcClassId]
  const { title: pcClassName } = generateNameStr(pcClass, true, true)

  const defaultSeoData = {
    title: `${pcClassName} | Классы и подклассы`,
    description: 'Классы и подклассы персонажей в Dungeons & Dragons',
    img: null,
  }

  return (
    <CommonCatalogPageTemplate
      defaultSeoData={defaultSeoData}
      itemCollection={pcSubClassCollection}
      ItemComponent={PcSubClass}
      itemImageCollection={pcSubClassImageCollection}
      {...props}
    />
  )
}

export default PcSubClassPageTemplate
