const {
  PC_SUBCLASS_ROGUE_ARCANE_TRICKSTER,
  PC_SUBCLASS_ROGUE_PHANTOM,
} = require('./../../pcSubClassIdList')

const {
  Tiefling_Rogue_Phantom_TCoE_data,
  Imoen_Mystic_Trickster_MTG_data,
} = require('./../commonImageCollection')

module.exports = {
  [PC_SUBCLASS_ROGUE_PHANTOM]: [
    Tiefling_Rogue_Phantom_TCoE_data,
  ],
  [PC_SUBCLASS_ROGUE_ARCANE_TRICKSTER]: [
    Imoen_Mystic_Trickster_MTG_data,
  ],
}
