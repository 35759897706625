import React from 'react'

import SpellGroupByPcClassLvlListComponent from './SpellGroupByPcClassLvlListComponent'

const SpellGroupByPcClassLvlListContainer = ({spellCircleLvl, spellList, ...rest}) => {
  const spellGroupList = spellCircleLvl.reduce(
    (groupList, pcClassLvl, spellLvlIndex) => {
      const spellIdListByLvl = spellList.filter(({lvl}) => lvl === spellLvlIndex)

      return spellIdListByLvl.length
        ? [
          ...groupList,
          {
            pcClassLvl,
            spellLvl: spellLvlIndex === 0
              ? 'Заговор'
              : `${spellLvlIndex}-й круг`,
            list: spellIdListByLvl,
          },
        ]
        : groupList
    },
    []
  )

  return (
    <SpellGroupByPcClassLvlListComponent
      spellGroupList={spellGroupList}
      {...rest}
    />
  )
}

export default SpellGroupByPcClassLvlListContainer
