import React from "react"

import sortByName from "@/utils/sortByName"

import {fightingStyleCollection} from "@/constants/fightingStyleList"

import ToggleList from '@/components/ToggleList'

import FightingStyleListRenderer from './FightingStyleListRenderer'

const FightingStyleList = ({idList}) => {
  const list = idList
    .map(id => fightingStyleCollection[id])
    .sort(sortByName)

  return (
    <ToggleList
      list={list}
      ListRenderer={FightingStyleListRenderer}
      text='боевые стили'
    />
  )
}

export default FightingStyleList
