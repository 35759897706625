import React from 'react'

import AdsPlacer from '@/components/AdsPlacer'

import './AdsAtAbilitiesStyles.less'

// Баннер РСЯ в умениях
const AdsAtAbilities = () => (
  <AdsPlacer
    blockId='R-A-2421058-7'
    className='AdsAtAbilities'
    incrementIndex
  />
)

export default AdsAtAbilities
